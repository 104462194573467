import React from 'react'
import ContactArt from '../components/Contact/ContactArt'
import ContactForm from '../components/Contact/ContactForm'

export default function Contact(props) {

    const setTitle = props.setTitle
    const setProgress = props.setProgress

    React.useEffect(() => {
        setTitle('Contact Us - Travezco')
        setProgress(100)
    }, [setTitle, setProgress])

    return (
        <div className="contact">
            <ContactArt />
            <ContactForm />
        </div>
    )
}
