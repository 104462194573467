import React from 'react'
import './query.css'

export default function QueryButton(props) {
    return (
        <div className="query-btn" onClick={() => props.setQuery(true)}>
            <div className="query-btn-inner">
                <i className='fa-solid fa-circle-question'></i>
                <p>Got a <b>Query?</b></p>
            </div>
            <div className="query-btn-extension"></div>
        </div>
    )
}
