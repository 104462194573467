import React from 'react'
import Filter from '../components/Filter/Filter'
import { useNavigate, useParams } from 'react-router-dom'
import SpecialTripCards from '../components/Specials/SpecialTripCards'
import DestinationHeader from '../components/DestinationHeader/DestinationHeader'

export default function TravConnect(props) {

    const navigate = useNavigate();
    const { category } = useParams();
    const setTitle = props.setTitle;
    const [permaTrips, setPermaTrips] = React.useState([])
    const [trips, setTrips] = React.useState([])
    const [ok, setOk] = React.useState(false);

    const categoryMap = {
        "Honeymoon": {
            "char": "H",
            "tagline": "Romantic escapes for the newly weds.",
            "collage": "https://travezco-bucket.s3.amazonaws.com/misc/honeymoon.webp"
        },
        "Holiday": {
            "char": "C",
            "tagline": "Explore a new destination this holiday season.",
            "collage": "https://travezco-bucket.s3.amazonaws.com/misc/holiday.webp"
        },
        "Winter": {
            "char": "W",
            "tagline": "Handpicked destinations for a serene winter.",
            "collage": "https://travezco-bucket.s3.amazonaws.com/misc/winter.webp"
        }
    }

    React.useEffect(() => {
        if (!categoryMap[category]) {
            navigate('/404');
        } else {
            setOk(true);
        }
        setTitle(`${category} Special Packages - Travezco`);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, setTitle])

    React.useEffect(() => {
        setTrips(permaTrips);
    }, [permaTrips])

    return (
        ok ? <>
            <DestinationHeader name={`${category} Specials`} tagline={categoryMap[category].tagline} collage={categoryMap[category].collage} />
            <Filter trips={permaTrips} setTrips={setTrips} />
            <SpecialTripCards category={categoryMap[category].char} trips={trips} setTrips={setPermaTrips}  setProgress={props.setProgress} authStatus={props.authStatus} setAuth={props.setAuth} setAuthStatus={props.setAuthStatus} />
        </> : <></>
    )
}
