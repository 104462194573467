import React from 'react'
import Legal from '../components/Legal/Legal';

export default function Terms(props) {

    const setTitle = props.setTitle;
    const setProgress = props.setProgress;

    React.useEffect(() => {
        setTitle('Terms of Service - Travezco');
        setProgress(100);
    }, [setTitle, setProgress])

    return (
        <>
            <Legal name="terms" />
        </>
    )
}
