import React from 'react'
import calendar from '../../assets/icons/calendar.svg'
import addTraveller from '../../assets/icons/add-traveller.svg'
import billing from '../../assets/icons/billing.svg'
import offer from '../../assets/icons/offer-black.svg'
import payment from '../../assets/icons/payment.svg'
import sharing from '../../assets/icons/sharing.svg'
import './checkout.css'
import DateSelector from './DateSelector'
import TravellerDetails from './TravellerDetails'
import AddEmail from './AddEmail'
import AddAddress from './AddAddress'
import ItinerarySummary from './ItinerarySummary'
import Promocode from './Promocode'
import Payment from './Payment'
import Sharing from './Sharing'

export default function Checkout(props) {

    const [date, setDate] = React.useState("");
    const [promocode, setPromocode] = React.useState("NONE"); 
    const [address, setAddress] = React.useState({});
    const [addressValid, setAddressValid] = React.useState(false);
    const [passengers, setPassengers] = React.useState([]);
    const [maxPassengers, setMaxPassengers] = React.useState(99);

    return (
        <div className="checkout">
            <div className="checkout-main">
                <div className="checkout-section">
                    <h2>
                        Choose Date
                        <img src={calendar} alt="calendar" />
                    </h2>
                    <DateSelector setMaxPassengers={setMaxPassengers} checkoutData={props.checkoutData} setDate={setDate} />
                </div>
                {date ? <>
                    <div className="checkout-section">
                        <h2>
                            Add Travellers
                            <img src={addTraveller} alt="traveller" />
                        </h2>
                        <TravellerDetails maxPassengers={maxPassengers} date={date} passengers={passengers} setPassengers={setPassengers} promocode={promocode} paymentDetails={props.paymentDetails} setPaymentDetails={props.setPaymentDetails} checkoutData={props.checkoutData} />
                    </div>
                    {passengers.length > 0 && props.checkoutData.travelBuddy ? <div className="checkout-section">
                        <h2>
                            Room Sharing
                            <img src={sharing} alt="traveller" />
                        </h2>
                        <Sharing date={date} passengers={passengers} setPassengers={setPassengers} checkoutData={props.checkoutData} />
                    </div> : null}
                    <div className="checkout-section">
                        <h2>
                            Billing Details
                            <img src={billing} alt="billing" />
                        </h2>
                        {props.checkoutData.userEmail ? null : <AddEmail checkoutData={props.checkoutData} />}
                        <AddAddress checkoutData={props.checkoutData} setAddress={setAddress} setAddressValid={setAddressValid} />
                    </div></> : null}
            </div>
            <div className="checkout-secondary">
                <ItinerarySummary checkoutData={props.checkoutData} />
                {date ? <>
                    <div className="checkout-secondary-section">
                        <h2>
                            Apply Promocode
                            <img src={offer} alt="offer" />
                        </h2>
                        <Promocode checkoutData={props.checkoutData} promocode={promocode} setPromocode={setPromocode} />
                    </div>
                    <div className="checkout-secondary-section">
                        <h2>
                            Payment Details
                            <img src={payment} alt="offer" />
                        </h2>
                        <Payment date={date} passengers={passengers} address={address} addressValid={addressValid} checkoutData={props.checkoutData} paymentDetails={props.paymentDetails} promocode={promocode} setPromocode={setPromocode} />
                    </div></> : null}
            </div>
        </div>
    )
}
