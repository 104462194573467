import React from 'react'
import './populardestinationcard.css'

export default function LoadingDestinationCard(props) {
    return (
        <div className="popular-destination-card loading-destination-card">
            <div className="popular-destination-image"></div>
            <div className="popular-destination-details"></div>
        </div>
  )
}
