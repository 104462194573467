import React from 'react'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import CheckoutComponent from '../components/Checkout/Checkout'
import axios from 'axios';
import Cookies from 'universal-cookie';

export default function Checkout(props) {

    const { tripParam } = useParams();
    const navigate = useNavigate();
    const setTitle = props.setTitle;
    const setProgress = props.setProgress;
    const [checkoutData, setCheckoutData] = React.useState({offers: [], dates: []});
    const [paymentDetails, setPaymentDetails] = React.useState({});

    React.useEffect(() => {
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        setProgress(20);

        if (access && refresh) {
            axios.post('https://romeo.travezco.com/trips/itinerary/checkout/', {tripId: tripParam}, {headers: {"Authorization": `Bearer ${access}`}})
                .then(res => {
                    setCheckoutData(res.data);
                    setTitle(`Booking ${res.data.origin} to ${res.data.destination} for ${res.data.duration} - Travezco`);
                    setPaymentDetails({costPerPerson: res.data.price});
                    setProgress(100);
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                            .then(res => {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.post('https://romeo.travezco.com/trips/itinerary/checkout/', {tripId: tripParam}, {headers: {"Authorization": `Bearer ${res.data.access}`}})
                                    .then(res => {
                                        setCheckoutData(res.data);
                                        setTitle(`Booking ${res.data.origin} to ${res.data.destination} for ${res.data.duration} - Travezco`);
                                        setPaymentDetails({costPerPerson: res.data.price});
                                        setProgress(100);
                                    })
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                }
                                navigate('/');
                            })
                    }
                })
        } else {
            navigate('/');
        }

    }, [setTitle, setProgress, props.authStatus, navigate, tripParam])

    return (
        <>
            <CheckoutComponent checkoutData={checkoutData} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />
        </>
    )
}
