import React from 'react'
import { useParams } from 'react-router';
import BookingSuccess from '../components/Booking/BookingSuccess'
import BookingFailed from '../components/Booking/BookingFailed';

export default function Booking(props) {

    const { bookingId }  = useParams();

    return (
        props.success ? <BookingSuccess bookingId={bookingId} setTitle={props.setTitle} /> : <BookingFailed bookingId={bookingId} setTitle={props.setTitle} />
    )
}
