import React from 'react'
import Header from '../components/TravConnect/Header'
import Filter from '../components/Filter/Filter'
import TripCards from '../components/TravConnect/TripCards'

export default function TravConnect(props) {

    const setTitle = props.setTitle;
    const [permaTrips, setPermaTrips] = React.useState([])
    const [trips, setTrips] = React.useState([])

    React.useEffect(() => {
        setTitle("TravConnect - Group Travel Made Easy");
    }, [setTitle])

    React.useEffect(() => {
        setTrips(permaTrips);
    }, [permaTrips])

    return (
        <>
            <Header />
            <Filter trips={permaTrips} setTrips={setTrips} />
            <TripCards trips={trips} setTrips={setPermaTrips}  setProgress={props.setProgress} authStatus={props.authStatus} setAuth={props.setAuth} setAuthStatus={props.setAuthStatus} />
        </>
    )
}
