import React from 'react'
import './seller.css'
import axios from 'axios';

export default function SellerForm(props) {

    window.onclick = function (event) {
        if (event.target.className === 'seller-form-modal') {
            props.setSellerPopup(0);    
        }
    }

    function validateForm() {
        var name = document.getElementById('seller-name');
        var agency = document.getElementById('seller-agency');
        var number = document.getElementById('seller-number');
        var email = document.getElementById('seller-email');
        var address = document.getElementById('seller-address');
        const mobileRegex = /^[0-9]{10}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!name.value) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter your name.'; 
            name.classList.add('error-input'); return false;
        }
        if (!agency.value) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter your agency name.'; 
            agency.classList.add('error-input'); return false;
        }
        if (!number.value) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter your contact number.'; 
            number.classList.add('error-input'); return false;
        }
        if (!number.value.match(mobileRegex)) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter a valid contact number.'; 
            number.classList.add('error-input'); return false;
        }
        if (!email.value) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter your email address.'; 
            email.classList.add('error-input'); return false;
        }
        if (!email.value.match(emailRegex)) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter a valid email address.'; 
            email.classList.add('error-input'); return false;
        }
        if (!address.value) {
            document.getElementById('seller-form-error').innerHTML = 'Please enter your office address.'; 
            address.classList.add('error-input'); return false;
        }
        document.getElementById('seller-form-error').innerHTML = '';
        name.classList.remove('error-input');
        agency.classList.remove('error-input');
        number.classList.remove('error-input');
        email.classList.remove('error-input');
        address.classList.remove('error-input');
        return true
    }

    function removeError(event) {
        event.target.classList.remove('error-input');
        document.getElementById('seller-form-error').innerHTML = '';
    }

    function clearForm() {
        document.getElementById('seller-name').value = '';
        document.getElementById('seller-agency').value = '';
        document.getElementById('seller-number').value = '';
        document.getElementById('seller-email').value = '';
        document.getElementById('seller-address').value = '';
    }

    function closePopup() {
        props.setSellerPopup(0);
    }

    function submitForm() {
        if (!validateForm()) {
            return;
        }
        var name = document.getElementById('seller-name').value;
        var agency = document.getElementById('seller-agency').value;
        var number = document.getElementById('seller-number').value;
        var email = document.getElementById('seller-email').value;
        var address = document.getElementById('seller-address').value;
        var data = {
            name: name,
            agency: agency,
            phone: number,
            email: email,
            address: address
        }
        axios.post('https://romeo.travezco.com/misc/vendor-query/', data)
            .then(res => {
                if (res.status === 200) {
                    clearForm();
                    props.setSellerPopup(2);
                }
            })
            .catch(err => {
                document.getElementById('seller-form-error').innerHTML = 'Something went wrong. Please try again later.';
            })
    }

    return (
        <div className="seller-form-modal">
            <div className="seller-form">
                <div className="seller-form-close">
                    <i className="fa-solid fa-x" onClick={closePopup}></i>
                </div>
                <h3>Fill this short form to schedule a meet with us.</h3>
                <div className="seller-form-input-row">
                    <div className="seller-form-input">
                        <p>Your Name</p>
                        <input type="text" name="name" id="seller-name" onKeyUp={removeError} />
                    </div>
                    <div className="seller-form-input">
                        <p>Name of Agency</p>
                        <input type="text" name="agency" id="seller-agency" onKeyUp={removeError} />
                    </div>
                </div>
                <div className="seller-form-input-row">
                    <div className="seller-form-input">
                        <p>Contact Number</p>
                        <input type="text" name="mobile" id="seller-number" onKeyUp={removeError} />
                    </div>
                    <div className="seller-form-input">
                        <p>Email Address</p>
                        <input type="email" name="email" id="seller-email" onKeyUp={removeError} />
                    </div>
                </div>
                <div className="seller-form-input-row">
                    <div className="seller-form-input seller-w-100">
                        <p>Office Address</p>
                        <input type="text" name="address" id="seller-address" onKeyUp={removeError} />
                    </div>
                </div>
                <p id="seller-form-error"></p>
                <button onClick={submitForm}>Submit</button>
            </div>
        </div>
    )
}
