import React from 'react'
import HomeCarousel from '../components/HomeCarousel/HomeCarousel'
import PopularDestinations from '../components/PopularDestinations/PopularDestinations'
import StatBar from '../components/StatBar/StatBar'
import TravConnect from '../components/HomeTravConnect/TravConnect';
import PastCollab from '../components/PastCollab/PastCollab';
import WhyUs from '../components/WhyUs/WhyUs';

export default function Home(props) {

    const setTitle = props.setTitle;

    React.useEffect(() => {
        setTitle('Travezco - The Best Travel Marketplace');
    }, [setTitle])

    return (
        <>
            <HomeCarousel />
            <PopularDestinations setProgress={props.setProgress} />
            <StatBar />
            <WhyUs />
            <TravConnect authStatus={props.authStatus} setAuth={props.setAuth} setAuthStatus={props.setAuthStatus} />
            <PastCollab />
        </>
    )
}
