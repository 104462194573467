import React from 'react'
import './about.css'

export default function AboutHeader() {

    const width = window.outerWidth;
    const desktopImage = "https://travezco-bucket.s3.amazonaws.com/misc/about-desktop.webp"
    const mobileImage = "https://travezco-bucket.s3.amazonaws.com/misc/about-mobile.webp"

    return (
        <div className="about-header">
            {width > 768 ? <img src={desktopImage} alt="About Us" /> : <img src={mobileImage} alt="About Us" />}
        </div>
    )
}
