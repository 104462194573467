import React from 'react'
import ProfileNavigator from '../components/ProfileNavigator/ProfileNavigator'
import BookingComponent from '../components/Bookings/Bookings';
import Booking from '../components/Bookings/Booking';

export default function Bookings(props) {

    return (
        <div className="profile" style={profileStyle}>
            <ProfileNavigator selected={1} />
            {props.bookingState ? <Booking setTitle={props.setTitle} setProgress={props.setProgress} setAuthStatus={props.setAuthStatus} setAuth={props.setAuth} authStatus={props.authStatus} /> : 
            <BookingComponent setTitle={props.setTitle} setProgress={props.setProgress} setAuthStatus={props.setAuthStatus} setAuth={props.setAuth} authStatus={props.authStatus} />}
        </div>
    )
}

const profileStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
}