import React from 'react'
import './profileedit.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export default function EditBio(props) {

    const setAuthStatus = props.setAuthStatus;
    const [bio, setBio] = React.useState(props.profile.bio);
    const cookies = new Cookies();
    const access = cookies.get('access');
    const refresh = cookies.get('refresh');
    const navigate = useNavigate();

    function updateBio() {
        const newBio = document.getElementById('edit-bio').value;
        setBio(newBio);
        axios.put(`https://romeo.travezco.com/users/update/bio/`, {
            bio: newBio
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    props.setEdit(0);
                    props.profile.bio = newBio;
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.put(`https://romeo.travezco.com/users/update/bio/`, {
                                    bio: newBio
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            props.setEdit(0);
                                            props.profile.bio = newBio;
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                }
            })
    }

    return (
        <>
            <h3>Edit Bio</h3>
            <textarea name="edit-bio" id="edit-bio" cols="30" rows="10" maxLength={200}>{bio}</textarea>
            <button onClick={updateBio}>Save</button>
        </>
    )
}
