import React from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import TripCard from '../TripCards/TripCard';
import './wishlist.css'
import LoadingTripCard from '../TripCards/LoadingTripCard';

export default function Wishlist(props) {

    const setProgress = props.setProgress;
    const setTitle = props.setTitle;
    const [wishlist, setWishlist] = React.useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        setProgress(20);
        setTitle('My Wishlist - Travezco');

        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');

        if (access && refresh) {
            axios.get('https://romeo.travezco.com/users/wishlist/', { headers: { "Authorization": `Bearer ${access}` } })
                .then(res => {
                    setWishlist(res.data);
                    const loadingCards = document.querySelectorAll('.loading-trip-card')
                    loadingCards.forEach(card => {
                        card.style.display = 'none'
                    })
                    setProgress(100);
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
                            .then(res => {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.get('https://romeo.travezco.com/users/wishlist/', { headers: { "Authorization": `Bearer ${res.data.access}` } })
                                    .then(res => {
                                        setWishlist(res.data);
                                        const loadingCards = document.querySelectorAll('.loading-trip-card')
                                        loadingCards.forEach(card => {
                                            card.style.display = 'none'
                                        })
                                        setProgress(100);
                                    })
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                    navigate('/');
                                }
                            })
                    }
                })
        } else {
            navigate('/');
        }

    }, [setProgress, navigate, setTitle])

    return (
        <div className="wishlist-cards">
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            {wishlist.map(trip => {
                return (
                    <TripCard
                        key={trip.id}
                        id={trip.id}
                        origin={trip.origin}
                        destination={trip.destination}
                        duration={trip.duration}
                        discount={trip.discount}
                        costPrice={trip.costPrice}
                        sellingPrice={trip.sellingPrice}
                        image={trip.primaryImage}
                        highlights={trip.highlights}
                        rating={trip.rating}
                        flight={trip.flight}
                        meal={trip.meal}
                        stay={trip.stay}
                        transfer={trip.transfer}
                        wishlist={trip.wishlist}
                        specialTag={trip.specialTag}
                        date={trip.date}
                        authStatus={props.authStatus}
                        setAuth={props.setAuth}
                        setAuthStatus={props.setAuthStatus}
                    />
                )
            })}
        </div>
    )
}
