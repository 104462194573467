import React from 'react'
import './seller.css'
import axios from 'axios';

export default function SellerTestimonials() {

    const [selectedTestimonial, setSelectedTestimonial] = React.useState({name: '', title: '', testimonial: ''});
    const [allTestimonails, setAllTestimonials] = React.useState([]);

    React.useEffect(() => {
        axios.get('https://romeo.travezco.com/misc/vendor-testimonials/')
            .then(res => {
                setAllTestimonials(res.data);
                setSelectedTestimonial(res.data[0]);
            })
            .catch(err => console.log(err));
    }, [])

    function nextTestimonial() {
        const index = allTestimonails.indexOf(selectedTestimonial);
        if (index === allTestimonails.length - 1) {
            setSelectedTestimonial(allTestimonails[0]);
        } else {
            setSelectedTestimonial(allTestimonails[index + 1]);
        }
        var circles = document.querySelectorAll(".seller-testimonials-nav i");
        circles.forEach(circle => circle.classList.remove("seller-testimonials-selected"));
        if (index === allTestimonails.length - 1) {
            circles[0].classList.add("seller-testimonials-selected");
        } else {
            circles[index + 1].classList.add("seller-testimonials-selected");
        }
    }

    function prevTestimonial() {
        const index = allTestimonails.indexOf(selectedTestimonial);
        if (index === 0) {
            setSelectedTestimonial(allTestimonails[allTestimonails.length - 1]);
        } else {
            setSelectedTestimonial(allTestimonails[index - 1]);
        }
        var circles = document.querySelectorAll(".seller-testimonials-nav i");
        circles.forEach(circle => circle.classList.remove("seller-testimonials-selected"));
        if (index === 0) {
            circles[allTestimonails.length - 1].classList.add("seller-testimonials-selected");
        } else {
            circles[index - 1].classList.add("seller-testimonials-selected");
        }
    }

    React.useEffect(() => {
        if (allTestimonails.length === 0) return;
        const interval = setInterval(() => {
            nextTestimonial();
        }, 5000);
        return () => clearInterval(interval);
    })

    return (
        <div className="seller-testimonials">
            <h3><span>Testimonials</span> from our Service Providers</h3>
            <div className="seller-testimonials-content">
                <div className="seller-testimonials-button">
                    <i className="fa-solid fa-arrow-left" onClick={prevTestimonial}></i>
                </div>
                <div className="seller-testimonials-card">
                    <div className="seller-testimonail-content">
                        <p>{selectedTestimonial.testimonial}</p>
                    </div>
                    <div className="seller-testimonial-details">
                        <p>{selectedTestimonial.name}</p>
                        <span>{selectedTestimonial.title}</span>
                    </div>
                </div>
                <div className="seller-testimonials-button">
                    <i className="fa-solid fa-arrow-right" onClick={nextTestimonial}></i>
                </div>
            </div>
            <div className="seller-testimonials-nav">
                {allTestimonails.map((testimonial, index) => (
                    index === 0 ? <i className="fa-solid fa-circle seller-testimonials-selected"></i> : <i key={index} className="fa-solid fa-circle"></i> 
                ))}
            </div>
        </div>
    )
}
