import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import '../auth.css'

export default function LoginScreen1(props) {

    function checkInput() {
        const mobileRegex = /^[0-9]{10}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const loginParam = document.getElementById('login-param');
        const authError = document.getElementById('auth-error');
        if (loginParam.value === '') {
            loginParam.classList.add('error-input');
            authError.innerHTML = 'Please enter email/mobile number';
            return false;
        }
        else if (mobileRegex.test(loginParam.value) === false && emailRegex.test(loginParam.value) === false) {
            loginParam.classList.add('error-input');
            authError.innerHTML = 'Please enter a valid email/mobile number';
            return false;
        }
        else {
            loginParam.classList.remove('error-input');
            authError.innerHTML = '';
            return true;
        }
    }

    function sendOtp() {
        if (checkInput()) {
            const loginParam = document.getElementById('login-param');
            const authError = document.getElementById('auth-error');
            loginParam.classList.remove('error-input');
            authError.innerHTML = '';
            document.getElementById('submitButton').style.display = 'none';
            document.getElementById('loadingButton').style.display = 'block';
            const url = 'https://romeo.travezco.com/users/login/';
            const data = {
                param: loginParam.value
            }
            axios.post(url, data)
                .then(res => {
                    if (res.status === 200) {
                        props.userId(res.data.userId);
                        props.userParam(loginParam.value);
                        props.authState(1);
                    }})
                .catch(err => {
                    loginParam.classList.add('error-input');
                    authError.innerHTML = err.response.data.error;
                    document.getElementById('submitButton').style.display = 'block';
                    document.getElementById('loadingButton').style.display = 'none';
                })
        }
    }

    return (
        <div className="auth-right">
            <h3>Login</h3>
            <div className="auth-input">
                <p>Email Address/Mobile Number</p>
                <input type="text" name="login-param" id="login-param" onKeyUp={checkInput} />
                <p className="auth-error" id="auth-error"></p>
                <span>By continuing, you agree to Travezco's <Link to="/terms" onClick={() => props.viewAuth(false)}>Terms of Use</Link> and <Link to="/privacy" onClick={() => props.viewAuth(false)}>Privacy Policy</Link></span>
                <button onClick={sendOtp} id="submitButton">Send OTP</button>
                <button className="loadingButton" id="loadingButton" disabled>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                </button>
            </div>
            <div className="auth-footer">
                <p>New to Travezco? <Link to="#" onClick={() => props.authState(2)}>Create an account</Link></p>
            </div>
        </div>
    )
}
