import React from 'react'
import './destinationheader.css'

export default function DestinationHeader(props) {
    return (
        <div className="destination-header">
            <div className="destination-header-image">
                <img src={props.collage} alt="destination" />
            </div>
            <div className="destination-image-overlay"></div>
            <div className="destination-details">
                <h1>{props.name}</h1>
                <h2>{props.tagline}</h2>
            </div>
        </div>
    )
}
