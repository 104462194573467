import React from 'react'
import './about.css'
import iitdelhi from '../../assets/images/iitdelhi.png'
import wadhwani from '../../assets/images/wadhwani.png'
import gsea from '../../assets/images/gsea.png'
import bincube from '../../assets/images/bincube.png'
import ecell from '../../assets/images/ecell-iitb.png'
import startupIndia from '../../assets/images/startup-india.png'

export default function AboutAchievements() {
    return (
        <div className="about-details">
            <div className="about-box">
                <h2><span>Recognitions</span></h2>
                <div className="about-recognitions">
                    <div className="about-recognition">
                        <img src={startupIndia} alt="Startup India" />
                    </div>
                    <div className="about-recognition">
                        <img src={iitdelhi} alt="IIT Delhi" />
                    </div>
                    <div className="about-recognition">
                        <img src={wadhwani} alt="Wadhwani Foundation" />
                    </div>
                    <div className="about-recognition">
                        <img src={gsea} alt="EO GSEA" />
                    </div>
                    <div className="about-recognition">
                        <img src={bincube} alt="BIncube" />
                    </div>
                    <div className="about-recognition">
                        <img src={ecell} alt="Ecell IIT Bombay" />
                    </div>
                </div>
            </div>
        </div>
    )
}
