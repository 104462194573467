import React from 'react'
import { Link } from 'react-router-dom'
import './destinationdropdown.css'

export default function DestinationDropdown(props) {

    const destinations = props.destinations;

    function openDestinationDropdown() {
        const dropdown = document.querySelector('.destinations-dropdown');
        const dest = document.querySelector('.nav-links a');
        dropdown.style.height = '450px';
        dest.style.color = '#4B9ABD';
    }

    function closeDestinationDropdown() {
        const dropdown = document.querySelector('.destinations-dropdown');
        const dest = document.querySelector('.nav-links a');
        dropdown.style.height = '0';
        dest.style.color = 'rgba(0, 0, 0, 0.7)';
    }

    return (
        <div className="destinations-dropdown" onMouseEnter={openDestinationDropdown} onMouseLeave={closeDestinationDropdown}>
            <div className="destination-listing">
                <div className="destination-list">
                    <h3>Mountains</h3>
                    <div className="destination-list-underline"></div>
                    {destinations.map((destination, key) => {
                        if (destination.category === "Mountains") {
                            return (
                                <Link to={`/destination/${destination.name}`} key={key} onClick={closeDestinationDropdown}>{destination.name}</Link>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div className="destination-list">
                    <h3>Trekking</h3>
                    <div className="destination-list-underline"></div>
                    {destinations.map((destination, key) => {
                        if (destination.category === "Trekking") {
                            return (
                                <Link to={`/destination/${destination.name}`} key={key} onClick={closeDestinationDropdown}>{destination.name}</Link>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div className="destination-list">
                    <h3>Religious</h3>
                    <div className="destination-list-underline"></div>
                    {destinations.map((destination, key) => {
                        if (destination.category === "Religious") {
                            return (
                                <Link to={`/destination/${destination.name}`} key={key} onClick={closeDestinationDropdown}>{destination.name}</Link>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div className="destination-list">
                    <h3>Heritage</h3>
                    <div className="destination-list-underline"></div>
                    {destinations.map((destination, key) => {
                        if (destination.category === "Heritage") {
                            return (
                                <Link to={`/destination/${destination.name}`} key={key} onClick={closeDestinationDropdown}>{destination.name}</Link>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div className="destination-list">
                    <h3>Must Visit</h3>
                    <div className="destination-list-underline"></div>
                    {destinations.map((destination, key) => {
                        if (destination.category === "Other") {
                            return (
                                <Link to={`/destination/${destination.name}`} key={key} onClick={closeDestinationDropdown}>{destination.name}</Link>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        </div>
    )
}
