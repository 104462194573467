import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios'
import mainLogo from '../../assets/images/full_logo.svg'
import hamburger from '../../assets/icons/hamburger.svg'
import logoBird from '../../assets/images/logo_bird.svg'
import call from '../../assets/icons/call.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'
import './navbar.css'
import DestinationDropdown from './DestinationDropdown'
import MobileNav from './MobileNav'
import Auth from '../Auth/Auth'

export default function Navbar(props) {

    const auth = props.auth;
    const setAuth = props.setAuth;
    const authStatus = props.authStatus;
    const setAuthStatus = props.setAuthStatus;
    const [userIcon, setUserIcon] = React.useState('');
    const [destinations, setDestinations] = React.useState([]);
    
    React.useEffect(() => {
        axios.get('https://romeo.travezco.com/trips/destinations/')
            .then(res => setDestinations(res.data))
    }, [])

    React.useEffect(() => {
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        if (access && refresh) {
            const url = 'https://romeo.travezco.com/users/';
            const header = {
                "Authorization": `Bearer ${access}`
            }
            axios.get(url, { headers: header })
                .then(res => {
                    if (res.status === 200) {   
                        setAuthStatus(true);
                        setUserIcon(res.data.profilePicture);
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        const url = 'https://romeo.travezco.com/users/token/refresh/';
                        const data = {
                            refresh: refresh
                        }
                        axios.post(url, data)
                            .then(res => {
                                if (res.status === 200) {
                                    cookies.set('access', res.data.access, { path: '/' });
                                    const url = 'https://romeo.travezco.com/users/';
                                    const header = {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                    axios.get(url, { headers: header })
                                        .then(res => {
                                            if (res.status === 200) {
                                                setAuthStatus(true);
                                                setUserIcon(res.data.profilePicture);
                                            }
                                        })
                                }
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                    setAuthStatus(false);
                                }
                            })
                    }
                })
        }
    }, [setAuthStatus])

    if (window.outerWidth > 768) {
        window.addEventListener('scroll', () => {
            const nav = document.querySelector('nav');
            const underline = document.querySelector('.nav-underline');
            const altNav = document.querySelector('.alt-nav');
            if (window.scrollY > 100) {
                nav.style.opacity = '0';
                underline.style.opacity = '0';
                altNav.style.height = '56px';
            } else {
                nav.style.opacity = '1';
                underline.style.opacity = '1';
                altNav.style.height = '0px';
            }
        })
    }

    function openDestinationDropdown() {
        const dropdown = document.querySelector('.destinations-dropdown');
        const dest = document.querySelector('.nav-links a');
        dropdown.style.height = '450px';
        dest.style.color = '#4B9ABD';
    }

    function closeDestinationDropdown() {
        const dropdown = document.querySelector('.destinations-dropdown');
        const dest = document.querySelector('.nav-links a');
        dropdown.style.height = '0';
        dest.style.color = 'rgba(0, 0, 0, 0.7)';
    }

    function mobileNav() {
        const mobileNav = document.querySelector('.mobile-nav');
        const cover = document.querySelector('.mobile-nav-screen-cover');
        if (mobileNav.style.width === "60%") {
            mobileNav.style.width = "0";
            cover.style.display = "none";
        } else {
            mobileNav.style.width = "60%";
            cover.style.display = "block";
        }
    }

    return (
        <header>
            <nav>
                <div className="nav-logo">
                    <Link to="/">
                        <img src={mainLogo} alt="logo" />
                    </Link>
                </div>
                <div className="nav-links">
                    <Link to="#">Destinations</Link>
                    <div className="destination-button-cover" onMouseEnter={openDestinationDropdown} onMouseLeave={closeDestinationDropdown}></div>
                    <Link to="/travconnect">TravConnect</Link>
                    <Link to="/contact">Contact</Link>
                    {authStatus ?
                        <Link to="/profile">
                            <div className="user-icon">
                                <img src={userIcon} alt="user-icon" />
                            </div>
                        </Link> :
                        <Link to="#" className="nav-button" onClick={() => setAuth(true)}>Login</Link>}
                </div>
                <div className="nav-hamburger" onClick={mobileNav}>
                    <img src={hamburger} alt="hamburger" />
                </div>
                <DestinationDropdown destinations={destinations} />
            </nav>
            <div className="nav-underline"></div>
            <div className="alt-nav">
                <div className="alt-nav-image">
                    <Link to="/">
                        <img src={logoBird} alt="logo" />
                    </Link>
                </div>
                <div className="alt-nav-links">
                    <div className="alt-nav-contact">
                        <Link to="tel:918396957516">
                            <img src={call} alt="call" />
                            <span>+91 83969 57516</span>
                        </Link>
                        <div className="alt-nav-contact-separator"></div>
                        <Link to="https://wa.me/918396957516?text=Hello%2C%20I%20would%20like%20to%20go%20on%20a%20trip!" target="_blank">
                            <img src={whatsapp} alt="Whatsapp" />
                            <span>Chat</span>
                        </Link>
                    </div>
                    {authStatus ?
                        <Link to="/profile">
                            <div className="alt-user-icon">
                                <img src={userIcon} alt="alt-user-icon" />
                            </div>
                        </Link> :
                        <Link to="#" className="alt-nav-button" onClick={() => setAuth(true)}>Login</Link>}
                </div>
            </div>
            <MobileNav setAuth={setAuth} authStatus={authStatus} destinations={destinations} />
            {auth ? <Auth authState={setAuth} authStatus={setAuthStatus} userIcon={setUserIcon} /> : null}
        </header>
    )
}
