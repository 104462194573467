import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ItineraryImages from '../components/ItineraryImages/ItineraryImages';
import ItineraryComponent from '../components/Itinerary/Itinerary';
import Cookies from 'universal-cookie';

export default function Itinerary(props) {

    const { tripParam } = useParams();
    const [tripImage, setTripImage] = React.useState([]);
    const [itinerary, setItinerary] = React.useState([]);
    const [trip, setTrip] = React.useState([]);
    const [tripDays, setTripDays] = React.useState([]);
    const [tripOffers, setTripOffers] = React.useState([]);
    const [tripInclusions, setTripInclusions] = React.useState([]);
    const [tripExclusions, setTripExclusions] = React.useState([]);
    const [recommendedTrips, setRecommendedTrips] = React.useState([]);
    const [tripReviews, setTripReviews] = React.useState([]);
    const [tripDates, setTripDates] = React.useState([]);
    const [ratingChart, setRatingChart] = React.useState([0, 0, 0, 0, 0]);
    const [tripFaqs, setTripFaqs] = React.useState([]);
    const setProgress = props.setProgress;
    const setTitle = props.setTitle;
    const navigate = useNavigate();

    React.useEffect(() => {
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');

        setProgress(20);

        if (access && refresh) {
            axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                .then(res => {
                    cookies.set('access', res.data.access, { path: '/' });
                    axios.get(`https://romeo.travezco.com/trips/itinerary/?trip=${tripParam}`, {headers: { "Authorization": `Bearer ${res.data.access}` }})
                        .then(res => {
                            setTripImage(res.data.images);
                            setItinerary(res.data.itineraries);
                            setTripDays(res.data.days);
                            setTripOffers(res.data.offers);
                            setTripInclusions(res.data.inclusions);
                            setTripExclusions(res.data.exclusions);
                            setRecommendedTrips(res.data.recommended);
                            setTripReviews(res.data.reviews);
                            setRatingChart(res.data.ratingChart);
                            setTripDates(res.data.dates);
                            setTripFaqs(res.data.faqs);
                            setTrip(res.data);
                            setTitle(`${res.data.origin} to ${res.data.destination} for ${res.data.duration} - Travezco`);
                            setProgress(100);
                        })
                        .catch(err => {
                            navigate('/404');
                        })
                })
        } else {
            axios.get(`https://romeo.travezco.com/trips/itinerary/?trip=${tripParam}`)
            .then(res => {
                setTripImage(res.data.images);
                setItinerary(res.data.itineraries);
                setTripDays(res.data.days);
                setTripOffers(res.data.offers);
                setTripInclusions(res.data.inclusions);
                setTripExclusions(res.data.exclusions);
                setRecommendedTrips(res.data.recommended);
                setTripReviews(res.data.reviews);
                setRatingChart(res.data.ratingChart);
                setTripDates(res.data.dates);
                setTripFaqs(res.data.faqs);
                setTrip(res.data);
                setTitle(`${res.data.origin} to ${res.data.destination} for ${res.data.duration} - Travezco`);
                setProgress(100);
            })
            .catch(err => {
                navigate('/404');
            })
        }
    
    }, [setTitle, setProgress, tripParam, navigate])


    return (
        <>
            <div className="nav-padding"></div>
            <ItineraryImages tripImage={tripImage} trip={trip} />
            <ItineraryComponent itinerary={itinerary} dates={tripDates} days={tripDays} offers={tripOffers} recommendedTrips={recommendedTrips} tripReviews={tripReviews} ratingChart={ratingChart} tripInclusions={tripInclusions} tripExclusions={tripExclusions} tripFaqs={tripFaqs} trip={trip} authStatus={props.authStatus} setAuth={props.setAuth} setAuthStatus={props.setAuthStatus} />
        </>
    )
}
