import React from 'react'
import './pastcollab.css'
import PastCollabCard from './PastCollabCard'
import axios from 'axios'

export default function PastCollab() {

    const vw = window.innerWidth / 100
    const [pastCollabCards, setPastCollabCards] = React.useState([])

    React.useEffect(() => {
        axios.get('https://romeo.travezco.com/misc/testimonials')
            .then(res => {
                setPastCollabCards(res.data)
            })
    }, [])

    function prevCard() {
        const card = document.querySelector('.past-collaboration-cards-inner')
        card.scrollLeft -= (300 + 3*vw)
    }

    function nextCard() {
        const card = document.querySelector('.past-collaboration-cards-inner')
        if (card.scrollLeft + card.clientWidth + (300 + 3*vw) + 100 < card.scrollWidth) {
            card.scrollLeft += (300 + 3*vw)
        }
        else {
            card.scrollLeft = 0
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            nextCard()
        }, 5000)
        return () => clearInterval(interval)
    })

    return (
        <div className="past-collaborations">
            <div className="past-collaborations-info">
                <h2>
                    Testimonials
                    <div className="past-collaborations-heading-underline"></div>
                </h2>
                <p>Discover unforgettable travel experiences through Travezco's Testimonials section. Read firsthand accounts from our satisfied customers, gaining confidence in our exceptional services. Let their stories ignite your wanderlust and inspire your next remarkable journey. Join us and create your own extraordinary memories.</p>
            </div>
            <div className="past-collaborations-cards">
                <div className="past-collaboration-card-control">
                    <i className="fa-solid fa-angle-left" onClick={prevCard}></i>
                </div>
                <div className="past-collaboration-cards-inner">
                    {pastCollabCards.map((card, key) => {
                        return (
                            <PastCollabCard key={key} name={card.name} title={card.title} testimonial={card.testimonial} image={card.image} />
                        )
                    })}
                </div>
                <div className="past-collaboration-card-control">
                    <i className="fa-solid fa-angle-right" onClick={nextCard}></i>
                </div>
            </div>
        </div>
    )
}
