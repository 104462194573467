import React from 'react'
import { useNavigate } from 'react-router-dom';
import './checkout.css'
import axios from 'axios';
import Cookies from 'universal-cookie';

export default function Payment(props) {

    const [paymentUrl, setPaymentUrl] = React.useState('');
    const navigate = useNavigate();
    const ruppee = "₹ ";

    function startPayment() {
        if (!props.passengers) {
            alert("Please add passengers");
            return;
        }
        if (!props.checkoutData.userEmail) {
            alert("Please add your email address");
            return
        }
        if (!props.addressValid) {
            alert("Please add your billing address");
            return;
        }

        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');

        var data = {
            tripId: props.checkoutData.tripId,
            date: props.date,
            travellers: props.passengers,
            promocode: props.promocode,
            addressLine1: props.address.addressLine1,
            addressLine2: props.address.addressLine2,
            city: props.address.city,
            state: props.address.state,
            country: props.address.country,
            pincode: props.address.pincode,
        }

        var headers = {
            Authorization: `Bearer ${access}`
        }

        axios.post('https://romeo.travezco.com/bookings/initiate/', data, { headers: headers })
            .then(res => {
                if (res.status === 200) {
                    setPaymentUrl(res.data.paymentUrl);
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                headers = { Authorization: `Bearer ${res.data.access}` }
                                axios.post('https://romeo.travezco.com/bookings/initiate/', data, { headers: headers })
                                    .then(res => {
                                        if (res.status === 200) {
                                            setPaymentUrl(res.data.paymentUrl);
                                        }
                                    })
                                    .catch(err => {
                                        alert(err.response.data.error);
                                    })
                            }
                        })
                        .catch(err => {
                            cookies.remove('access', { path: '/' });
                            cookies.remove('refresh', { path: '/' });
                            navigate('/');
                        })
                } else {
                    alert(err.response.data.error);
                }
            })
    }

    return (
        <>
            <div className="payment">
                <table>
                    <tbody>
                        <tr>
                            <td>Cost Per Person</td>
                            <td>{props.paymentDetails.costPerPerson ? ruppee.concat(props.paymentDetails.costPerPerson) : '-'}</td>
                        </tr>
                        <tr>
                            <td>Travellers</td>
                            <td>{props.paymentDetails.travellers ? props.paymentDetails.travellers : '-'}</td>
                        </tr>
                        <tr>
                            <td>Subtotal</td>
                            <td>{props.paymentDetails.subtotal ? ruppee.concat(props.paymentDetails.subtotal) : '-'}</td>
                        </tr>
                        <tr>
                            <td>Sharing Charges</td>
                            <td>{props.paymentDetails.sharingCharges ? ruppee.concat(props.paymentDetails.sharingCharges) : '-'}</td>
                        </tr>
                        <tr>
                            <td>Discount</td>
                            <td>{props.paymentDetails.discount ? ruppee.concat(props.paymentDetails.discount) : '-'}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{props.paymentDetails.total ? ruppee.concat(props.paymentDetails.total) : '-'}</td>
                        </tr>
                        <tr>
                            <td>Booking Amount</td>
                            <td>{props.paymentDetails.bookingAmount ? ruppee.concat(props.paymentDetails.bookingAmount) : '-'}</td>
                        </tr>
                    </tbody>
                </table>
                {props.paymentDetails.bookingAmount ? <button onClick={startPayment}>Pay {ruppee.concat(props.paymentDetails.bookingAmount)}</button> : null}
            </div>
            {paymentUrl ? <div className="payment-modal">
                <div className="payment-modal-content">
                    <iframe title='Payment Page - Travezco | Powered by CCAvenue' frameborder="0" id="paymentFrame" src={paymentUrl}></iframe>
                </div>
            </div> : null}
        </>
    )
}
