import React from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import './profile.css'
import ProfileEdit from '../ProfileEdit/ProfileEdit';

export default function Profile(props) {

    const setAuthStatus = props.setAuthStatus;
    const [profile, setProfile] = React.useState({})
    const [selectedInterest, setSelectedInterest] = React.useState([]);
    const [remainingInterest, setRemainingInterest] = React.useState([]);
    const [edit, setEdit] = React.useState(0);
    const setProgress = props.setProgress;

    const navigate = useNavigate();

    React.useEffect(() => {
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        setProgress(20);
        if (access && refresh) {
            axios.get('https://romeo.travezco.com/users/', {
                headers: {
                    "Authorization": `Bearer ${access}`
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        setProgress(60);
                        setProfile(res.data);
                        setSelectedInterest(res.data.selectedInterest);
                        setRemainingInterest(res.data.remainingInterest);
                        setProgress(100);
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        setProgress(40)
                        axios.post('https://romeo.travezco.com/users/token/refresh/', {
                            refresh: refresh
                        })
                            .then(res => {
                                setProgress(60)
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.get('https://romeo.travezco.com/users/', {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            setProgress(80);
                                            setProfile(res.data);
                                            setSelectedInterest(res.data.selectedInterest);
                                            setRemainingInterest(res.data.remainingInterest);
                                            setProgress(100);
                                        }
                                    })
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                    setProgress(100);
                                    setAuthStatus(false);
                                }
                                navigate('/');
                            })
                    }
                })
        } else {
            navigate('/');
        }
    }, [setProgress, navigate, setAuthStatus])

    function callPhotoInput() {
        const profilePhotoUpdateInput = document.getElementById('profile-photo-update-input');
        profilePhotoUpdateInput.click();
    }

    const cookies = new Cookies();

    function updateProfilePhoto() {
        setProgress(20);
        const photoInput = document.getElementById('profile-photo-update-input');
        const photo = photoInput.files[0];
        const formData = new FormData();
        formData.append('profilePicture', photo);
        axios.put('https://romeo.travezco.com/users/update/profilephoto/', formData, {
            headers: {
                "Authorization": `Bearer ${cookies.get('access')}`
            }
        })
        .then(res => {
            setProgress(100);
            if (res.status === 200) {
                    alert(res.data.message);
                }
            }
            )
    }

    function manageInterest(interest) {
        axios.put('https://romeo.travezco.com/users/update/interest/', {
            interest: interest
        }, {
            headers: {
                "Authorization": `Bearer ${cookies.get('access')}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setSelectedInterest(res.data.selectedInterest);
                    setRemainingInterest(res.data.remainingInterest);
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: cookies.get('refresh')
                    })
                        .then(res => {
                            cookies.set('access', res.data.access, { path: '/' });
                            axios.put('https://romeo.travezco.com/users/update/interest/', {
                                interest: interest
                            }, {
                                headers: {
                                    "Authorization": `Bearer ${res.data.access}`
                                }
                            })
                                .then(res => {  
                                    if (res.status === 200) {
                                        setSelectedInterest(res.data.selectedInterest);
                                        setRemainingInterest(res.data.remainingInterest);
                                    }
                                })
                                .catch(err => {
                                    alert(err.response.data.message);
                                })
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                navigate('/');
                                setAuthStatus(false);
                            }
                        }
                        )
                    } else {
                        alert(err.response.data.message);
                    }
                })      
    }

    return (
        <>
            <div className="my-profile">
                <div className="profile-header">
                    <div className="profile-cover">
                        <img src="https://www.colorbook.io/imagecreator.php?width=1920&height=1080" alt="cover" />
                    </div>
                    <div className="profile-photo">
                        <img src={profile.profilePicture} alt="profile" />
                        <input type="file" name="profile-photo" accept="image/*" id="profile-photo-update-input" onChange={updateProfilePhoto} hidden />
                        <i className="fa-solid fa-pen" onClick={callPhotoInput}></i>
                    </div>
                    <div className="profile-details">
                        <h1>{profile.name}</h1>
                        <p>{profile.bio} <i className="fa-solid fa-pen" onClick={() => setEdit(1)}></i></p>
                    </div>
                </div>
                <div className="profile-detail-box">
                    <div className="profile-details-box-header">
                        <h2>Personal Details</h2>
                        <i className="fa-solid fa-pen" onClick={() => setEdit(2)}></i>
                    </div>
                    <div className="profile-details-box-details">
                        <div className="profile-detail">
                            <h4>Name</h4>
                            <p>{profile.name ? profile.name : <span className="profile-edit-add" onClick={() => setEdit(2)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Gender</h4>
                            <p>{profile.gender ? profile.gender : <span className="profile-edit-add" onClick={() => setEdit(2)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Marital Status</h4>
                            <p>{profile.maritalStatus ? profile.maritalStatus : <span className="profile-edit-add" onClick={() => setEdit(2)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Date of Birth</h4>
                            <p>{profile.dateOfBirth ? profile.dateOfBirth : <span className="profile-edit-add" onClick={() => setEdit(2)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Location</h4>
                            <p>{profile.location ? profile.location : <span className="profile-edit-add" onClick={() => setEdit(2)}>+ Add</span>}</p>
                        </div>
                    </div>
                </div>
                <div className="profile-detail-box">
                    <div className="profile-details-box-header">
                        <h2>Interests</h2>
                    </div>
                    <div className="profile-interests">
                            {selectedInterest.map(interest => {
                                return <div className="profile-interest selected-interest" onClick={() => manageInterest(interest)} key={interest}>{interest}</div>
                            })}
                            {remainingInterest.map(interest => {
                                return <div className="profile-interest" onClick={() => manageInterest(interest)} key={interest}>{interest}</div>
                            })}

                    </div>
                </div>
                <div className="profile-detail-box">
                    <div className="profile-details-box-header">
                        <h2>Contact Details</h2>
                        <i className="fa-solid fa-pen" onClick={() => setEdit(3)}></i>
                    </div>
                    <div className="profile-details-box-details">
                        <div className="profile-detail">
                            <h4>Mobile Number</h4>
                            <p>+91 {profile.mobileNumber}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Email Address</h4>
                            <p>{profile.emailAddress ? profile.emailAddress : <span className="profile-edit-add" onClick={() => setEdit(3)}>+ Add</span>}</p>
                        </div>
                    </div>
                </div>
                <div className="profile-detail-box">
                    <div className="profile-details-box-header">
                        <h2>Billing Address</h2>
                        <i className="fa-solid fa-pen" onClick={() => setEdit(4)}></i>
                    </div>
                    <div className="profile-details-box-details">
                        <div className="profile-detail">
                            <h4>Address Line 1</h4>
                            <p>{profile.addressLine1 ? profile.addressLine1 : <span className="profile-edit-add" onClick={() => setEdit(4)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Address Line 2</h4>
                            <p>{profile.addressLine2 ? profile.addressLine2 : <span className="profile-edit-add" onClick={() => setEdit(4)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Town/City</h4>
                            <p>{profile.city ? profile.city : <span className="profile-edit-add" onClick={() => setEdit(4)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>State</h4>
                            <p>{profile.state ? profile.state : <span className="profile-edit-add" onClick={() => setEdit(4)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>Country</h4>
                            <p>{profile.country ? profile.country : <span className="profile-edit-add" onClick={() => setEdit(4)}>+ Add</span>}</p>
                        </div>
                        <div className="profile-detail">
                            <h4>PIN Code</h4>
                            <p>{profile.pincode ? profile.pincode : <span className="profile-edit-add" onClick={() => setEdit(4)}>+ Add</span>}</p>
                        </div>
                    </div>
                </div>
            </div>
            {edit ? <ProfileEdit profile={profile} edit={edit} setEdit={setEdit} setAuthStatus={setAuthStatus} /> : null}
        </>
    )
}
