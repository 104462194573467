import React from 'react'
import './whyus.css'
import WhyUsCard from './WhyUsCard'
import LocationSpecific from '../../assets/icons/location-specific.svg'
import Quality from '../../assets/icons/quality.svg'
import Support from '../../assets/icons/support.svg'
import SecurePayment from '../../assets/icons/secure-payment.svg'

export default function WhyUs() {
    return (
        <div className="why-us">
            <h2>Why Us?</h2>
            <div className="why-us-cards">
                <WhyUsCard image={LocationSpecific} text="Location Specific Service Provider" />
                <WhyUsCard image={Quality} text="Quality Assurance" />
                <WhyUsCard image={Support} text="24x7 Support" />
                <WhyUsCard image={SecurePayment} text="Secure Transaction" />
            </div>
        </div>
    )
}
