import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import './tripcard.css'
import star from '../../assets/icons/star-white.svg'
import day from '../../assets/icons/day.svg'
import offer from '../../assets/icons/card-offer.svg'
import arrow from '../../assets/icons/trip-card-arrow.svg'
import flight from '../../assets/icons/flight.svg'
import flight2 from '../../assets/icons/flight-inactive.svg'
import stay from '../../assets/icons/stay.svg'
import stay2 from '../../assets/icons/stay-inactive.svg'
import meal from '../../assets/icons/meal.svg'
import meal2 from '../../assets/icons/meal-inactive.svg'
import transfers from '../../assets/icons/transfers.svg'
import transfers2 from '../../assets/icons/transfers-inactive.svg'
import price from '../../assets/images/trip-card-price.svg'
import christmas from '../../assets/images/christmas-special.png'
import winter from '../../assets/images/winter-special.png'
import honeymoon from '../../assets/images/honeymoon-special.png'
import axios from 'axios'


export default function TripCard(props) {

    const authStatus = props.authStatus;
    const setAuth = props.setAuth;
    const setAuthStatus = props.setAuthStatus;

    function manageWishlist(event) {
        if (!authStatus) {
            setAuth(true);
            return
        }

        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        var body = { tripId: props.id };
        var header = { "Authorization": `Bearer ${access}` };

        axios.put('https://romeo.travezco.com/users/update/wishlist/', body, { headers: header })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.message === 'Trip added to wishlist') {
                        if (event.target.classList.contains('trip-card-wishlist')) {
                            event.target.innerHTML = '<i class="fa-solid fa-heart trip-card-wishlisted"></i>';
                        } else {
                            event.target.classList.add('trip-card-wishlisted');
                            event.target.classList.remove('fa-regular');
                            event.target.classList.add('fa-solid');
                        }
                    } else {
                        if (event.target.classList.contains('trip-card-wishlist')) {
                            event.target.innerHTML = '<i class="fa-regular fa-heart"></i>';
                        } else {
                            event.target.classList.remove('trip-card-wishlisted');
                            event.target.classList.remove('fa-solid');
                            event.target.classList.add('fa-regular');
                        }
                    }
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                header = { "Authorization": `Bearer ${res.data.access}` };
                                axios.put('https://romeo.travezco.com/users/update/wishlist/', body, { headers: header })
                                    .then(res => {
                                        if (res.status === 200) {
                                            if (res.data.message === 'Trip added to wishlist') {
                                                if (event.target.classList.contains('trip-card-wishlist')) {
                                                    event.target.innerHTML = '<i class="fa-solid fa-heart trip-card-wishlisted"></i>';
                                                } else {
                                                    event.target.classList.add('trip-card-wishlisted');
                                                    event.target.classList.remove('fa-regular');
                                                    event.target.classList.add('fa-solid');
                                                }
                                            } else {
                                                if (event.target.classList.contains('trip-card-wishlist')) {
                                                    event.target.innerHTML = '<i class="fa-regular fa-heart"></i>';
                                                } else {
                                                    event.target.classList.remove('trip-card-wishlisted');
                                                    event.target.classList.remove('fa-solid');
                                                    event.target.classList.add('fa-regular');
                                                }
                                            }
                                        }
                                    })
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                setAuth(false);
                                setAuthStatus(false);
                            }
                        })
                }
            })
    }

    return (
        <div className="trip-card">
            {props.specialTag === 'W' ? <Link to={`/package/${props.origin}-to-${props.destination}-${props.id}`} className="trip-card-anchor"><div className="trip-card-overlay winter-special">
                <img src={winter} alt="winter-special" />
            </div></Link> : null}
            {props.specialTag === 'C' ? <Link to={`/package/${props.origin}-to-${props.destination}-${props.id}`} className="trip-card-anchor"><div className="trip-card-overlay christmas-special">
                <img src={christmas} alt="christmas-special" />
            </div></Link> : null}
            {props.specialTag === 'H' ? <Link to={`/package/${props.origin}-to-${props.destination}-${props.id}`} className="trip-card-anchor"><div className="trip-card-overlay honeymoon-special">
                <img src={honeymoon} alt="honeymoon-special" />
            </div></Link> : null}
            <div className="trip-card-header">
                <Link to={`/package/${props.origin}-to-${props.destination}-${props.id}`} className="trip-card-anchor"><div className="trip-card-image">
                    <img src={props.image} alt="trip" />
                </div></Link>
                <div className="trip-card-rating">
                    <img src={star} alt="rating" />
                    <p>{props.rating}</p>
                </div>
                <div className="trip-card-wishlist" onClick={manageWishlist}>
                    {props.wishlist ? <i className="fa-solid fa-heart trip-card-wishlisted"></i> : <i className="fa-regular fa-heart"></i>}
                </div>
                <div className="trip-card-duration">
                    <img src={day} alt="duration" />
                    <p>{props.duration}</p>
                </div>
                <div className="trip-card-offer">
                    <div className="trip-card-offer-image">
                        <img src={offer} alt="offer" />
                    </div>
                    <div className="trip-card-offer-text">
                        <p>{props.discount}% off</p>
                    </div>
                </div>
            </div>
            <Link to={`/package/${props.origin}-to-${props.destination}-${props.id}`} className="trip-card-anchor"><div className="trip-card-main">
                <div className="trip-card-location">
                    <h2>{props.origin}</h2>
                    <img src={arrow} alt="arrow" />
                    <h2>{props.destination}</h2>
                </div>
                <div className="trip-card-inclusions">
                    <div className="trip-card-inclusion">
                        {props.flight ? <img src={flight} alt="flight" /> : <img src={flight2} alt="flight" />}
                        {props.flight ? <p>Flight</p> : <p className="inactive-inclusion">Flight</p>}
                    </div>
                    <div className="trip-card-inclusion">
                        {props.meal ? <img src={meal} alt="meal" /> : <img src={meal2} alt="meal" />}
                        {props.meal ? <p>Meal</p> : <p className="inactive-inclusion">Meal</p>}
                    </div>
                    <div className="trip-card-inclusion">
                        {props.stay ? <img src={stay} alt="stay" /> : <img src={stay2} alt="stay" />}
                        {props.stay ? <p>Stay</p> : <p className="inactive-inclusion">Stay</p>}
                    </div>
                    <div className="trip-card-inclusion">
                        {props.transfer ? <img src={transfers} alt="transfers" /> : <img src={transfers2} alt="transfers" />}
                        {props.transfer ? <p>Transfer</p> : <p className="inactive-inclusion">Transfer</p>}
                    </div>
                </div>
                <div className="trip-card-highlights">
                    <ul>
                        {props.highlights.map((highlight, key) => {
                            return (
                                <li key={key}>{highlight}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
                <div className="trip-card-footer">
                    {props.date ? <div className="trip-card-departure-date">
                        <h4>Next Departing</h4>
                        <p>{props.date}</p>
                    </div> : null}
                    <div className="trip-card-price">
                        <img src={price} alt="price" />
                        <div className="trip-card-price-details">
                            <p className="trip-card-price-old">₹ {props.costPrice}</p>
                            <p className="trip-card-price-new">₹ {props.sellingPrice}</p>
                            <p className="trip-card-price-text">per person</p>
                        </div>
                    </div>
                </div></Link>
        </div>
    )
}
