import React from 'react'
import './auth.css'
import { useState } from 'react'
import LoginScreen1 from './Login/LoginScreen1'
import LoginScreen2 from './Login/LoginScreen2'
import SignupScreen1 from './Signup/SignupScreen1'
import SignupScreen2 from './Signup/SignupScreen2'
import SignupScreen3 from './Signup/SignupScreen3'
import authImage from '../../assets/images/auth-art.svg'

export default function Auth(props) {

    const [auth, setAuth] = useState(0);
    const [userId, setUserId] = useState(null);
    const [userParam, setUserParam] = useState(null);

    window.addEventListener('click', (e) => {
        const authModal = document.querySelector('.auth-modal');
        if (e.target === authModal) {
            props.authState(false);
        }
    })

    if (auth === 999) {
        return null;
    }

    return (
        <div className="auth-modal">
            <div className="auth-box">
                <div className="auth-left">
                    <h3>Dive <br /> into an amazing travelling experience</h3>
                    <div className="auth-image">
                        <img src={authImage} alt="auth" />
                    </div>
                </div>
                {auth === 0 ? <LoginScreen1 authState={setAuth} userId={setUserId} userParam={setUserParam} viewAuth={props.authState} /> : null}
                {auth === 1 ? <LoginScreen2 authState={setAuth} userId={userId} userParam={userParam} authStatus={props.authStatus} userIcon={props.userIcon} /> : null}
                {auth === 2 ? <SignupScreen1 authState={setAuth} userId={setUserId} userParam={setUserParam} viewAuth={props.authState} /> : null}
                {auth === 3 ? <SignupScreen2 authState={setAuth} userId={userId} userParam={userParam} authStatus={props.authStatus} userIcon={props.userIcon} /> : null}
                {auth === 4 ? <SignupScreen3 authState={setAuth} authStatus={props.authStatus} /> : null}
            </div>
        </div>
    )
}