import React from 'react'
import { Link } from 'react-router-dom'

export default function MobileNavDestinations(props) {

    const destinations = props.destinations;

    return (
        <>
            <div className="mobile-nav-destinations-go-back" onClick={() => props.navstate(-1)}>
                <i className="fa-solid fa-angle-left"></i>
                Go Back
            </div>
            <div className="mobile-nav-destinations">
                <div className="mobile-nav-destinations-header">
                    <h3>Mountains</h3>
                    <div className="mobile-nav-destinations-header-underline"></div>
                </div>
                <div className="mobile-nav-destinations-list">
                    {destinations.map((destination, key) => {
                        if (destination.category === 'Mountains') {
                            return (
                                <Link to={`/destination/${destination.name}`} className="mobile-nav-destinations-list-item" onClick={() => props.navstate(0)}>{destination.name}</Link>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
                <div className="mobile-nav-destinations-header">
                    <h3>Trekking</h3>
                    <div className="mobile-nav-destinations-header-underline"></div>
                </div>
                <div className="mobile-nav-destinations-list">
                    {destinations.map((destination, key) => {
                        if (destination.category === 'Trekking') {
                            return (
                                <Link to={`/destination/${destination.name}`} className="mobile-nav-destinations-list-item" onClick={() => props.navstate(0)}>{destination.name}</Link>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
                <div className="mobile-nav-destinations-header">
                    <h3>Religious</h3>
                    <div className="mobile-nav-destinations-header-underline"></div>
                </div>
                <div className="mobile-nav-destinations-list">
                    {destinations.map((destination, key) => {
                        if (destination.category === 'Religious') {
                            return (
                                <Link to={`/destination/${destination.name}`} className="mobile-nav-destinations-list-item" onClick={() => props.navstate(0)}>{destination.name}</Link>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
                <div className="mobile-nav-destinations-header">
                    <h3>Heritage</h3>
                    <div className="mobile-nav-destinations-header-underline"></div>
                </div>
                <div className="mobile-nav-destinations-list">
                    {destinations.map((destination, key) => {
                        if (destination.category === 'Heritage') {
                            return (
                                <Link to={`/destination/${destination.name}`} className="mobile-nav-destinations-list-item" onClick={() => props.navstate(0)}>{destination.name}</Link>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
                <div className="mobile-nav-destinations-header">
                    <h3>Must Visit</h3>
                    <div className="mobile-nav-destinations-header-underline"></div>
                </div>
                <div className="mobile-nav-destinations-list">
                    {destinations.map((destination, key) => {
                        if (destination.category === 'Other') {
                            return (
                                <Link to={`/destination/${destination.name}`} className="mobile-nav-destinations-list-item" onClick={() => props.navstate(0)}>{destination.name}</Link>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            </div>
        </>
    )
}
