import React from 'react'
import './checkout.css'

export default function AddAddress(props) {

    //eslint-disable-next-line
    const setAddress = props.setAddress;
    const setAddressValid = props.setAddressValid;

    React.useEffect(() => {
        const data = props.checkoutData;
        setAddress({
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            country: data.country,
            pincode: data.pincode
        })
        if (data.addressLine1 && data.addressLine2 && data.city && data.state && data.country && data.pincode.length === 6) {
            setAddressValid(true);
            document.getElementById('state').value = data.state;
        }
    }, [props.checkoutData, setAddress, setAddressValid])

    function validateAddress() {
        const addressLine1 = document.getElementById('address-line-1');
        const addressLine2 = document.getElementById('address-line-2');
        const city = document.getElementById('city');
        const pincode = document.getElementById('pincode');
        if (!addressLine1.value) {
            addressLine1.classList.add('error-input');
            setAddressValid(false);
        } else {
            addressLine1.classList.remove('error-input');
        }
        if (!addressLine2.value) {
            addressLine2.classList.add('error-input');
            setAddressValid(false);
        } else {
            addressLine2.classList.remove('error-input');
        }
        if (!city.value) {
            city.classList.add('error-input');
            setAddressValid(false);
        } else {
            city.classList.remove('error-input');
        }
        if (pincode.value.length !== 6) {
            pincode.classList.add('error-input');
            setAddressValid(false);
        } else {
            pincode.classList.remove('error-input');
        }
        if (addressLine1.value && addressLine2.value && city.value && pincode.value.length === 6) {
            setAddressValid(true);
            setAddress({
                addressLine1: addressLine1.value,
                addressLine2: addressLine2.value,
                city: city.value,
                state: document.getElementById('state').value,
                country: document.getElementById('country').value,
                pincode: pincode.value
            })
        }
    }

    return (
        <div className="add-address">
            <div className="add-address-input-line">
                <div className="add-address-input">
                    <p>Address Line 1</p>
                    <input type="text" name="address-line-1" id="address-line-1" defaultValue={props.checkoutData.addressLine1} onKeyUp={validateAddress} />
                </div>
                <div className="add-address-input">
                    <p>Address Line 2</p>
                    <input type="text" name="address-line-2" id="address-line-2" defaultValue={props.checkoutData.addressLine2} onKeyUp={validateAddress} />
                </div>
            </div>
            <div className="add-address-input-line">
                <div className="add-address-input">
                    <p>City</p>
                    <input type="text" name="city" id="city" defaultValue={props.checkoutData.city} onKeyUp={validateAddress} />
                </div>
                <div className="add-address-input">
                    <p>State</p>
                    <select name="state" id="state" defaultValue={props.checkoutData.state}>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Delhi/U.T./Other">Delhi/U.T./Other</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Orissa">Orissa</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                    </select>
                </div>
            </div>
            <div className="add-address-input-line">
                <div className="add-address-input">
                    <p>Country</p>
                    <select name="country" id="country" defaultValue={props.checkoutData.country}>
                        <option value="India">India</option>
                    </select>
                </div>
                <div className="add-address-input">
                    <p>Pincode</p>
                    <input type="text" name="pincode" id="pincode" defaultValue={props.checkoutData.pincode} onKeyUp={validateAddress} />
                </div>
            </div>
        </div>
    )
}
