import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
// import Cookies from 'universal-cookie'
// import axios from 'axios'
import timeline from '../../assets/icons/timeline.svg'
import offer from '../../assets/icons/offer.svg'
import offerBlack from '../../assets/icons/offer-black.svg'
import serviceProvider from '../../assets/icons/service-provider.svg'
import route from '../../assets/icons/route.svg'
// import reviews from '../../assets/icons/reviews.svg'
// import starBlue from '../../assets/icons/star-blue.svg'
// import starBlack from '../../assets/icons/star-black.svg'
// import starDarkBlue from '../../assets/icons/star-dark-blue.svg'
// import thumbUp from '../../assets/icons/thumb-up.svg'
// import thumbDown from '../../assets/icons/thumb-down.svg'
// import faqs from '../../assets/icons/faqs.svg'
import calendar from '../../assets/icons/calendar.svg'
import TripCard from '../TripCards/TripCard'

export default function ItineraryDetails(props) {

    // const cookies = new Cookies();
    // const access = cookies.get('access');
    // const refresh = cookies.get('refresh');
    const [selectedDay, setSelectedDay] = React.useState();
    const navigate = useNavigate();

    React.useEffect(() => {
        setSelectedDay(props.days[0]);
    }, [props.days])

    function clickDay() {
        const days = document.querySelectorAll('.day-item');
        days.forEach(day => {
            day.classList.remove('selected-day');
        })
        var element = document.getElementById(`day-selector-${selectedDay + 1}`);
        if (element) {
            switchDay({ day: selectedDay + 1 });
        } else {
            if (document.getElementById(`day-selector-0`)) {
                switchDay({ day: 0 });
            } else {
                switchDay({ day: 1 });
            }
        }
    }

    function switchDay(day) {
        setSelectedDay(day.day);
        const days = document.querySelectorAll('.day-item');
        days.forEach(day => {
            day.classList.remove('selected-day');
        })
        document.getElementById(`day-selector-${day.day}`).classList.add('selected-day');
        var element = document.getElementById(`day-selector-${day.day}`);
        var parent = document.querySelector('.days-nav-inner');
        var rect = element.getBoundingClientRect();
        var parentRect = parent.getBoundingClientRect();
        var offset = rect.left - parentRect.left;
        var scroll = parent.scrollLeft;
        var middle = parent.offsetWidth / 2;
        parent.scrollLeft = scroll + offset - middle + (rect.width / 2);
    }

    function offerTncHandler(key) {
        var div = document.getElementById(`offer-terms-${key}`);
        var angle = document.getElementById(`term-angle-${key}`);
        if (div.style.height === "100%") {
            div.style.height = "0";
            div.style.overflow = "hidden";
            angle.style.transform = "rotate(0deg)";
        } else {
            div.style.height = "100%";
            div.style.overflow = "visible";
            angle.style.transform = "rotate(180deg)";
        }
    }

    // function upvote(reviewId) {
    //     if (!props.authStatus) {
    //         props.setAuth(true);
    //         return;
    //     }
    //     var header = { "Authorization": `Bearer ${access}` }
    //     var body = { "reviewId": reviewId }
    //     axios.post('https://romeo.travezco.com/trips/review/upvote/', body, { headers: header })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 const voteImage = document.getElementById(`review-upvote-${reviewId}`);
    //                 const voteCount = document.getElementById(`review-upvote-count-${reviewId}`);
    //                 const downVoteImage = document.getElementById(`review-downvote-${reviewId}`);
    //                 const downVoteCount = document.getElementById(`review-downvote-count-${reviewId}`);
    //                 if (res.data.config === 0) {
    //                     voteImage.classList.remove('upvoted');
    //                     voteCount.innerHTML = parseInt(voteCount.innerHTML) - 1;
    //                 } else if (res.data.config === 1) {
    //                     voteImage.classList.add('upvoted');
    //                     voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                     downVoteImage.classList.remove('downvoted');
    //                     downVoteCount.innerHTML = parseInt(downVoteCount.innerHTML) - 1;
    //                 } else if (res.data.config === 2) {
    //                     voteImage.classList.add('upvoted');
    //                     voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                 }
    //             }
    //         })
    //         .catch(err => {
    //             if (err.response.status === 401) {
    //                 axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
    //                     .then(res => {
    //                         if (res.status === 200) {
    //                             cookies.set('access', res.data.access, { path: '/' });
    //                             header = { "Authorization": `Bearer ${res.data.access}` }
    //                             axios.post('https://romeo.travezco.com/trips/review/upvote/', body, { headers: header })
    //                                 .then(res => {
    //                                     if (res.status === 200) {
    //                                         const voteImage = document.getElementById(`review-upvote-${reviewId}`);
    //                                         const voteCount = document.getElementById(`review-upvote-count-${reviewId}`);
    //                                         const downVoteImage = document.getElementById(`review-downvote-${reviewId}`);
    //                                         const downVoteCount = document.getElementById(`review-downvote-count-${reviewId}`);
    //                                         if (res.data.config === 0) {
    //                                             voteImage.classList.remove('upvoted');
    //                                             voteCount.innerHTML = parseInt(voteCount.innerHTML) - 1;
    //                                         } else if (res.data.config === 1) {
    //                                             voteImage.classList.add('upvoted');
    //                                             voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                                             downVoteImage.classList.remove('downvoted');
    //                                             downVoteCount.innerHTML = parseInt(downVoteCount.innerHTML) - 1;
    //                                         } else if (res.data.config === 2) {
    //                                             voteImage.classList.add('upvoted');
    //                                             voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                                         }
    //                                     }
    //                                 })
    //                         }
    //                     })
    //                     .catch(err => {
    //                         if (err.response.status === 401) {
    //                             cookies.remove('access', { path: '/' });
    //                             cookies.remove('refresh', { path: '/' });
    //                             props.setAuth(false);
    //                         }
    //                     })
    //             }
    //         })
    // }

    // function downvote(reviewId) {
    //     if (!props.authStatus) {
    //         props.setAuth(true);
    //         return;
    //     }
    //     var header = { "Authorization": `Bearer ${access}` }
    //     var body = { "reviewId": reviewId }
    //     axios.post('https://romeo.travezco.com/trips/review/downvote/', body, { headers: header })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 const voteImage = document.getElementById(`review-downvote-${reviewId}`);
    //                 const voteCount = document.getElementById(`review-downvote-count-${reviewId}`);
    //                 const upVoteImage = document.getElementById(`review-upvote-${reviewId}`);
    //                 const upVoteCount = document.getElementById(`review-upvote-count-${reviewId}`);
    //                 if (res.data.config === 0) {
    //                     voteImage.classList.remove('downvoted');
    //                     voteCount.innerHTML = parseInt(voteCount.innerHTML) - 1;
    //                 } else if (res.data.config === 1) {
    //                     voteImage.classList.add('downvoted');
    //                     voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                     upVoteImage.classList.remove('upvoted');
    //                     upVoteCount.innerHTML = parseInt(upVoteCount.innerHTML) - 1;
    //                 } else if (res.data.config === 2) {
    //                     voteImage.classList.add('downvoted');
    //                     voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                 }
    //             }
    //         })
    //         .catch(err => {
    //             if (err.response.status === 401) {
    //                 axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
    //                     .then(res => {
    //                         if (res.status === 200) {
    //                             cookies.set('access', res.data.access, { path: '/' });
    //                             header = { "Authorization": `Bearer ${res.data.access}` }
    //                             axios.post('https://romeo.travezco.com/trips/review/downvote/', body, { headers: header })
    //                                 .then(res => {
    //                                     if (res.status === 200) {
    //                                         const voteImage = document.getElementById(`review-downvote-${reviewId}`);
    //                                         const voteCount = document.getElementById(`review-downvote-count-${reviewId}`);
    //                                         const upVoteImage = document.getElementById(`review-upvote-${reviewId}`);
    //                                         const upVoteCount = document.getElementById(`review-upvote-count-${reviewId}`);
    //                                         if (res.data.config === 0) {
    //                                             voteImage.classList.remove('downvoted');
    //                                             voteCount.innerHTML = parseInt(voteCount.innerHTML) - 1;
    //                                         } else if (res.data.config === 1) {
    //                                             voteImage.classList.add('downvoted');
    //                                             voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                                             upVoteImage.classList.remove('upvoted');
    //                                             upVoteCount.innerHTML = parseInt(upVoteCount.innerHTML) - 1;
    //                                         } else if (res.data.config === 2) {
    //                                             voteImage.classList.add('downvoted');
    //                                             voteCount.innerHTML = parseInt(voteCount.innerHTML) + 1;
    //                                         }
    //                                     }
    //                                 })
    //                         }
    //                     })
    //                     .catch(err => {
    //                         if (err.response.status === 401) {
    //                             cookies.remove('access', { path: '/' });
    //                             cookies.remove('refresh', { path: '/' });
    //                             props.setAuth(false);
    //                         }
    //                     })
    //             }
    //         })
    // }

    // function handleInclusion() {
    //     var div = document.getElementById('inclusion-details');
    //     var angle = document.getElementById('inclusion-angle');
    //     if (div.style.height === "100%") {
    //         div.style.height = "0";
    //         div.style.overflow = "hidden";
    //         angle.style.transform = "rotate(0deg)";
    //     } else {
    //         div.style.height = "100%";
    //         div.style.overflow = "visible";
    //         angle.style.transform = "rotate(-180deg)";
    //     }
    // }

    // function handleExclusion() {
    //     var div = document.getElementById('exclusion-details');
    //     var angle = document.getElementById('exclusion-angle');
    //     if (div.style.height === "100%") {
    //         div.style.height = "0";
    //         div.style.overflow = "hidden";
    //         angle.style.transform = "rotate(0deg)";
    //     } else {
    //         div.style.height = "100%";
    //         div.style.overflow = "visible";
    //         angle.style.transform = "rotate(-180deg)";
    //     }
    // }

    // function handleFaq(key) {
    //     var div = document.getElementById(`faq-answer-${key}`);
    //     var plus = document.getElementById(`faq-plus-${key}`);
    //     if (div.style.height === "100%") {
    //         div.style.height = "0";
    //         div.style.overflow = "hidden";
    //         plus.classList.remove('fa-minus');
    //         plus.classList.add('fa-plus');
    //     } else {
    //         div.style.height = "100%";
    //         div.style.overflow = "visible";
    //         plus.classList.remove('fa-plus');
    //         plus.classList.add('fa-minus');
    //     }
    // }

    function initiateBooking() {
        if (!props.authStatus) {
            props.setAuth(true);
            return;
        }
        navigate('checkout')
    }

    function showMoreDates() {
        var overlay = document.getElementById('dates-overlay');
        overlay.style.display = "flex";
    }

    function closeDatesOverlay() {
        var overlay = document.getElementById('dates-overlay');
        overlay.style.display = "none";
    }

    window.addEventListener('click', function (event) {
        var overlay = document.getElementById('dates-overlay');
        if (event.target === overlay) {
            overlay.style.display = "none";
        }
    })

    return (
        <>
            <div className="itinerary">
                <div className="itinerary-left">
                    {props.dates ? <><div className="dates-for-booking">
                        <h3>
                            Dates for Booking
                            <img src={calendar} alt="Calender" />
                        </h3>
                        <div className="date-cards">
                            {props.dates.map((date, key) => {
                                if (key > 2) return null;
                                return (
                                    <p className="date-card" key={key}>{date.date}</p>
                                )
                            })}
                            {props.dates.length > 3 ? <p className="date-view-more" onClick={showMoreDates}>View All</p> : null}
                        </div>
                    </div>
                        <div className="dates-overlay" id="dates-overlay">
                            <div className="dates-modal">
                                <div className="dates-modal-header">
                                    <h3>Dates for Booking</h3>
                                </div>
                                <div className="dates-modal-body">
                                    {props.dates.map((date, key) => {
                                        return (
                                            <div className="dates-modal-body-date" key={key}>
                                                <p className="date-card">{date.date}</p>
                                                {date.vacancy > 7 ? <p className="date-availability green">{date.vacancy} Seats Available</p> : <p className="date-availability red">{date.vacancy} Seats Available</p>}
                                            </div>
                                        )
                                    })}
                                </div>
                                <button onClick={closeDatesOverlay}>Close</button>
                            </div>
                        </div></> : null}
                    <div className="timeline">
                        <h3>
                            Timeline
                            <img src={timeline} alt="Timeline" />
                        </h3>
                        <div className="days-nav">
                            <div className="days-nav-inner">
                                {props.days.map((day, key) => {
                                    if (!key) {
                                        return (
                                            <p key={day} className="day-item selected-day" id={`day-selector-${day}`} onClick={() => switchDay({ day })}>Day {day}</p>
                                        )
                                    }
                                    return (
                                        <p key={day} className="day-item" id={`day-selector-${day}`} onClick={() => switchDay({ day })}>Day {day}</p>
                                    )
                                })}
                            </div>
                            <div className="date-nav-navigator" onClick={clickDay}>
                                &rarr;
                            </div>
                        </div>
                        {props.days.map((day, key) => {
                            if (day === selectedDay) {
                                return (
                                    <div className="timeline-items" id={`itinerary-day-${day}`} key={key}>
                                        <div className="timeline-line"></div>
                                        {props.itinerary.map((item, newKey) => {
                                            if (day === item.day) {
                                                return (
                                                    <div className="timeline-item" key={newKey}>
                                                        <div className="timeline-header">
                                                            <div className="timeline-header-item">
                                                                <div className="timeline-index"></div>
                                                                <h4>{item.title}</h4>
                                                            </div>
                                                            {/* <div className="timeline-header-item">
                                                                <h4>{item.time}</h4>
                                                            </div> */}
                                                        </div>
                                                        <div className="timeline-box">
                                                            {item.image ?
                                                                <>
                                                                    <div className="timeline-image">
                                                                        <img src={item.image} alt='itinerary' />
                                                                    </div>
                                                                    <div className="timeline-details">
                                                                        <h5>{item.subtitle}</h5>
                                                                        <p>{item.description}</p>
                                                                    </div>
                                                                </> : <div className="timeline-details width-100">
                                                                <h5>{item.subtitle}</h5>
                                                                <p>{item.description}</p>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return null;
                                        })}
                                    </div>
                                )
                            }
                            return (
                                <div className="timeline-items" id={`itinerary-day-${day}`} style={{ display: "none" }} key={key}>
                                    <div className="timeline-line"></div>
                                    {props.itinerary.map((item, newKey) => {
                                        if (day === item.day) {
                                            return (
                                                <div className="timeline-item" key={newKey}>
                                                    <div className="timeline-header">
                                                        <div className="timeline-header-item">
                                                            <div className="timeline-index"></div>
                                                            <h4>{item.title}</h4>
                                                        </div>
                                                        <div className="timeline-header-item">
                                                            <h4>{item.time}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-box">
                                                        {item.image ?
                                                            <div className="timeline-image">
                                                                <img src={null} alt="{{event.subtitle}}" />
                                                            </div> : null}
                                                        <div className="timeline-details {% if not event.image %}width-100{% endif %}">
                                                            <h5>{item.subtitle}</h5>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* </div> */}
                <div className="itinerary-right">
                    <div className="itinerary-price">
                        <div className="itinerary-price-item">
                            <h2>₹{props.trip.sellingPrice}</h2>
                            <p>Per Person</p>
                        </div>
                        <div className="itinerary-book">
                            <button onClick={initiateBooking}>Book Now</button>
                        </div>
                    </div>
                    {props.trip.additionalInfo ? <div className="assistance">
                        <pre>{props.trip.additionalInfo}</pre>
                    </div> : null}
                    <div className="assistance">
                        For group bookings, we recommended you to <Link to="/contact">contact us</Link>
                    </div>
                    {props.offers.length > 0 ? <div className="itinerary-component">
                        <h3>
                            Available Offers
                            <img src={offerBlack} alt="Offers" />
                        </h3>
                        {props.offers ?
                            props.offers.map((offerDetail, key) => {
                                return (
                                    <div className="offer-card" key={key}>
                                        <div className="offer-details" onClick={() => offerTncHandler(key)}>
                                            <div className="offer-details-main">
                                                <img src={offer} alt="Offer" />
                                                <p>{offerDetail.description}</p>
                                            </div>
                                            <i className="fa-solid fa-angle-down" id={`term-angle-${key}`}></i>
                                        </div>
                                        <ul className="offer-terms" id={`offer-terms-${key}`}>
                                            <h6>Terms & Conditions</h6>
                                            {offerDetail.termsAndConditions.map((tnc, key) => {
                                                return (
                                                    <li key={key}>{tnc}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            }) : null
                        }
                    </div> : null}
                    <div className="itinerary-component">
                        <div className="inclusion-exlusion-box">
                            <div className="inclusion-exclusion-box-inner">
                                <p>What's <span>Included?</span></p>
                            </div>
                            <div className="inclusion-exclusion-details" id="inclusion-details">
                                <ul>
                                    {props.tripInclusions.map((incl, key) => {
                                        return (
                                            <li key={key}>{incl}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="inclusion-exlusion-box">
                            <div className="inclusion-exclusion-box-inner">
                                <p>What's <span>Excluded?</span></p>
                            </div>
                            <div className="inclusion-exclusion-details" id="exclusion-details">
                                <ul>
                                    {props.tripExclusions.map((excl, key) => {
                                        return (
                                            <li key={key}>{excl}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="itinerary-component">
                        <h3>
                            Service Provider
                            <img src={serviceProvider} alt="service-provider" />
                        </h3>
                        <div className="service-provider">
                            {props.trip.serviceProvider}
                        </div>
                    </div>
                </div >
            </div >
            <div className="other-packages">
                <h3>
                    You may also like
                    <img src={route} alt="Other Packages" />
                </h3>
                <div className="packages-cards">
                    {props.recommendedTrips.map((trip, key) => {
                        return (
                            <TripCard
                                key={trip.id}
                                id={trip.id}
                                origin={trip.origin}
                                destination={trip.destination}
                                duration={trip.duration}
                                discount={trip.discount}
                                costPrice={trip.costPrice}
                                sellingPrice={trip.sellingPrice}
                                image={trip.primaryImage}
                                highlights={trip.highlights}
                                rating={trip.rating}
                                flight={trip.flight}
                                meal={trip.meal}
                                stay={trip.stay}
                                transfer={trip.transfer}
                                wishlist={trip.wishlist}
                                specialTag={trip.specialTag}
                                date={trip.date}
                                authStatus={props.authStatus}
                                setAuth={props.setAuth}
                                setAuthStatus={props.setAuthStatus}
                            />
                        )
                    })}
                </div>
            </div>
            {/* <div className="reviews">
                <h3>
                    Reviews
                    <img src={reviews} alt="FAQs" />
                </h3>
                <div className="reviews-container">
                    <div className="reviews-stats">
                        <div className="reviews-overview">
                            <div className="reviews-overview-inner">
                                <div className="reviews-overview-inner-inner">
                                    <div className="reviews-rating">
                                        <img src={starBlue} alt="Rating" />
                                        <p>{props.trip.rating}</p>
                                    </div>
                                    <p className="reviews-count">{props.trip.reviewCount} Ratings</p>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-stats-bars">
                            <div className="reviews-stat-bar">
                                <div className="reviews-stat-bar-head">
                                    <p>5</p>
                                    <img src={starBlack} alt="Star" />
                                </div>
                                <div className="reviews-stat-bar-body">
                                    <div className="reviews-stat-bar-body-inner" style={{ width: `${props.ratingChart[4]}%` }}></div>
                                </div>
                            </div>
                            <div className="reviews-stat-bar">
                                <div className="reviews-stat-bar-head">
                                    <p>4</p>
                                    <img src={starBlack} alt="Star" />
                                </div>
                                <div className="reviews-stat-bar-body">
                                    <div className="reviews-stat-bar-body-inner" style={{ width: `${props.ratingChart[3]}%` }}></div>
                                </div>
                            </div>
                            <div className="reviews-stat-bar">
                                <div className="reviews-stat-bar-head">
                                    <p>3</p>
                                    <img src={starBlack} alt="Star" />
                                </div>
                                <div className="reviews-stat-bar-body">
                                    <div className="reviews-stat-bar-body-inner" style={{ width: `${props.ratingChart[2]}%` }}></div>
                                </div>
                            </div>
                            <div className="reviews-stat-bar">
                                <div className="reviews-stat-bar-head">
                                    <p>2</p>
                                    <img src={starBlack} alt="Star" />
                                </div>
                                <div className="reviews-stat-bar-body">
                                    <div className="reviews-stat-bar-body-inner" style={{ width: `${props.ratingChart[1]}%` }}></div>
                                </div>
                            </div>
                            <div className="reviews-stat-bar">
                                <div className="reviews-stat-bar-head">
                                    <p>1</p>
                                    <img src={starBlack} alt="Star" />
                                </div>
                                <div className="reviews-stat-bar-body">
                                    <div className="reviews-stat-bar-body-inner" style={{ width: `${props.ratingChart[0]}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reviews-cards">
                        {props.tripReviews.map((review, key) => {
                            return (
                                <div className="review-card" key={key}>
                                    <div className="review-card-left">
                                        <img src={review.profilePicture} alt="User" />
                                        <div className="review-card-rating">
                                            <p>{review.rating}</p>
                                            <img src={starDarkBlue} alt="Rating" />
                                        </div>
                                    </div>
                                    <div className="reviews-card-right">
                                        <div className="review-card-header">
                                            <div className="review-card-details">
                                                <h6>{review.user}</h6>
                                                <p>{review.dateAdded}</p>
                                            </div>
                                            <div className="review-card-votes">
                                                <div className="review-card-vote">
                                                    <img src={thumbUp} alt="Upvote" onClick={() => upvote(review.id)} id={`review-upvote-${review.id}`} />
                                                    <p id={`review-upvote-count-${review.id}`}>{review.upvote}</p>
                                                </div>
                                                <div className="review-card-vote">
                                                    <img src={thumbDown} alt="Downvote" onClick={() => downvote(review.id)} id={`review-downvote-${review.id}`} />
                                                    <p id={`review-downvote-count-${review.id}`}>{review.downvote}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="review-card-review">
                                            <p>{review.review}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="faqs">
                <h3>
                    FAQs
                    <img src={faqs} alt="FAQs" />
                </h3>
                {props.tripFaqs.map((faq, key) => {
                    return (
                        <div className="faq-card" key={key}>
                            <div className="faq-question" onClick={() => handleFaq(key)}>
                                <p>{key + 1}. {faq.question}</p>
                                <i className="fa-solid fa-plus" id={`faq-plus-${key}`}></i>
                            </div>
                            <div className="faq-answer" id={`faq-answer-${key}`}>
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    )
                })}
            </div> */}
        </>
    )
}