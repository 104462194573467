import React from 'react'
import './query.css'
import { Link } from 'react-router-dom'
import axios from 'axios';

export default function QueryModal(props) {

    const setQuery = props.setQuery;

    window.onclick = function (event) {
        if (event.target.className === 'query-modal') {
            setQuery(false);
        }
    }

    const [name, setName] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [privacy, setPrivacy] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);

    const validateForm = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (!privacy) {
            alert('Please agree to our Privacy Policy');
            return false;
        }
        if (name === '') {
            alert('Please enter your name');
            return false;
        }
        if (mobile === '') {
            alert('Please enter your mobile number');
            return false;
        }
        if (mobile.length !== 10 || isNaN(mobile)) {
            alert('Please enter a valid 10-digit mobile number');
            return false;
        }
        if (email === '') {
            alert('Please enter your email address');
            return false;
        }
        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address');
            return false;
        }
        if (message === '') {
            alert('Please enter your query');
            return false;
        }
        if (message.length < 20) {
            alert('Please enter a query of at least 20 characters');
            return false;
        }
        return true;
    }

    const submitQuery = () => {
        if (!validateForm()) {
            setSubmit(false);
            return;
        }
        setSubmit(true);
        const body = {
            name: name,
            mobileNumber: mobile,
            emailAddress: email,
            message: message,
            tripId: props.trip.id
        }
        
        axios.post('https://romeo.travezco.com/trips/query/', body)
            .then(res => {
                setSubmit(false);
                alert('Your query has been submitted. We will get back to you shortly.');
                setQuery(false);
            })
            .catch(err => {
                setSubmit(false);
                alert('There was an error submitting your query. Please try again later.');
                setQuery(false);
            })
    }

    return (
        <div className="query-modal">
            <div className="query-box">
                <h2>Need help in planning your trip?</h2>
                <h4>Fill out this short form and we will get back to you!</h4>
                <p>If there is something we can help you with right away, call <a href='tel:+918396957516'>+91 83969 57516</a>.</p>
                <div className="query-form-fields">
                    <div className="query-form-field">
                        <input type="text" name="name" placeholder='Your Name' onKeyUp={(e) => setName(e.target.value)} />
                    </div>
                    <div className="query-form-field">
                        <input type="text" name="mobile" placeholder='Your Mobile Number' onKeyUp={(e) => setMobile(e.target.value)} />
                    </div>
                </div>
                <div className="query-form-fields">
                    <div className="query-form-field">
                        <input type="email" name="email" placeholder='Your Email Address' onKeyUp={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="query-form-fields">
                    <div className="query-form-field">
                        <textarea name="message" cols="30" rows="10" placeholder='Your Query' onKeyUp={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="query-form-fields">
                    <div className="query-form-tnc">
                        <input type="checkbox" name="privacy" onChange={(e) => setPrivacy(e.target.checked)} />
                        <label htmlFor="privacy">I agree to your friendly <Link to='/privacy'>Privacy Policy</Link></label>
                    </div>
                </div>
                <div className="query-form-fields">
                    {submit ? <button><i className='fa-solid fa-circle'></i><i className='fa-solid fa-circle'></i><i className='fa-solid fa-circle'></i></button> : <button onClick={submitQuery}>Send Query</button>}
                </div>
            </div>
        </div>
    )
}
