import React from 'react'
import { Link } from 'react-router-dom'
import './searchbar.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function SearchBar() {

    const [search, setSearch] = React.useState("#");
    const [searchInput, setSearchInput] = React.useState(null);

    const navigate = useNavigate();

    function showOverlay() {
        const overlay = document.getElementById("search-overlay");
        overlay.style.padding = "15px 20px 5px 20px";
        overlay.style.height = "max-content";
    }

    function hideOverlay() {
        const overlay = document.getElementById("search-overlay");
        overlay.style.padding = "0 20px";
        overlay.style.height = "0%";
    }

    function selectDestination(e) {
        navigate(`/destination/${e}`)
        // inputField.style.color = "#4B9ABD";
        // hideOverlay();
        // setSearch(`/destination/${e}`);
    }

    function updateSearchInput() {
        setSearchInput(document.getElementById("search-input-field").value);
    }

    React.useEffect(() => {
        showOverlay();
        const value = searchInput ? searchInput.toLowerCase() : ""; 
        const cards = document.getElementsByClassName("search-location-card");
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            const cardTextReal = card.innerText.split(" - ")[0];
            const cardText = cardTextReal.toLowerCase();
            if (cardText.indexOf(value) > -1) {
                card.style.display = "block";
            } else {
                card.style.display = "none";
            }
            if (value === cardText) {
                setSearch(`/destination/${cardTextReal}`);
            }
        }
        if (!value) {
            hideOverlay();
        }
    }, [searchInput])

    const [destinations, setDestinations] = React.useState([]);
    React.useEffect(() => {
        axios.get('https://romeo.travezco.com/trips/destinations/')
            .then(res => setDestinations(res.data))
    }, [])

    React.useEffect(() => {
        const searchInputField = document.getElementById("search-input-field");
        searchInputField.addEventListener("focus", showOverlay);
        searchInputField.addEventListener("blur", () => {
            setTimeout(() => {
                searchInputField.value = "";
                setSearchInput(null);
                try {
                    hideOverlay();
                } catch (error) {
                    return;
                }
            }, 200);
        });
    })

    return (
        <div className="search-bar">
            <div className="search-icon">
                <i className="fa-solid fa-search"></i>
            </div>
            <div className="search-input" id="search-input">
                <input type="text" placeholder="Search Destination" id="search-input-field" onKeyUp={updateSearchInput} />
            </div>
            <Link to={search} className="search-submit">
                <div className="search-go">
                    <i className="fa-solid fa-angle-right"></i>
                </div>
            </Link>
            <div className="search-overlay" id="search-overlay">
                {destinations.map(destination => {
                    return (
                        <div className="search-location-card" onClick={() => selectDestination(destination.name)} key={destination.name}>
                            <p>{destination.name} - &nbsp; <span>{destination.tagline}</span></p>
                        </div>
                    )
                })}
                <div className="search-overlay-null">
                    <p>Can't find your destination? <Link to="/contact">Contact Us</Link> for best deals!</p>
                </div>
            </div>
        </div>
    )
}
