import React from 'react'
import './profileedit.css'
import EditContactMobile from './EditContactMobile';
import EditContactEmail from './EditContactEmail';

export default function EditContact(props) {

    const setAuthStatus = props.setAuthStatus;
    const [editContact, setEditContact] = React.useState(0);

    return (
        <>
            <h3>Edit Contact Details</h3>
            <div className="edit-contact-navigate">
                <h4 onClick={() => setEditContact(0)}>
                    Email
                    {editContact === 0 ? <div className="selected-contact-nav"></div> : null }
                </h4>
                <h4 onClick={() => setEditContact(1)}>
                    Mobile
                    {editContact === 1 ? <div className="selected-contact-nav"></div> : null }
                </h4>
            </div>
            {editContact === 1 ? <EditContactMobile profile={props.profile} setEdit={props.setEdit} setAuthStatus={setAuthStatus} /> : <EditContactEmail profile={props.profile} setEdit={props.setEdit} setAuthStatus={setAuthStatus} />}
        </>
    )
}
