import React from 'react'
import './checkout.css'
import SharingCard from './SharingCard'

export default function Sharing(props) {

    const passengers = props.passengers;
    const [doublePassengers, setDoublePassengers] = React.useState([]);
    const [triplePassengers, setTriplePassengers] = React.useState([]);
    const [quadPassengers, setQuadPassengers] = React.useState([]);

    React.useEffect(() => {
        let double = [];
        let triple = [];
        let quad = [];
        for (let i = 0; i < passengers.length; i++) {
            if (passengers[i].sharing === "Double") {
                double.push(passengers[i]);
            } else if (passengers[i].sharing === "Triple") {
                triple.push(passengers[i]);
            } else if (passengers[i].sharing === "Quad") {
                quad.push(passengers[i]);
            }
        }
        setDoublePassengers(double);
        setTriplePassengers(triple);
        setQuadPassengers(quad);
    }, [passengers]);

    return (
        <>
            <div className="sharing-options">
                <SharingCard passengers={doublePassengers} allPassengers={passengers} setPassengers={props.setPassengers} price={props.checkoutData.doublePrice} sharing="Double" />
                <SharingCard passengers={triplePassengers} allPassengers={passengers} setPassengers={props.setPassengers} price={props.checkoutData.triplePrice} sharing="Triple" />
                <SharingCard passengers={quadPassengers} allPassengers={passengers} setPassengers={props.setPassengers} price={props.checkoutData.price} sharing="Quad" />
            </div>
            <p className="sharing-note">* By default, all travellers are placed in <b>Quad</b> Sharing</p>
        </>
    )
}
