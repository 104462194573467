import React from 'react'
import quote from '../../assets/icons/quote.svg'
import './pastcollab.css'

export default function PastCollabCard(props) {
    return (
        <div className="past-collaboration-card">
            <div className="past-collaboration-card-quote">
                <img src={quote} alt="quote" />
            </div>
            <div className="past-collaboration-card-text">
                <p>{props.testimonial}</p>
            </div>
            <div className="past-collaboration-card-author">
                <img src={props.image} alt="author" />
                <h5>{props.name}</h5>
                <p>{props.title}</p>
            </div>
        </div>
    )
}
