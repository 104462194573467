import React from 'react'
import './policy.css'
import cancel from '../../assets/icons/cancel.svg'

export default function Policy() {
    return (
        <div className="policies">
            <h3>
                Cancellation Policy
                <img src={cancel} alt="Policies" />
            </h3>
            <div className="cancellation-boxes">
                <div className="cancellation-box positive-box">
                    <p>30 Days Before</p>
                    <h4>₹0</h4>
                    <p>Cancellation Charges</p>
                </div>
                <div className="cancellation-box positive-box">
                    <p>15 Days Before</p>
                    <h4>50% of Advance</h4>
                    <p>Cancellation Charges</p>
                </div>
                <div className="cancellation-box negative-box">
                    <p>Less than 15 days</p>
                    <h4>Non-Refundable</h4>
                    <p>Cancellation not allowed</p>
                </div>
            </div>
            <h3>
                Terms and Conditions
                <img src={cancel} alt="Terms" />
            </h3>
            <ul className="terms-n-conditions">
                <li>Initially Travezco will be taking 40% advanced payment to confirm the booking. The remaining 60% is paid before the starting of trip.</li>
                <li>If Trip is Cancelled before 30 days of Starting Full refund</li>
                <li>If Trip is Cancelled before 15 days of Starting 50% refund</li>
                <li>No refund otherwise</li>
            </ul>
        </div>
    )
}
