import React from 'react'
import './profileedit.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export default function EditContactEmail(props) {

    const setAuthStatus = props.setAuthStatus;
    const cookies = new Cookies();
    const access = cookies.get('access');
    const refresh = cookies.get('refresh');
    const navigate = useNavigate();

    function updateEmail() {
        const newEmail = document.getElementById('edit-email').value;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const btn = document.getElementById('edit-email-btn');
        const loadingBtn = document.getElementById('edit-email-loading-btn');
        btn.style.display = 'none';
        loadingBtn.style.display = 'block';
        document.querySelector('.error-text').innerHTML = '';
        if (!newEmail) {
            document.querySelector('.error-text').innerHTML = 'Please enter email address';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        } else if (!newEmail.match(emailRegex)) {
            document.querySelector('.error-text').innerHTML = 'Please enter a valid email address';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        }
        axios.put(`https://romeo.travezco.com/users/update/email/`, {
            emailAddress: newEmail
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    document.getElementById("edit-email-heading").innerHTML = 'Verify OTP';
                    var inputField = document.getElementById('edit-email');
                    inputField.value = '';
                    inputField.name = 'edit-email-otp';
                    inputField.id = 'edit-email-otp';
                    inputField.maxLength = 5;
                    var btnNew = document.getElementById('edit-email-otp-btn');
                    btn.style.display = 'none';
                    loadingBtn.style.display = 'none';
                    btnNew.style.display = 'block';
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.put(`https://romeo.travezco.com/users/update/email/`, {
                                    emailAddress: newEmail
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            document.getElementById("edit-email-heading").innerHTML = 'Verify OTP';
                                            var inputField = document.getElementById('edit-email');
                                            inputField.value = '';
                                            inputField.name = 'edit-email-otp';
                                            inputField.id = 'edit-email-otp';
                                            inputField.maxLength = 5;
                                            var btnNew = document.getElementById('edit-email-otp-btn');
                                            btn.style.display = 'none';
                                            loadingBtn.style.display = 'none';
                                            btnNew.style.display = 'block';
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                } else {
                    document.querySelector('.error-text').innerHTML = err.response.data.error;
                    btn.style.display = 'block';
                    loadingBtn.style.display = 'none';
                }
            })
    }

    function updateEmailVerify() {
        const otp = document.getElementById('edit-email-otp').value;
        const btn = document.getElementById('edit-email-otp-btn');
        const loadingBtn = document.getElementById('edit-email-loading-btn');
        btn.style.display = 'none';
        loadingBtn.style.display = 'block';
        document.querySelector('.error-text').innerHTML = '';
        if (!otp) {
            document.querySelector('.error-text').innerHTML = 'Please enter the OTP';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        } else if (otp.length !== 5) {
            document.querySelector('.error-text').innerHTML = 'Please enter a valid OTP';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        }
        axios.post(`https://romeo.travezco.com/users/update/email/`, {
            otp: otp
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    props.setEdit(0);
                    props.profile.emailAddress = res.data.emailAddress;
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.post(`https://romeo.travezco.com/users/update/email/`, {
                                    otp: otp
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            props.setEdit(0);
                                            props.profile.emailAddress = res.data.emailAddress;
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                } else {
                    document.querySelector('.error-text').innerHTML = err.response.data.error;
                    btn.style.display = 'block';
                    loadingBtn.style.display = 'none';
                }
            })
    }

    return (
        <>
            <p id="edit-email-heading">Email Address</p>
            <input type="text" name="edit-email" id="edit-email" defaultValue={props.profile.emailAddress} maxLength={256} />
            <p className="error-text"></p>
            <button id="edit-email-btn" onClick={updateEmail}>Send OTP</button>
            <button id="edit-email-otp-btn" onClick={updateEmailVerify}>Verify OTP</button>
            <button id="edit-email-loading-btn" className="edit-contact-loading-btn" disabled>
                <i className="fa-solid fa-circle"></i>
                <i className="fa-solid fa-circle"></i>
                <i className="fa-solid fa-circle"></i>
            </button>
        </>
    )
}
