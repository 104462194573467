import React from 'react'
import to from '../../assets/icons/to.svg'
import './bookingDetails.css'
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

export default function Booking(props) {

    const { bookingId } = useParams();
    const setProgress = props.setProgress;
    const setTitle = props.setTitle;
    const [booking, setBooking] = React.useState({travellerDetails: {travellerDetails: []}, paymentDetails: {}});
    const [paymentModal, setPaymentModal] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        setProgress(20);
        setTitle(`Booking #${bookingId} - Travezco`);

        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');

        if (access && refresh) {
            var headers = { Authorization: `Bearer ${access}` }
            axios.get(`https://romeo.travezco.com/bookings/?bookingId=${bookingId}`, { headers: headers })
                .then(res => {
                    if (res.status === 200) {
                        setBooking(res.data);
                        setProgress(100);
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
                            .then(res => {
                                cookies.set('access', res.data.access, { path: '/' });
                                headers = { Authorization: `Bearer ${res.data.access}` }
                                axios.get(`https://romeo.travezco.com/bookings/?bookingId=${bookingId}`, { headers: headers })
                                    .then(res => {
                                        if (res.status === 200) {
                                            setBooking(res.data);
                                            setProgress(100);
                                        }
                                    }
                                    )
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                }
                                navigate('/');
                            })
                    } else if (err.response.status === 400) {
                        navigate('/');
                    }
                })
        } else {
            navigate('/');
        }
    }, [setProgress, setTitle, navigate, bookingId])

    return (
        <div className="profile-booking">
            <div className="profile-booking-head">
                <div className="profile-booking-location">
                    <p>{booking.origin}</p>
                    <img src={to} alt="Arrow" />
                    <p>{booking.destination}</p>
                </div>
                <div className="profile-booking-details">
                    <div>
                        <p>Booking ID: #{booking.bookingId}</p>
                        <p>Travel Agent: {booking.serviceProvider}</p>
                        <p>Date of Booking: {booking.dateOfBooking}</p>
                        <p>Booking Status: {booking.status}</p>
                    </div>
                    <div>
                        <p>Package ID: <Link to={`/package/${booking.origin}-to-${booking.destination}-${parseInt(booking.tripId)}`}>{booking.tripId} <i className="fa-solid fa-arrow-up-right-from-square"></i></Link></p>
                        <p>Number of Travellers: {booking.travellers}</p>
                        <p>Date of Journey: {booking.dateOfJourney}</p>
                        <p>Amount: ₹{booking.amount}</p>
                    </div>
                </div>
            </div>
            <div className="profile-booking-box">
                <h3>Traveller Details</h3>
                <table>
                    <thead>
                        <tr>
                            <td><b>Name</b></td>
                            <td><b>Gender</b></td>
                            <td><b>Age</b></td>
                            <td><b>ID Proof</b></td>
                            <td><b>ID Number</b></td>
                            <td><b>Sharing</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {booking.travellerDetails.travellerDetails.map((traveller, key) => {
                            return (
                                <tr key={key}>
                                    <td>{traveller.name}</td>
                                    <td>{traveller.gender}</td>
                                    <td>{traveller.age}</td>
                                    <td>{traveller.idType}</td>
                                    <td>{traveller.idNumber}</td>
                                    <td>{traveller.sharing}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="profile-booking-box">
                <h3>Payment Details</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>Base Price</td>
                            <td>₹{booking.paymentDetails.subtotal}</td>
                        </tr>
                        <tr>
                            <td>Sharing Charges</td>
                            <td>₹{booking.paymentDetails.sharingCharges}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>₹{parseFloat(booking.paymentDetails.subtotal) + parseFloat(booking.paymentDetails.sharingCharges)}</td>
                        </tr>
                        <tr>
                            <td>Discount</td>
                            <td>- ₹{booking.paymentDetails.discount}</td>
                        </tr>
                        <tr>
                            <td><b>Net Total</b></td>
                            <td><b>₹{booking.paymentDetails.total}</b></td>
                        </tr>
                        {booking.status !== "Cancelled" ? <>
                            <tr>
                                <td className="green">Amount Paid</td>
                                <td className="green">₹{booking.amountPaid}</td>
                            </tr>
                            {booking.amountDue > 0 ? <tr>
                                <td className="red">Amount Due</td>
                                <td className="red">₹{booking.amountDue}</td>
                            </tr> : null}
                        </> : null}
                    </tbody>
                </table>
                {booking.amountDue && booking.status !== "Cancelled" && booking.status !== "Payment Awaited" ? <><button className="profile-payment-button" onClick={() => setPaymentModal(true)}>Pay ₹{booking.amountDue}</button>
                <p className="profile-payment-terms">
                    <i>* If the above payment is not made until 72 hours before journey date, the booking will be auto-cancelled and the paid amount will NOT be refunded.</i>
                </p></> : null}
            </div>
            <div className="profile-booking-box">
                <h3>Cancellation Policy</h3>
                <p className="profile-booking-text">Travezco values the importance of timely communication of any trip cancellations. In the event that the traveller decides to cancel the trip more than 30 days in advance of the date of the journey, the full amount paid for the booking will be refunded to the traveller. This is in accordance with the company's cancellation policy which provides a reasonable time frame for both parties to adjust their schedules and make alternate arrangements.</p>
                <p className="profile-booking-text">For cancellations made between 30-15 days before the date of the journey, a partial refund of 50% of the booking amount will be processed. This is because a significant portion of the planning and coordination has already taken place by this point, and the service provider may have incurred expenses that cannot be recovered. Travezco acknowledges this and aims to strike a balance between providing flexibility for travellers and fair compensation for service providers.</p>
                <p className="profile-booking-text">However, for cancellations made within 15 days of the journey date or in case of no-show, Travezco will not be able to provide any refund to the traveller. This is because the service provider may have reserved specific resources or blocked certain dates exclusively for the traveller, which cannot be used for other bookings at such short notice. The cancellation policy exists to protect the interests of all parties involved, including the traveller, service provider, and Travezco.</p>
                <Link to={`https://wa.me/918396957516?text=I%20will%20have%20to%20cancel%20my%20booking%20%23${booking.bookingId}`} target='_blank'><button className="profile-cancellation-button">Proceed with Cancellation</button></Link>
            </div>
            {booking.amountDue > 0 && paymentModal ? <div className="payment-modal">
                <div className="payment-modal-content">
                    <iframe title='Payment Page - Travezco | Powered by CCAvenue' frameborder="0" id="paymentFrame" src={booking.paymentUrl}></iframe>
                </div>
            </div> : null}
        </div>
    )
}
