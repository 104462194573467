import React from 'react'
import { Link } from 'react-router-dom';
import sendBlack from '../../assets/icons/to-black.svg';
import './bookings.css';

export default function BookingCard(props) {
    return (
        <div className="booking-card">
            <div className="booking-details">
                <div className="booking-location">
                    <p>{props.origin}</p>
                    <img src={sendBlack} alt="Arrow" />
                    <p>{props.destination}</p>
                </div>
                <p className="booking-text">Booking ID: #{props.bookingId}</p>
                <p className="booking-text">Travel Agent: {props.travelAgent}</p>
                <p className="booking-text">Date of Journey: {props.dateOfJourney}</p>
            </div>
            {props.bookingStatus === "Payment Awaited" ?
                <div className="bookings-status orange">
                    <p>{props.bookingStatus}</p>
            </div> : null}
            {props.bookingStatus === "Payment Failed" || props.bookingStatus === "Cancelled" ?
                <div className="bookings-status red">
                    <p>{props.bookingStatus}</p>
            </div> : null}
            {props.bookingStatus !== "Payment Failed" && props.bookingStatus !== "Cancelled" && props.bookingStatus !== "Payment Awaited" ?
                <div className="bookings-status green">
                    <p>{props.bookingStatus}</p>
            </div> : null}
            <Link to={props.bookingId}><button>View Details</button></Link>
        </div>
    )
}
