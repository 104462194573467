import React from 'react'
import sellerHead from '../../assets/images/seller-head.svg'
import './seller.css'

export default function SellerHeader(props) {
    return (
        <div className="seller-head">
            <div className="seller-head-image">
                <img src={sellerHead} alt="seller-head" />
            </div>
            <div className="seller-head-info">
                <h1>Elevate Your Travel Agency's business with <span>Travezco</span></h1>
                <h3>Unlock your agency's potential, <span>expand your customer base</span>, and <span>achieve remarkable growth</span> with our dedicated platform.</h3>
                <button onClick={() => props.setSellerPopup(1)}>Get Started &rarr;</button>
            </div>
        </div>
    )
}
