import React from 'react'
import './profileedit.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export default function EditContactMobile(props) {

    const setAuthStatus = props.setAuthStatus;
    const cookies = new Cookies();
    const access = cookies.get('access');
    const refresh = cookies.get('refresh');
    const navigate = useNavigate();

    function updateMobile() {
        const newMobile = document.getElementById('edit-mobile').value;
        const mobileRegex = /^[0-9]{10}$/;
        const btn = document.getElementById('edit-mobile-btn');
        const loadingBtn = document.getElementById('edit-mobile-loading-btn');
        btn.style.display = 'none';
        loadingBtn.style.display = 'block';
        document.querySelector('.error-text').innerHTML = '';
        if (!newMobile) {
            document.querySelector('.error-text').innerHTML = 'Please enter mobile number';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        } else if (!newMobile.match(mobileRegex)) {
            document.querySelector('.error-text').innerHTML = 'Please enter a valid mobile number';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        }
        axios.put(`https://romeo.travezco.com/users/update/mobile/`, {
            mobileNumber: newMobile
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    document.getElementById("edit-mobile-heading").innerHTML = 'Verify OTP';
                    var inputField = document.getElementById('edit-mobile');
                    inputField.value = '';
                    inputField.name = 'edit-mobile-otp';
                    inputField.id = 'edit-mobile-otp';
                    inputField.maxLength = 5;
                    var btnNew = document.getElementById('edit-mobile-otp-btn');
                    btn.style.display = 'none';
                    loadingBtn.style.display = 'none';
                    btnNew.style.display = 'block';
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.put(`https://romeo.travezco.com/users/update/mobile/`, {
                                    mobileNumber: newMobile
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            document.getElementById("edit-mobile-heading").innerHTML = 'Verify OTP';
                                            var inputField = document.getElementById('edit-mobile');
                                            inputField.value = '';
                                            inputField.name = 'edit-mobile-otp';
                                            inputField.id = 'edit-mobile-otp';
                                            inputField.maxLength = 5;
                                            var btnNew = document.getElementById('edit-mobile-otp-btn');
                                            btn.style.display = 'none';
                                            loadingBtn.style.display = 'none';
                                            btnNew.style.display = 'block';
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                } else {
                    document.querySelector('.error-text').innerHTML = err.response.data.error;
                    btn.style.display = 'block';
                    loadingBtn.style.display = 'none';
                }
            })
    }

    function updateMobileVerify() {
        const otp = document.getElementById('edit-mobile-otp').value;
        const btn = document.getElementById('edit-mobile-otp-btn');
        const loadingBtn = document.getElementById('edit-mobile-loading-btn');
        btn.style.display = 'none';
        loadingBtn.style.display = 'block';
        document.querySelector('.error-text').innerHTML = '';
        if (!otp) {
            document.querySelector('.error-text').innerHTML = 'Please enter the OTP';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        } else if (otp.length !== 5) {
            document.querySelector('.error-text').innerHTML = 'Please enter a valid OTP';
            btn.style.display = 'block';
            loadingBtn.style.display = 'none';
            return;
        }
        axios.post(`https://romeo.travezco.com/users/update/mobile/`, {
            otp: otp
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    props.setEdit(0);
                    props.profile.mobileNumber = res.data.mobileNumber;
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.post(`https://romeo.travezco.com/users/update/mobile/`, {
                                    otp: otp
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            props.setEdit(0);
                                            props.profile.mobileNumber = res.data.mobileNumber;
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                } else {
                    document.querySelector('.error-text').innerHTML = err.response.data.error;
                    btn.style.display = 'block';
                    loadingBtn.style.display = 'none';
                }
            })
    }

    return (
        <>
            <p id="edit-mobile-heading">Mobile Number</p>
            <input type="text" name="edit-mobile" id="edit-mobile" defaultValue={props.profile.mobileNumber} maxLength={256} />
            <p className="error-text"></p>
            <button id="edit-mobile-btn" onClick={updateMobile}>Send OTP</button>
            <button id="edit-mobile-otp-btn" onClick={updateMobileVerify}>Verify OTP</button>
            <button id="edit-mobile-loading-btn" className="edit-contact-loading-btn" disabled>
                <i className="fa-solid fa-circle"></i>
                <i className="fa-solid fa-circle"></i>
                <i className="fa-solid fa-circle"></i>
            </button>
        </>
    )
}
