import React from 'react'
import notFound from '../../assets/images/not-found.svg'
import './notfound.css'
import { Link } from 'react-router-dom'

export default function NotFound(props) {

    const setTitle = props.setTitle
    const setProgress = props.setProgress

    React.useEffect(() => {
        setTitle('Page Not Found - Travezco')
        setProgress(100)
    }, [setTitle, setProgress])

    return (
        <div className="not-found">
            <div className="not-found-image">
                <img src={notFound} alt="not-found" />
            </div>
            <div className="not-found-info">
                <h3>We're sorry, but it seems like the page you're looking for has taken a vacation of its own!</h3>
                <p>Why not take a moment to explore some of our popular travel destinations? <br /> You might just discover your next dream getaway!</p>
                <Link to="/">Back to Home</Link>
            </div>
        </div>
    )
}
