import React from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie'

export default function Logout(props) {

    const cookies = new Cookies();
    const navigate = useNavigate();
    cookies.remove('access', { path: '/' });
    cookies.remove('refresh', { path: '/' });
    props.setAuthStatus(false);
    navigate('/');

    return (
        <div>Redirecting...</div>
    )
}
