import React from 'react'
import ProfileNavigator from '../components/ProfileNavigator/ProfileNavigator'
import WishlistComponenet from '../components/Wishlist/Wishlist';

export default function Wishlist(props) {

    return (
        <div className="profile" style={profileStyle}>
            <ProfileNavigator selected={2} />
            <WishlistComponenet setTitle={props.setTitle} setProgress={props.setProgress} setAuthStatus={props.setAuthStatus} setAuth={props.setAuth} authStatus={props.authStatus} />
        </div>
    )
}

const profileStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
}