import React from 'react'
import moment from 'moment'
import './checkout.css'

export default function DateSelector(props) {

    function setDate() {
        var date = document.getElementById("date").value;
        var today = moment().format("YYYY-MM-DD");
        if (moment(date).diff(today, 'days') >= 2) {
            props.setDate(document.getElementById("date").value);
        } else {
            return;
        }
    }

    function setTCDate() {
        var date = document.getElementById("date-tc").value;
        var seatAvail = document.getElementsByClassName("seat-availability")[0];
        if (date === "0") {
            props.setDate("");
            seatAvail.innerHTML = "";
            props.setMaxPassengers(99);
            return;
        }
        var vac = props.checkoutData.dates.filter((dateObj) => {
            return dateObj.date === date;
        });
        if (vac[0].vacancy > 5) {
            seatAvail.innerHTML = `${vac[0].vacancy} Seats Available`;
            seatAvail.classList.remove("red");
            props.setMaxPassengers(vac[0].vacancy)
            props.setDate(date);
        }
        else if (vac[0].vacancy > 0) {
            seatAvail.innerHTML = `${vac[0].vacancy} Seats Available`;
            seatAvail.classList.add("red");
            props.setMaxPassengers(vac[0].vacancy)
            props.setDate(date);
        } else {
            seatAvail.innerHTML = `No Seats Available`;
            seatAvail.classList.add("red");
            props.setMaxPassengers(99);
            return;
        }
    }

    return (
        <>
            <div className="date-selector">
                {props.checkoutData.travelBuddy ? <select name="date" id="date-tc" onChange={setTCDate} defaultValue={'0'}>
                    <option value="0" disabled>Select Date</option>
                    {props.checkoutData.dates.map((date, key) => {
                        return (
                            <option key={key} value={date.date}>{date.date}</option>
                        )
                    })}
                </select>
                : <input type="date" name="date" id="date" min={moment().add(2, 'days').format("YYYY-MM-DD")} onChange={setDate} />}
            </div>
            <div className="seat-availability"></div>
        </>
    )
}
