import React from 'react'
import SellerOnboardingStep from './SellerOnboardingStep'
import sellerMeet from '../../assets/icons/seller-meet.svg'
import sellerDocuments from '../../assets/icons/seller-documents.svg'
import sellerSell from '../../assets/icons/seller-sell.svg'
import './seller.css'

export default function SellerOnboarding() {
    return (
        <div className="seller-onboarding">
            <h2>Onboard with us in <span>3 Steps!</span></h2>
            <div className="seller-onboarding-steps">
                <SellerOnboardingStep image={sellerMeet} title="Meet with our team." desc="Know what it takes to work with us." />
                <SellerOnboardingStep image={sellerDocuments} title="Submit your documents." desc="Submit your documents for verification." />
                <SellerOnboardingStep image={sellerSell} title="Start selling on Travezco." desc="After successful KYC, your packages will be live." />
            </div>
        </div>
    )
}
