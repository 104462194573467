import React from 'react'
import './itineraryimages.css'
import to from '../../assets/icons/to.svg'
import star from '../../assets/icons/star-white.svg'

export default function ItineraryImages(props) {

    const trip = props.trip;
    const images = props.tripImage;

    function prevImage() {
        let selectedImage = document.getElementsByClassName("selected-image")[0];
        let newSelection = selectedImage.previousElementSibling;
        if (newSelection) {
            selectedImage.classList.remove("selected-image");
            newSelection.classList.add("selected-image");
        } else {
            selectedImage.classList.remove("selected-image");
            let images = document.getElementsByClassName("overlay-image");
            images[images.length - 1].classList.add("selected-image");
        }
    }

    function nextImage() {
        let selectedImage = document.getElementsByClassName("selected-image")[0];
        let newSelection = selectedImage.nextElementSibling;
        if (newSelection) {
            selectedImage.classList.remove("selected-image");
            newSelection.classList.add("selected-image");
        } else {
            selectedImage.classList.remove("selected-image");
            document.getElementsByClassName("overlay-image")[0].classList.add("selected-image");
        }
    }

    function checkClick(event) {
        var arrows = document.getElementsByClassName("images-overlay-arrow");
        var images = document.getElementsByClassName("images-overlay-image");
        var overlay = document.getElementById("images-overlay");
        if (arrows[0].contains(event.target) || arrows[1].contains(event.target) || images[0].contains(event.target)) {
            return;
        }
        overlay.style.display = "none";
    }

    return (
        <>
            <div className="itinerary-images">
                <div className="itinerary-rating">
                    <img src={star} alt="Rating" />
                    <p>{trip.rating}</p>
                </div>
                <div className="left-image">
                    <img src={images[0]} alt="itinerary" />
                    <div className="itinerary-details">
                        <div className="itinerary-from-to">
                            <p>{trip.origin}</p>
                            <img src={to} alt="To" />
                            <p>{trip.destination}</p>
                        </div>
                    </div>
                </div>
                <div className="right-images">
                    <div className="right-image">
                        <img src={images[1]} alt="itinerary" />
                    </div>
                    <div className="right-image">
                        <img src={images[2]} alt="itinerary" />
                    </div>
                    <div className="right-image">
                        <img src={images[3]} alt="itinerary" />
                    </div>
                    <div className="right-image">
                        <img src={images[4]} alt="itinerary" />
                        <div className="all-photos">
                            <p onClick={() => document.getElementById('images-overlay').style.display = 'flex'}>View More &rarr;</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="images-overlay" id="images-overlay" onClick={checkClick}>
                <div className="close-image-overlay" onClick={() => document.getElementById('images-overlay').style.display = 'none'}>
                    <i className="fa-solid fa-x"></i>
                </div>
                <div className="images-overlay-arrow" onClick={prevImage}>
                    &larr;
                </div>
                <div className="images-overlay-image">
                    {images.map((image, key) => {
                        if (!image) {return null}
                        return (
                            key === 4 ? <img key={key} src={image} alt="itinerary" className="overlay-image selected-image" /> : <img key={key} src={image} alt="itinerary" className="overlay-image" />
                        )
                    })}
                </div>
                <div className="images-overlay-arrow" onClick={nextImage}>
                    &rarr;
                </div>
            </div>
        </>
    )
}
