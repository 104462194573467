import React from 'react'
import './tripcards.css'
import TripCard from './TripCard'
import axios from 'axios'
import LoadingTripCard from './LoadingTripCard'
import Cookies from 'universal-cookie';

export default function TripCards(props) {

    const setAuth = props.setAuth;
    const setAuthStatus = props.setAuthStatus;
    const destination = props.destination || 'all'
    const setProgress = props.setProgress
    const trips = props.trips
    const setTrips = props.setTrips

    React.useEffect(() => {
        setProgress(50)
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        var header = {}

        if (access && refresh) {
            axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                .then(res => {
                    cookies.set('access', res.data.access, { path: '/' });
                    header = {"Authorization": `Bearer ${res.data.access}`};
                    axios.get(`https://romeo.travezco.com/trips/?destination=${destination}`, {headers: header})
                        .then(res => {
                            setProgress(80)
                            setTrips(res.data)
                            const loadingCards = document.querySelectorAll('.loading-trip-card')
                            loadingCards.forEach(card => {
                                card.style.display = 'none'
                            })
                            setProgress(100)
                        })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        cookies.remove('access', { path: '/' });
                        cookies.remove('refresh', { path: '/' });
                        setAuth(false);
                        setAuthStatus(false);
                    }
                })
        } else {
            axios.get(`https://romeo.travezco.com/trips/?destination=${destination}`)
            .then(res => {
                setProgress(80)
                setTrips(res.data)
                const loadingCards = document.querySelectorAll('.loading-trip-card')
                loadingCards.forEach(card => {
                    card.style.display = 'none'
                })
                setProgress(100)
            })
        }

    }, [destination, setProgress, setAuth, setAuthStatus, setTrips])

    return (
        <div className="trip-cards">
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            <LoadingTripCard />
            {trips.map(trip => {
                return (
                    <TripCard
                        key={trip.id}
                        id={trip.id}
                        origin={trip.origin}
                        destination={trip.destination}
                        duration={trip.duration}
                        discount={trip.discount}
                        costPrice={trip.costPrice}
                        sellingPrice={trip.sellingPrice}
                        image={trip.primaryImage}
                        highlights={trip.highlights}
                        rating={trip.rating}
                        flight={trip.flight}
                        meal={trip.meal}
                        stay={trip.stay}
                        transfer={trip.transfer}
                        wishlist={trip.wishlist}
                        specialTag={trip.specialTag}
                        date={trip.date}
                        authStatus={props.authStatus}
                        setAuth={props.setAuth}
                        setAuthStatus={props.setAuthStatus}
                    />
                        )
                    })}
        </div>
    )
}
