import React from 'react'
import './profileedit.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export default function EditAddress(props) {

    const setAuthStatus = props.setAuthStatus;
    const cookies = new Cookies();
    const access = cookies.get('access');
    const refresh = cookies.get('refresh');
    const navigate = useNavigate();

    function updateAddress() {
        const addressLine1 = document.getElementById('edit-address-line-1').value;
        const addressLine2 = document.getElementById('edit-address-line-2').value;
        const city = document.getElementById('edit-address-city').value;
        const state = document.getElementById('edit-address-state').value;
        const country = document.getElementById('edit-address-country').value;
        const pincode = document.getElementById('edit-address-pincode').value;
        document.querySelector('.error-text').innerHTML = '';
        if (!addressLine1 || !addressLine2 || !city || !state || !country || !pincode) {
            document.querySelector('.error-text').innerHTML = 'Please fill all the fields';
            return;
        }
        if (pincode.length !== 6) {
            document.querySelector('.error-text').innerHTML = 'Please enter a valid PIN Code';
            return;
        }
        axios.put(`https://romeo.travezco.com/users/update/address/`, {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: state,
            country: country,
            pincode: pincode
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    props.setEdit(0);
                    props.profile.addressLine1 = res.data.addressLine1;
                    props.profile.addressLine2 = res.data.addressLine2;
                    props.profile.city = res.data.city;
                    props.profile.state = res.data.state;
                    props.profile.country = res.data.country;
                    props.profile.pincode = res.data.pincode;
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.put(`https://romeo.travezco.com/users/update/address/`, {
                                    addressLine1: addressLine1,
                                    addressLine2: addressLine2,
                                    city: city,
                                    state: state,
                                    country: country,
                                    pincode: pincode
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            props.setEdit(0);
                                            props.profile.addressLine1 = res.data.addressLine1;
                                            props.profile.addressLine2 = res.data.addressLine2;
                                            props.profile.city = res.data.city;
                                            props.profile.state = res.data.state;
                                            props.profile.country = res.data.country;
                                            props.profile.pincode = res.data.pincode;
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                }
            })
    }

    return (
        <>
            <h3>Edit Billing Address</h3>
            <p>Address Line 1</p>
            <input type="text" name="edit-address-line-1" id="edit-address-line-1" defaultValue={props.profile.addressLine1} maxLength={256} />
            <p>Address Line 2</p>
            <input type="text" name="edit-address-line-2" id="edit-address-line-2" defaultValue={props.profile.addressLine2} maxLength={256} />
            <p>Town/City</p>
            <input type="text" name="edit-address-city" id="edit-address-city" defaultValue={props.profile.city} maxLength={256} />
            <p>State</p>
            <select name="edit-address-city" id="edit-address-state" defaultValue={props.profile.state}>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Delhi/U.T./Other">Delhi/U.T./Other</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Orissa">Orissa</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
            </select>
            <p>Country</p>
            <select name="edit-address-country" id="edit-address-country" defaultValue={props.profile.country}>
                <option value="India">India</option>
            </select>
            <p>PIN Code</p>
            <input type="text" name="edit-address-pincode" id="edit-address-pincode" defaultValue={props.profile.pincode} maxLength={6} />
            <p className="error-text"></p>
            <button onClick={updateAddress}>Save</button>
        </>
    )
}
