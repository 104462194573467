import React from 'react'
import './seller.css'

export default function SellerOnboardingStep(props) {
    return (
        <div className="seller-onboarding-step">
            <div className="seller-onboarding-step-image">
                <img src={props.image} alt={props.title} />
            </div>
            <div className="seller-onboarding-step-content">
                <h3>{props.title}</h3>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}
