import React from 'react'
import './seller.css'

export default function SellerWhyCard(props) {
    return (
        <div className="seller-why-card">
            <div className="seller-why-card-image">
                <img src={props.image} alt={props.title} />
            </div>
            <div className="seller-why-card-info">
                <h3>{props.title}</h3>
                <p>{props.desc}</p>
            </div>
        </div>
    )
}
