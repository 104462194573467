import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'
import mainLogo from '../../assets/images/full_logo.svg'
import facebook from '../../assets/icons/facebook.svg'
import instagram from '../../assets/icons/instagram.svg'
import linkedin from '../../assets/icons/linkedin.svg'
import mail from '../../assets/icons/mail.svg'
import call from '../../assets/icons/call.svg'
import location from '../../assets/icons/location.svg'
import axios from 'axios'

export default function Footer() {

    var year = new Date().getFullYear();
    
    const [popularPlaces, setPopularPlaces] = React.useState([])
    React.useEffect(() => {
        axios.get('https://romeo.travezco.com/trips/popular/')
            .then(res => setPopularPlaces(res.data))
    }, [])

    function subscribeNewsletter() {
        const email = document.getElementById('newletter-email').value;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (email.match(emailRegex)) {
            axios.post('https://romeo.travezco.com/users/newsletter/', {
                email: email
            })
                .then(res => {
                    alert(res.data.message);
                    document.getElementById('newletter-email').value = '';
                })
                .catch(err => alert(err.response.data.error));
        } else {
            alert('Please enter a valid email');
        }
    }

    return (
        <footer>
            <div className="footer-main">
                <div className="footer-logo">
                    <img src={mainLogo} alt="logo" />
                    <div className="footer-socials">
                        <Link to="https://www.instagram.com/travezco/" target='_blank'><img src={instagram} alt="instagram" /></Link>
                        <Link to="https://www.facebook.com/Travezco/" target='_blank'><img src={facebook} alt="facebook" /></Link>
                        <Link to="https://www.linkedin.com/company/travezco/" target='_blank'><img src={linkedin} alt="linkedin" /></Link>
                    </div>
                </div>
                <div className="footer-links">
                    <div className="footer-link-container">
                        <h3>popular places</h3>
                        {popularPlaces.map((place, key) => {
                            if (key > 4) {
                                return null
                            }
                            return (
                                <Link to={`/destination/${place.name}`} key={place.name}>{place.name}</Link>
                            )
                        })}
                    </div>
                    <div className="footer-link-container">
                        <h3>about travezco</h3>
                        <Link to="/about">About Us</Link>
                        <Link to="/contact">Contact Us</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/seller">Become a seller</Link>
                    </div>
                </div>
                <div className="footer-newsletter">
                    <h3>
                        <img src={mail} alt="facebook" />
                        subscribe to newsletter
                    </h3>
                    <div className="footer-newsletter-input">
                        <input type="email" name="newsletter-email" placeholder="Email Address" id="newletter-email" />
                        <button onClick={subscribeNewsletter}>Subscribe</button>
                    </div>
                </div>
            </div>
            <div className="footer-contact">
                <div className="footer-contact-detail">
                    <img src={location} alt="location" />
                    <Link to="https://goo.gl/maps/Qf2ZaKxZ6Hrcq7Ed8" target="_blank">IIT Delhi, Hauz Khas</Link>
                </div>
                <div className="footer-contact-detail">
                    <img src={call} alt="call" />
                    <Link to="tel:918396957516">+91 83969 57516</Link>
                </div>
                <div className="footer-contact-detail">
                    <img src={mail} alt="mail" />
                    <Link to="mailto:support@travezco.com">support@travezco.com</Link>
                </div>
            </div>
            <div className="footer-copyright">
                &copy; {year} Copyright: Travezco Private Limited
            </div>
        </footer>
    )
}
