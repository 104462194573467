import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import '../auth.css'

export default function SignupScreen1(props) {

    function checkInput() {
        const mobileRegex = /^[0-9]{10}$/;
        const signupParam = document.getElementById('signup-param');
        const authError = document.getElementById('auth-error');
        if (signupParam.value === '') {
            signupParam.classList.add('error-input');
            authError.innerHTML = 'Please enter mobile number';
            return false;
        }
        else if (mobileRegex.test(signupParam.value) === false) {
            signupParam.classList.add('error-input');
            authError.innerHTML = 'Please enter a valid mobile number';
            return false;
        }
        else {
            signupParam.classList.remove('error-input');
            authError.innerHTML = '';
            return true;
        }
    }

    function sendOtp() {
        if (checkInput()) {
            const signupParam = document.getElementById('signup-param');
            const authError = document.getElementById('auth-error');
            signupParam.classList.remove('error-input');
            authError.innerHTML = '';
            document.getElementById('submitButton').style.display = 'none';
            document.getElementById('loadingButton').style.display = 'block';
            const url = 'https://romeo.travezco.com/users/signup/';
            const data = {
                mobileNumber: signupParam.value
            }
            axios.post(url, data)
                .then(res => {
                    if (res.status === 200) {
                        props.userId(res.data.userId);
                        props.userParam(signupParam.value);
                        props.authState(3);
                    }})
                .catch(err => {
                    signupParam.classList.add('error-input');
                    authError.innerHTML = err.response.data.error;
                    document.getElementById('submitButton').style.display = 'block';
                    document.getElementById('loadingButton').style.display = 'none';
                })
        }
    }

    return (
        <div className="auth-right">
            <h3>Signup</h3>
            <div className="auth-input">
                <p>Mobile Number</p>
                <input type="text" name="signup-param" id="signup-param" onKeyUp={checkInput} />
                <p className="auth-error" id="auth-error"></p>
                <span>By continuing, you agree to Travezco's <Link to="/terms" onClick={() => props.viewAuth(false)}>Terms of Use</Link> and <Link to="/privacy" onClick={() => props.viewAuth(false)}>Privacy Policy</Link></span>
                <button onClick={sendOtp} id="submitButton">Send OTP</button>
                <button className="loadingButton" id="loadingButton" disabled>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                </button>
            </div>
            <div className="auth-footer">
                <p>Already Registered? <Link to="#" onClick={() => props.authState(0)}>Login</Link></p>
            </div>
        </div>
    )
}
