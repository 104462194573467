import React from 'react'
import sellerSuccess from '../../assets/images/seller-success.svg'
import './seller.css'

export default function SellerFormSuccess(props) {

    window.onclick = function (event) {
        if (event.target.className === 'seller-form-modal') {
            props.setSellerPopup(0);
        }
    }

    function closeFormSuccess() {
        props.setSellerPopup(0);
    }

    return (
        <div className="seller-form-modal">
            <div className="seller-form">
                <div className="seller-form-close" onClick={closeFormSuccess}>
                    <i className="fa-solid fa-x"></i>
                </div>
                <h3>We will reach out to you soon!</h3>
                <div className="seller-form-success-image">
                    <img src={sellerSuccess} alt="Seller Form Success" />
                </div>
                <p className="seller-success-msg">Thank you for considering partnering with us. We are excited about the prospect of a fruitful partnership and look forward to working together.</p>
            </div>
        </div>
    )
}
