import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import success from '../../assets/images/success.gif'
import './booking.css'

export default function BookingSuccess(props) {

    const bookingId = props.bookingId;
    const navigate = useNavigate();
    const [bookingData, setBookingData] = React.useState({});

    React.useEffect(() => {
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        
        props.setTitle('Booking Successful - Travezco');

        if (access && refresh) {
            axios.get(`https://romeo.travezco.com/bookings/?bookingId=${bookingId}`, { headers: { Authorization: `Bearer ${access}` } })
                .then(res => {
                    setBookingData(res.data);
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        axios.post('https://romeo.travezco.com/users/token/refresh/', { refresh: refresh })
                            .then(res => {
                                if (res.status === 200) {
                                    cookies.set('access', res.data.access, { path: '/' });
                                    axios.get(`https://romeo.travezco.com/bookings/?bookingId=${bookingId}`, { headers: { Authorization: `Bearer ${res.data.access}` } })
                                        .then(res => {
                                            setBookingData(res.data);
                                        })
                                        .catch(err => {
                                            alert(err.response.data.error);
                                            navigate('/');
                                        })
                                }
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                }
                                navigate('/');
                            })
                    } else {
                        alert(err.response.data.error);
                        navigate('/');
                    }
                })
        } else {
            navigate('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId])

    return (
        <div className="booking">
            <div className="booking-animation">
                <img src={success} alt="Success" />
            </div>
            <h2>Booking Successful</h2>
            <h4>Let the trip begin!</h4>
            <table>
                <tr>
                    <td>Booking ID</td>
                    <td>#{ bookingData.bookingId }</td>
                </tr>
                <tr>
                    <td>Booking Date</td>
                    <td>{ bookingData.dateOfBooking }</td>
                </tr>
                <tr>
                    <td>Trip ID</td>
                    <td>{ bookingData.tripId }</td>
                </tr>
                <tr>
                    <td>Payment ID</td>
                    <td>#{ bookingData.paymentId }</td>
                </tr>
                <tr>
                    <td>Amount Paid</td>
                    <td>₹ { bookingData.amountPaid }</td>
                </tr>
                <tr>
                    <td>Amount Remaining</td>
                    <td>₹ { bookingData.amountDue }</td>
                </tr>
            </table>
            <Link to="/"><button>Go Home</button></Link>
        </div>
    )
}
