import React from 'react'
import './statbar.css'

export default function StatBar() {
    return (
        <div className="stat-bar">
            <div className="stat-bar-item">
                <div className="stat-bar-item-number">
                    1000+
                </div>
                <div className="stat-bar-item-text">
                    Travellers
                </div>
            </div>
            <div className="stat-bar-separator"></div>
            <div className="stat-bar-item">
                <div className="stat-bar-item-number">
                    100+
                </div>
                <div className="stat-bar-item-text">
                    Trips
                </div>
            </div>
            <div className="stat-bar-separator"></div>
            <div className="stat-bar-item">
                <div className="stat-bar-item-number">
                    4.9
                </div>
                <div className="stat-bar-item-text">
                    Google Rating
                </div>
            </div>
        </div>
    )
}
