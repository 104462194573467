import React from 'react'
import promocode from '../../assets/icons/promocode.svg'
import './checkout.css'

export default function PromocodeCard(props) {
    return (
        <div className="promocode-card" onClick={() => props.setPromocode(props.offer.code)}>
            <div className="promocode-icon">
                <img src={promocode} alt="Icon" />
            </div>
            <div className="promocode-details">
                <p>{props.offer.description}</p>
                <span>Use Code: {props.offer.code}</span>
            </div>
        </div>
    )
}
