import React from 'react'
import contact from '../../assets/images/contact.svg'
import { Link } from 'react-router-dom'
import './contact.css'

export default function ContactArt() {
    return (
        <div className="contact-left">
            <h1>Contact Us</h1>
            <img src={contact} alt="contact" />
            <div className="contact-details">
                <Link to="tel:+918396957516"><div className="contact-detail">
                    <i className="fa-solid fa-phone"></i>
                    <p>+91 83969 57516</p>
                </div></Link>
                <Link to="mailto:support@travezco.com"><div className="contact-detail">
                    <i className="fa-solid fa-envelope"></i>
                    <p>support@travezco.com</p>
                </div></Link>
                <Link to="https://goo.gl/maps/Qf2ZaKxZ6Hrcq7Ed8"><div className="contact-detail">
                    <i className="fa-solid fa-location-dot"></i>
                    <p>IIT Delhi, Hauz Khas</p>
                </div></Link>
            </div>
            <div className="contact-socials">
                <h2>Follow Us!</h2>
                <div className="contact-social-icons">
                    <Link to="https://www.instagram.com/travezco/"><i className="fa-brands fa-instagram"></i></Link>
                    <Link to="https://www.facebook.com/Travezco/"><i className="fa-brands fa-facebook"></i></Link>
                    <Link to="https://www.linkedin.com/company/travezco/"><i className="fa-brands fa-linkedin"></i></Link>
                </div>
            </div>
        </div>
    )
}
