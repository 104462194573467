import React from 'react'
import './about.css'
import { Link } from 'react-router-dom'

export default function TeamCard(props) {
    return (
        <div className="about-team-card">
            <div className="about-team-card-image">
                <img src={props.image} alt={props.name} />
                <Link to={props.linkedin} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></Link>
            </div>
            <div className="about-team-card-details">
                <p>{props.name}</p>
                <span>{props.title}</span>
            </div>
        </div>
    )
}
