import React from 'react'
import sellerLeads from '../../assets/icons/seller-leads.svg'
import sellerManagement from '../../assets/icons/seller-management.svg'
import sellerPayment from '../../assets/icons/seller-payment.svg'
import sellerBranding from '../../assets/icons/seller-branding.svg'
import './seller.css'
import SellerWhyCard from './SellerWhyCard'

export default function SellerWhy() {
    return (
        <div className="seller-why">
            <h2>Why <span>Travezco?</span></h2>
            <div className="seller-why-cards">
                <SellerWhyCard image={sellerLeads} title="Free Leads" desc="Service providers get access to a wide audience, enabling them to sell their packages throughout the country without spending a penny on acquiring customers." />
                <SellerWhyCard image={sellerManagement} title="Easy Management" desc="Travezco provides all the details for a booking in a clear and concise manner, making it easier for service providers to manage their bookings and cater to a large number of customers." />
                <SellerWhyCard image={sellerPayment} title="Regular Payouts" desc="Travezco doesn't make Service Providers wait for 15 to 30 days for their payment to be cleared. More than 60% of the amount for a booking is dispatched even before the starting of the trip." />
                <SellerWhyCard image={sellerBranding} title="Branding" desc="All the packages sold at Travezco are sold by the name of the Service Provider. Hence, by providing quality services, Service Providers can build their own brand on the platform." />
            </div>
        </div>
    )
}
