import React from 'react'
import AboutHeader from '../components/About/AboutHeader';
import AboutDetails from '../components/About/AboutDetails';
import AboutStat from '../components/About/AboutStat';
import AboutAchievements from '../components/About/AboutAchievements';

export default function About(props) {

    const setTitle = props.setTitle;
    const setProgress = props.setProgress;

    React.useEffect(() => {
        setTitle('About Us - Travezco');
        setProgress(100);
    }, [setTitle, setProgress])

    return (
        <>
            <AboutHeader />
            <AboutDetails />
            <AboutStat />
            <AboutAchievements />
        </>
    )
}
