import React from 'react'
import './whyus.css'

export default function WhyUsCard(props) {
    return (
        <div className="why-us-card">
            <img src={ props.image } alt={ props.text } />
            <p>{ props.text }</p>
        </div>
    )
}
