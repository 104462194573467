import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import './contact.css'

export default function ContactForm() {

    const error = document.getElementById('contact-form-error')

    function validateName() {
        let name = document.getElementById('contact-name').value
        if (name === '') {
            document.getElementById('contact-name').classList.add('error-input')
            error.innerHTML = 'Please enter your name'
            return false
        } else {
            document.getElementById('contact-name').classList.remove('error-input')
            error.innerHTML = ''
            return true
        }
    }

    function validateEmail() {
        let email = document.getElementById('contact-email').value
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (email === '') {
            document.getElementById('contact-email').classList.add('error-input')
            error.innerHTML = 'Please enter your email'
            return false
        } else if (!emailRegex.test(email)) {
            document.getElementById('contact-email').classList.add('error-input')
            error.innerHTML = 'Please enter a valid email'
            return false
        } else {
            document.getElementById('contact-email').classList.remove('error-input')
            error.innerHTML = ''
            return true
        }
    }

    function validateMobile() {
        let mobile = document.getElementById('contact-mobile').value
        const mobileRegex = /^[0-9]{10}$/;
        if (mobile === '') {
            document.getElementById('contact-mobile').classList.add('error-input')
            error.innerHTML = 'Please enter your mobile number'
            return false
        } else if (!mobileRegex.test(mobile)) {
            document.getElementById('contact-mobile').classList.add('error-input')
            error.innerHTML = 'Please enter a valid mobile number'
            return false
        } else {
            document.getElementById('contact-mobile').classList.remove('error-input')
            error.innerHTML = ''
            return true
        }
    }

    function validateMessage() {
        let message = document.getElementById('contact-message').value
        if (message === '') {
            document.getElementById('contact-message').classList.add('error-input')
            error.innerHTML = 'Please enter your message'
            return false
        } else {
            document.getElementById('contact-message').classList.remove('error-input')
            error.innerHTML = ''
            return true
        }
    }

    function clearForm() {
        document.getElementById('contact-name').value = ''
        document.getElementById('contact-email').value = ''
        document.getElementById('contact-mobile').value = ''
        document.getElementById('contact-message').value = ''
        document.getElementById('contact-terms').checked = false
    }

    function submitContact() {
        let name = document.getElementById('contact-name').value
        let email = document.getElementById('contact-email').value
        let mobile = document.getElementById('contact-mobile').value
        let message = document.getElementById('contact-message').value
        let terms = document.getElementById('contact-terms').checked
        if (!(validateName() && validateEmail() && validateMobile() && validateMessage())) {
            return
        }
        if (!terms) {
            error.innerHTML = 'Please agree to our privacy policy'
            return
        }
        error.innerHTML = ''

        var body = {
            name: name,
            emailAddress: email,
            mobileNumber: mobile,
            message: message,
            privacy: terms
        }

        axios.post('https://romeo.travezco.com/users/contact/', body)
            .then(res => {
                alert(res.data.message)
                clearForm()
            })
            .catch(err => {
                alert(err.response.data.error)
            })
    }
    

    return (
        <div className="contact-right">
            <h1>Get in touch</h1>
            <h3>Our friendly team would love to hear from you!</h3>
            <div className="contact-form">
                <div className="contact-form-input">
                    <p>Name</p>
                    <input type="text" name="contact-name" id="contact-name" maxLength={100} onKeyUp={validateName} />
                </div>
                <div className="contact-form-input">
                    <p>Email</p>
                    <input type="text" name="contact-email" id="contact-email" maxLength={150} onKeyUp={validateEmail} />
                </div>
                <div className="contact-form-input">
                    <p>Mobile Number</p>
                    <input type="text" name="contact-mobile" id="contact-mobile" maxLength={10} onKeyUp={validateMobile} />
                </div>
                <div className="contact-form-input">
                    <p>Message</p>
                    <textarea name="contact-message" id="contact-message" cols="30" rows="10" maxLength={500} onKeyUp={validateMessage}></textarea>
                </div>
                <div className="contact-form-input">
                    <input type="checkbox" name="contact-terms" id="contact-terms" />
                    <p>I agree to your friendly <Link to='/privacy'>privacy policy</Link></p>
                </div>
                <p id="contact-form-error"></p>
                <button onClick={submitContact}>Send Message</button>
            </div>
        </div>
    )
}
