import React from 'react'
import axios from 'axios'
import './populardestinations.css'
import PopularDestinationCard from './PopularDestinationCard'
import LoadingDestinationCard from './LoadingDestinationCard'

export default function PopularDestinations(props) {

    const setProgress = props.setProgress;

    function scrollLeft() {
        const cards = document.querySelector('.popular-destination-cards')
        cards.scrollLeft -= 370
    }

    function scrollRight() {
        const cards = document.querySelector('.popular-destination-cards')
        cards.scrollLeft += 370
    }

    const [popularPlaces, setPopularPlaces] = React.useState([])
    React.useEffect(() => {
        setProgress(20)
        axios.get('https://romeo.travezco.com/trips/popular/')
            .then(res => {
                setProgress(60)
                setPopularPlaces(res.data)
                setProgress(80)
                const loadingCards = document.querySelectorAll('.loading-destination-card')
                loadingCards.forEach(card => {
                    card.style.display = 'none'
                })
                setProgress(100)
            })
    }, [setProgress])

    return (
        <div className="popular-destination">
            <div className="popular-destination-header">
                <h2>Popular Destinations</h2>
                <div className="popular-destination-navigator">
                    <div className="popular-destination-navigator-left" onClick={scrollLeft}>
                        <i className="fas fa-chevron-left"></i>
                    </div>
                    <div className="popular-destination-navigator-right" onClick={scrollRight}>
                        <i className="fas fa-chevron-right"></i>
                    </div>
                </div>
            </div>
            <div className="popular-destination-cards">
                <LoadingDestinationCard />
                <LoadingDestinationCard />
                <LoadingDestinationCard />
                <LoadingDestinationCard />
                <LoadingDestinationCard />
                {popularPlaces.map(place => {
                    return (
                        <PopularDestinationCard
                            key={place.id}
                            id={place.id}
                            name={place.name}
                            country={place.country}
                            image={place.photo}
                            price={place.price}
                        />
                    )
                })}
            </div>
        </div>
    )
}
