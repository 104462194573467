import React from 'react'
import ProfileComponent from '../components/Profile/Profile'
import ProfileNavigator from '../components/ProfileNavigator/ProfileNavigator'

export default function Profile(props) {

    const setTitle = props.setTitle;
    const setAuthStatus = props.setAuthStatus;

    React.useEffect(() => {
        setTitle('My Profile - Travezco');
    }, [setTitle])

    return (
        <div className="profile" style={profileStyle}>
            <ProfileNavigator selected={0} />
            <ProfileComponent setProgress={props.setProgress} setAuthStatus={setAuthStatus} />
        </div>
    )
}

const profileStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
}