import React from 'react'
import SellerHeader from '../components/Seller/SellerHeader';
import SellerWhy from '../components/Seller/SellerWhy';
import SellerOnboarding from '../components/Seller/SellerOnboarding';
import SellerJoin from '../components/Seller/SellerJoin';
import SellerFaqs from '../components/Seller/SellerFaqs';
import SellerTestimonials from '../components/Seller/SellerTestimonials';
import SellerForm from '../components/Seller/SellerForm';
import SellerFormSuccess from '../components/Seller/SellerFormSuccess';

export default function Seller(props) {

    const setTitle = props.setTitle;
    const setProgress = props.setProgress;
    const [sellerPopup, setSellerPopup] = React.useState(0);

    React.useEffect(() => {
        setTitle('Become a Seller - Travezco');
        setProgress(100);
    }, [setTitle, setProgress])

    return (
        <>
            <SellerHeader setSellerPopup={setSellerPopup} />
            <SellerWhy />
            <SellerOnboarding />
            <SellerTestimonials />
            <SellerJoin setSellerPopup={setSellerPopup} />
            <SellerFaqs />
            {sellerPopup === 1 ? <SellerForm setSellerPopup={setSellerPopup} /> : null}
            {sellerPopup === 2 ? <SellerFormSuccess setSellerPopup={setSellerPopup} /> : null}
        </>
    )
}
