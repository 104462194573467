import React from 'react'
import to from '../../assets/icons/to.svg'
import './checkout.css'

export default function ItinerarySummary(props) {
    return (
        <div className="itinerary-summary">
            <div className="itinerary-image">
                <img src={props.checkoutData.primaryImage} alt="itinerary" />
            </div>
            <div className="itinerary-detail-1">
                <p>{props.checkoutData.origin}</p>
                <img src={to} alt="to" />
                <p>{props.checkoutData.destination}</p>
            </div>
            <div className="itinerary-detail-2">
                <div className="itinerary-detail-inner">
                    <p>Duration</p>
                    <span>{props.checkoutData.duration}</span>
                </div>
                <div className="itinerary-detail-inner">
                    <p>Price</p>
                    <span>₹{props.checkoutData.price}</span>
                </div>
                <div className="itinerary-detail-inner">
                    <p>Package ID</p>
                    <span>{props.checkoutData.tripId}</span>
                </div>
            </div>
        </div>
    )
}
