import React from 'react'
import './tripcard.css'


export default function LoadingTripCard(props) {
    return (
        <div className="trip-card loading-trip-card">
            <div className="trip-card-header">
                <div className="trip-card-image"></div>
            </div>
        </div>
    )
}
