import React from 'react'
import './checkout.css'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AddEmail(props) {

    const navigate = useNavigate();

    function checkEmail() {
        var email = document.getElementById('email').value;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        var btn = document.querySelector('.add-email-input button');
        if (email.match(emailRegex)) {
            btn.disabled = false;
            btn.onClick = emailUpdate;
            return true;
        } else {
            btn.disabled = true;
            btn.onClick = null;
            return false;
        }
    }

    function emailUpdate() {
        var email = document.getElementById('email').value;
        if (!checkEmail(email)) {return}
        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');
        const btn = document.querySelector('.add-email-input button');
        btn.disabled = true;
        if (access && refresh) {
            axios.put("https://romeo.travezco.com/users/update/email/", {emailAddress: email}, {headers: {"Authorization": `Bearer ${access}`}})
                .then(res => {
                    if (res.status === 200) {
                        btn.disabled = false;
                        var otp = prompt("Enter OTP");
                        if (otp) {
                            axios.post("https://romeo.travezco.com/users/update/email/", {otp: otp}, {headers: {"Authorization": `Bearer ${access}`}})
                                .then(res => {
                                    if (res.status === 200) {
                                        props.checkoutData.userEmail = true;
                                        document.querySelector('.add-email button').innerHTML = 'Verified';
                                        document.querySelector('.add-email input').disabled = true;
                                        document.querySelector('.add-email button').disabled = true;
                                    }
                                })
                                .catch(err => {
                                    if (err.response.status === 401) {
                                        axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                                            .then(res => {
                                                cookies.set('access', res.data.access, { path: '/' });
                                                axios.post("https://romeo.travezco.com/users/update/email/", {otp: otp}, {headers: {"Authorization": `Bearer ${res.data.access}`}})
                                                    .then(res => {
                                                        if (res.status === 200) {
                                                            props.checkoutData.userEmail = true;
                                                            document.querySelector('.add-email button').innerHTML = 'Verified';
                                                            document.querySelector('.add-email input').disabled = true;
                                                            document.querySelector('.add-email button').disabled = true;
                                                        }
                                                    })
                                            })
                                            .catch(err => {
                                                if (err.response.status === 401) {
                                                    cookies.remove('access', { path: '/' });
                                                    cookies.remove('refresh', { path: '/' });
                                                }
                                                navigate('/');
                                            })
                                    } else {
                                        alert(err.response.data.error)
                                        btn.disabled = false;
                                    }
                                })
                        }
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                            .then(res => {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.put("https://romeo.travezco.com/users/update/email/", {emailAddress: email}, {headers: {"Authorization": `Bearer ${res.data.access}`}})
                                    .then(res => {
                                        if (res.status === 200) {
                                            btn.disabled = false;
                                            var otp = prompt("Enter OTP");
                                            if (otp) {
                                                axios.post("https://romeo.travezco.com/users/update/email/", {otp: otp}, {headers: {"Authorization": `Bearer ${res.data.access}`}})
                                                    .then(res => {
                                                        if (res.status === 200) {
                                                            return;
                                                        }
                                                    })
                                            }
                                        }
                                    })
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                }
                                navigate('/');
                            })
                    } else {
                        alert(err.response.data.error)
                        btn.disabled = false;
                    }
                })
        }

    }

    return (
        <div className="add-email">
            <p>Email Address</p>
            <div className="add-email-input">
                <input type="email" name="email" id="email" onKeyUp={checkEmail} />
                <button onClick={emailUpdate}>Send OTP</button>
            </div>
        </div>
    )
}
