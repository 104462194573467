import React from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import DestinationHeader from '../components/DestinationHeader/DestinationHeader';
import Filter from '../components/Filter/Filter';
import TripCards from '../components/TripCards/TripCards';

export default function Destination(props) {

    const { destination } = useParams();
    const [dest, setDest] = React.useState({});
    const setProgress = props.setProgress;
    const setTitle = props.setTitle;
    const [trips, setTrips] = React.useState([])
    const [permaTrips, setPermaTrips] = React.useState([])

    React.useEffect(() => {
        setProgress(20);
        axios.get(`https://romeo.travezco.com/trips/destination?destination=${destination}`)
            .then(res => {
                setDest(res.data)
                setTitle(`Explore ${res.data.name} - ${res.data.tagline} with Travezco`);
            })
            .catch(err => console.log(err))
    }, [destination, setProgress, setTitle])

    React.useEffect(() => {
        setTrips(permaTrips);
    }, [permaTrips])

    return (
        <>
            <DestinationHeader name={dest.name} tagline={dest.tagline} collage={dest.collage} />
            <Filter trips={permaTrips} setTrips={setTrips} />
            <TripCards destination={destination} trips={trips} setTrips={setPermaTrips} setProgress={setProgress} authStatus={props.authStatus} setAuth={props.setAuth} />
        </>
    )
}