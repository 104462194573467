import React from 'react'
import './seller.css'

export default function SellerJoin(props) {
    return (
        <div className="seller-join">
            <h3>So what are you waiting for? <span>Join TRAVEZCO today!</span></h3>
            <button onClick={() => props.setSellerPopup(1)}>Get Started &rarr;</button>
        </div>
    )
}
