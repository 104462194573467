import React from 'react'

export default function Privacy() {
    return (
        <>
            <h1><span>P</span>rivacy Policy</h1>
            <p className="last-updated"><i>Last Updated: March 09, 2023</i></p>
            <div className="legal-box">
                <h2>Collection of your personal data</h2>
                <p>Travezco Private Limited is committed to safeguarding your privacy. We may collect your personal data when you register on our website, book travel services, or participate in surveys, contests or promotions. The personal data we collect may include your name, email address, phone number, address, payment information, travel preferences, and other relevant information.</p>
            </div>
            <div className="legal-box">
                <h2>How we use your personal data</h2>
                <p>We may use your personal data to provide you with travel-related services, including but not limited to booking flights, hotels, tours, and transportation. We may also use your personal data to communicate with you, provide customer support, and improve our services.</p>
            </div>
            <div className="legal-box">
                <h2>Use of Demographic and profile data</h2>
                <p>We may use your demographic and profile data to personalize your travel experience and provide you with relevant offers and promotions.</p>
            </div>
            <div className="legal-box">
                <h2>With whom we share your personal Information</h2>
                <p>We may share your personal data with our travel service providers, including airlines, hotels, and transportation companies, to provide you with the travel services you have booked. We may also share your personal data with our business partners to offer you relevant products and services. We do not sell your personal data to third parties.</p>
            </div>
            <div className="legal-box">
                <h2>How long we keep your personal data</h2>
                <p>We will retain your personal data for as long as necessary to provide you with the requested travel services and comply with legal obligations.</p>
            </div>
            <div className="legal-box">
                <h2>Cookies</h2>
                <p>We use cookies and other tracking technologies to improve your experience on our website and analyze how you use our services. You can choose to accept or reject cookies through your browser settings.</p>
            </div>
            <div className="legal-box">
                <h2>How we protect your personal data</h2>
                <p>We implement reasonable security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. We also regularly review and update our security practices to ensure the safety of your personal data.</p>
            </div>
            <div className="legal-box">
                <h2>Notifications of modifications and changes to the policy</h2>
                <p>We may update this privacy policy from time to time. We will notify you of any significant changes to the policy through our website or other appropriate means.</p>
            </div>
            <div className="legal-box">
                <h2>Sharing of traveller's photos and videos</h2>
                <p>We may display photos and videos submitted by travellers on our website and social media channels. By submitting your photos and videos, you consent to their use for promotional purposes.</p>
            </div>
            <div className="legal-box">
                <h2>Security precautions</h2>
                <p>We encourage you to take precautions to protect your personal data when using our website, such as choosing a strong password and not sharing your account information with others.</p>
            </div>
            <div className="legal-box">
                <h2>Choice to opt out</h2>
                <p>You have the right to opt out of receiving marketing communications from us. You can do this by clicking on the unsubscribe link in our emails or by contacting our customer support team.</p>
            </div>
            <div className="legal-box">
                <h2>Transfer of information overseas</h2>
                <p>We may transfer your personal data to our affiliates or service providers located in other countries. We will ensure that such transfers are made in compliance with applicable data protection laws.</p>
            </div>
            <div className="legal-box">
                <h2>EU data subject rights</h2>
                <p>If you are a resident of the European Economic Area (EEA), you have certain rights regarding your personal data, including the right to access, rectify, or erase your personal data. You can contact us to exercise these rights.</p>
            </div>
            <div className="legal-box">
                <h2>Confidentiality</h2>
                <p>We maintain strict confidentiality of your personal data and will only disclose it to third parties in accordance with this privacy policy.</p>
            </div>
            <div className="legal-box">
                <h2>Disclaimer</h2>
                <p>Travezco Private Limited shall not be liable for any loss or damage arising from the use of our website or the services provided by our travel service providers.</p>
            </div>
            <div className="legal-box">
                <h2>Grievance officer</h2>
                <p>If you have any concerns or complaints regarding the processing of your personal data, you can contact our grievance officer at privacy@travezco.com</p>
            </div>
        </>
    )
}
