import React from 'react'
import { Link } from 'react-router-dom'
import './populardestinationcard.css'

export default function PopularDestinationCard(props) {
    return (
        <Link to={`/destination/${props.name}`} className="popular-card-link"><div className="popular-destination-card" >
            <div className="popular-destination-image">
                <img src={props.image} alt="destination" />
                <p className="price-badge">₹{props.price}</p>
            </div>
            <div className="popular-destination-details">
                <h3>{props.name}</h3>
                <p>
                    <i className="fa-sharp fa-solid fa-location-dot"></i>
                    {props.country}
                </p>
            </div>
        </div></Link>
  )
}
