import React from 'react'
import './legal.css'
import img1 from '../../assets/images/terms-art-1.svg'
import img2 from '../../assets/images/terms-art-2.svg'
import Terms from './Terms'
import Privacy from './Privacy'

export default function Legal(props) {
    return (
        <div className="legal-container">
            <div className="legal-art-1">
                <img src={img1} alt="Art" />
            </div>
            {props.name === "terms" ? <Terms /> : null}
            {props.name === "privacy" ? <Privacy /> : null}
            <div className="legal-art-2">
                <img src={img2} alt="Art" />
            </div>
        </div>
    )
}
