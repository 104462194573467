import React from 'react'
import './about.css'
import aboutUs from '../../assets/images/about-us.svg'
import TeamCard from './TeamCard'
import Rupesh from '../../assets/images/Rupesh.jpg'
import Shashank from '../../assets/images/Shashank.png'
import Vipul from '../../assets/images/Vipul.png'
import Dev from '../../assets/images/Dev.jpg'

export default function AboutDetails() {
    return (
        <div className="about-details">
            <div className="about-box">
                <h2>About <span>US</span></h2>
                <div className="about-box-inner">
                    <p><span className="about-blue-text">Travezco</span> is an innovative online marketplace that revolutionizes the way people plan their trips. By partnering with <span className="about-bold-text">location-specific travel agencies</span>, we offer a simplified and hassle-free experience for our customers. These expert agencies provide us with their <span className="about-bold-text">specialized itineraries</span>, ensuring that we offer the best prices and services to our customers. <br /><br />
                    With our <span className="about-bold-text">user-friendly platform</span>, travelers can easily browse through a wide range of well-categorized trips and book their preferred itinerary in just a few clicks. <br /><br />
                    <span className="about-bold-text">No more overwhelming planning or overthinking, just a seamless experience from start to finish.</span></p>
                    <img src={aboutUs} alt="about-us" />
                </div>
            </div>
            <div className="about-box">
                <h2>Our <span>TEAM</span></h2>
                <p className="about-team-text">We are a group of travel and business enthusiasts, making it the perfect combination for a start-up in this domain. We are students at IIT Delhi from different backgrounds and branches who are creative, hard-working, and adept at problem solving.</p>
                <div className="about-team-cards">
                    <TeamCard name="Rupesh" title="Founder" image={Rupesh} linkedin="https://www.linkedin.com/in/rupesh-taneja-836225195/" />
                    <TeamCard name="Shashank Mahawar" title="Co-Founder" image={Shashank} linkedin="https://linkedin.com/in/shashmahawar/" />
                    <TeamCard name="Vipul Suthar" title="Core Team Member" image={Vipul} linkedin="https://www.linkedin.com/in/vipul-suthar-1ba612216/" />
                    <TeamCard name="Dev Bansal" title="Core Team Member" image={Dev} linkedin="https://www.linkedin.com/in/dev-bansal-753061209/" />
                </div>
            </div>
        </div>
    )
}
