import React from 'react'
import './itinerary.css'
import ItineraryDetails from './ItineraryDetails';
import Policy from './Policy';
import QueryButton from './QueryButton';
import QueryModal from './QueryModal';

export default function Itinerary(props) {

    const [section, setSection] = React.useState(0);
    const [query, setQuery] = React.useState(false);

    return (
        <>
            <div className="itinerary-nav">
                <ul>
                    <li id="nav-itinerary" onClick={() => setSection(0)}>Itinerary
                        {section ? null : <div className="itinerary-selected"></div>}
                    </li>
                    <li id="nav-policy" onClick={() => setSection(1)}>
                        Policies
                        {section ? <div className="itinerary-selected"></div> : null}
                    </li>
                </ul>
            </div>
            {section ? <Policy /> : <ItineraryDetails dates={props.dates} itinerary={props.itinerary} trip={props.trip} days={props.days} offers={props.offers} recommendedTrips={props.recommendedTrips} tripReviews={props.tripReviews} ratingChart={props.ratingChart} tripInclusions={props.tripInclusions} tripExclusions={props.tripExclusions} tripFaqs={props.tripFaqs} authStatus={props.authStatus} setAuth={props.setAuth} setAuthStatus={props.setAuthStatus} />}
            <QueryButton setQuery={setQuery} />
            {query ? <QueryModal setQuery={setQuery} trip={props.trip} /> : null}
        </>
    )
}
