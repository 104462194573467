import React from 'react'
import './checkout.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

export default function TravellerDetails(props) {

    const passengers = props.passengers;
    const setPassengers = props.setPassengers;
    const [passengerModal, setPassengerModal] = React.useState(0);
    const [currentPassenger, setCurrentPassenger] = React.useState(-1);
    const [passId, setPassId] = React.useState(1);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const access = cookies.get('access');
    const refresh = cookies.get('refresh');

    window.addEventListener('click', (e) => {
        if (e.target.id === 'passenger-modal') {
            setPassengerModal(0);
        }
    })

    React.useEffect(() => {
        if (passengers.length === props.maxPassengers) {
            document.getElementById('add-traveller-btn').style.display = 'none';
        } else if (passengers.length > props.maxPassengers) {
            setPassengers(passengers.slice(0, props.maxPassengers));
        } else {
            document.getElementById('add-traveller-btn').style.display = 'block';
        }
        var headers = {"Authorization": `Bearer ${access}`};
        axios.post('https://romeo.travezco.com/trips/itinerary/estimate/', {tripId: props.checkoutData.tripId, date: props.date, travellers: passengers, promocode: props.promocode}, {headers: headers})
            .then(res => {
                props.setPaymentDetails(res.data);
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                        .then(res => {
                            cookies.set('access', res.data.access, { path: '/' });
                            axios.post('https://romeo.travezco.com/trips/itinerary/estimate/', {tripId: props.checkoutData.tripId, date: props.date, travellers: passengers, promocode: props.promocode}, {headers: {"Authorization": `Bearer ${res.data.access}`}})
                                .then(res => {
                                    props.setPaymentDetails(res.data);
                                })
                                .catch(err => {
                                    if (err.response.status === 401) {
                                        cookies.remove('access', { path: '/' });
                                        cookies.remove('refresh', { path: '/' });
                                    }
                                    navigate('/');
                                })
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                            }
                            navigate('/');
                        })
                } else {
                    alert('Something went wrong. Please try again later.')
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passengers, props.promocode, props.date])

    function validateData(name, gender, age, idProofType, idProofNumber) {
        var error = document.getElementById('traveller-modal-error');
        if (!name) {
            error.innerHTML = 'Please enter your name';
            return false;
        }
        if (!age) {
            error.innerHTML = 'Please enter your age';
            return false;
        }
        if (age < 1 || age > 120) {
            error.innerHTML = 'Please enter a valid age';
            return false;
        }
        var aadharRegex = new RegExp('^[0-9]{12}$');
        var panRegex = new RegExp('^[A-Z]{5}[0-9]{4}[A-Z]{1}$');
        var voterIdRegex = new RegExp('^[A-Z]{3}[0-9]{7}$');
        var drivingLicenseRegex = new RegExp('^[A-Z]{2}[0-9]{13}$');
        var passportRegex = new RegExp('^[A-Z]{1}[0-9]{7}$');
        if (!idProofNumber) {
            error.innerHTML = 'Please enter your ID Proof Number';
            return false;
        }
        if (idProofType === 'Aadhar' && !idProofNumber.match(aadharRegex)) {
            error.innerHTML = 'Please enter a valid Aadhar Number';
            return false;
        }
        if (idProofType === 'PAN' && !idProofNumber.match(panRegex)) {
            error.innerHTML = 'Please enter a valid PAN Number';
            return false;
        }
        if (idProofType === 'Voter ID' && !idProofNumber.match(voterIdRegex)) {
            error.innerHTML = 'Please enter a valid Voter ID Number';
            return false;
        }
        if (idProofType === 'Driving License' && !idProofNumber.match(drivingLicenseRegex)) {
            error.innerHTML = 'Please enter a valid Driving License Number';
            return false;
        }
        if (idProofType === 'Passport' && !idProofNumber.match(passportRegex)) {
            error.innerHTML = 'Please enter a valid Passport Number';
            return false;
        }
        return true;
    }

    function addPassenger() {
        var name = document.getElementById('fullname').value;
        var gender = document.getElementById('gender').value;
        var age = document.getElementById('age').value;
        var idProofType = document.getElementById('id-proof-type').value;
        var idProofNumber = document.getElementById('id-proof-number').value;
        if (!validateData(name, gender, age, idProofType, idProofNumber)) {
            return;
        }
        var data = {
            name: name, gender: gender, age: age, idType: idProofType, idNumber: idProofNumber, sharing: 'Quad', id: passId
        }
        if (!props.checkoutData.travelBuddy) {
            data.sharing = 'Not Applicable'
        }
        setPassengers([...passengers, data]);
        setPassengerModal(0);
        setPassId(passId + 1);
    }

    function editPassenger(key) {
        setPassengerModal(2);
        setCurrentPassenger(key);
    }

    React.useEffect(() => {
        if (passengerModal === 2) {
            var passenger = passengers[currentPassenger];
            document.getElementById('fullname').value = passenger.name;
            document.getElementById('gender').value = passenger.gender;
            document.getElementById('age').value = passenger.age;
            document.getElementById('id-proof-type').value = passenger.idType;
            document.getElementById('id-proof-number').value = passenger.idNumber;
        }
    }, [currentPassenger, passengers, passengerModal])

    function updatePassenger(key) {
        var name = document.getElementById('fullname').value;
        var gender = document.getElementById('gender').value;
        var age = document.getElementById('age').value;
        var idProofType = document.getElementById('id-proof-type').value;
        var idProofNumber = document.getElementById('id-proof-number').value;
        if (!validateData(name, gender, age, idProofType, idProofNumber)) {
            return;
        }
        var oldPass = passengers[key];
        var data = {
            name: name, gender: gender, age: age, idType: idProofType, idNumber: idProofNumber, sharing: oldPass.sharing, id: oldPass.id
        };
        var newPassengers = [...passengers];
        newPassengers[key] = data;
        setPassengers(newPassengers);
        setPassengerModal(0);
    }

    function deletePassenger(key) {
        var newPassengers = [...passengers];
        newPassengers.splice(key, 1);
        setPassengers(newPassengers);
        setPassengerModal(0);
    }

    return (
        <>
            <div className="traveller-details">
                <div className="traveller-table">
                    <table>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Full Name</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>ID Proof Type</th>
                                <th>ID Proof Number</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passengers.map((passenger, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{key + 1}.</td>
                                        <td>{passenger.name}</td>
                                        <td>{passenger.gender}</td>
                                        <td>{passenger.age}</td>
                                        <td>{passenger.idType}</td>
                                        <td>{passenger.idNumber}</td>
                                        <td><i className="fa-solid fa-pen" onClick={() => editPassenger(key)}></i></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <button onClick={() => setPassengerModal(1)} id="add-traveller-btn">Add Traveller</button>
            </div>
            {passengerModal ?
                <div className="add-traveller-modal" id="passenger-modal">
                    <div className="add-traveller-modal-content">
                        <div className="traveller-modal-input">
                            <p>Full Name</p>
                            <input type="text" name="fullname" id="fullname" />
                        </div>
                        <div className="traveller-modal-input">
                            <p>Gender</p>
                            <select name="gender" id="gender">
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Others">Others</option>
                                <option value="Prefer not say">Prefer not say</option>
                            </select>
                        </div>
                        <div className="traveller-modal-input">
                            <p>Age</p>
                            <input type="number" name="age" id="age" min={1} max={120} />
                        </div>
                        <div className="traveller-modal-input">
                            <p>ID Proof Type</p>
                            <select name="id-proof-type" id="id-proof-type">
                                <option value="Aadhar">Aadhar</option>
                                <option value="PAN">PAN</option>
                                <option value="Voter ID">Voter ID</option>
                                <option value="Driving License">Driving License</option>
                                <option value="Passport">Passport</option>
                            </select>
                        </div>
                        <div className="traveller-modal-input">
                            <p>ID Proof Number</p>
                            <input type="text" name="id-proof-number" id="id-proof-number" />
                        </div>
                        <div className="traveller-modal-error">
                            <p id="traveller-modal-error"></p>
                        </div>
                        <div className="traveller-modal-buttons">
                            {passengerModal === 1 ? <button onClick={addPassenger}>Save</button> : null}
                            {passengerModal === 2 ? <><button onClick={() => updatePassenger(currentPassenger)}>Save</button> <button className="traveller-modal-delete-btn" onClick={() => deletePassenger(currentPassenger)}>Delete</button></> : null}
                        </div>
                    </div>
                </div> : null}
        </>
    )
}
