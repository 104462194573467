import React from 'react'
import './checkout.css'

export default function SharingCard(props) {

    const [searchInput, setSearchInput] = React.useState("");
    const [searchResults, setSearchResults] = React.useState([]);

    React.useEffect(() => {
        if (searchInput === "") {
            document.getElementById("sharing-name-search").value = "";
        }
    }, [searchInput])

    function setInput(event) {
        setSearchInput(event.target.value);
        let results = [];
        for (let i = 0; i < props.allPassengers.length; i++) {
            if (props.allPassengers[i].name.toLowerCase().includes(event.target.value.toLowerCase())) {
                results.push(props.allPassengers[i]);
            }
        }
        setSearchResults(results);
    }

    function setSharing(id) {
        const updatedPassengers = props.allPassengers.map((passenger) => {
            if (passenger.id === id) {
                return {
                    ...passenger,
                    sharing: props.sharing,
                };
            }
            return passenger;
        });
        props.setPassengers(updatedPassengers);
        setSearchInput("");
        setSearchResults([]);
    }

    function setSharingQuad(id) {
        const updatedPassengers = props.allPassengers.map((passenger) => {
            if (passenger.id === id) {
                return {
                    ...passenger,
                    sharing: "Quad",
                };
            }
            return passenger;
        });
        props.setPassengers(updatedPassengers);
    }

    return (
        <div className="sharing-card-outer">
            <h3>{props.sharing}</h3>
            <div className="sharing-card">
                <div className="sharing-card-search">
                    <input type="text" name="sharing-name-search" id="sharing-name-search" placeholder="Enter Traveller Name" onKeyUp={setInput} />
                    {searchInput !== "" ? <div className="sharing-search-list">
                        {searchResults.map((passenger, key) => {
                            return (
                                <p key={key} onClick={() => setSharing(passenger.id)}>{passenger.name}</p>
                            )
                        })}
                    </div> : null}
                </div>
                <div className="sharing-card-names">
                    {props.passengers.map((passenger, key) => {
                        return (
                            <div className="sharing-card-name" key={key}>
                                <p>{passenger.name}</p>
                                {props.sharing === "Quad" ? null : <i className="fa-solid fa-x" onClick={() => {setSharingQuad(passenger.id)}}></i>}
                            </div>
                        )
                    })}
                </div>
                <div className="sharing-card-footer">
                    <p>Cost Per Person: <span>₹ {props.price}</span></p>
                </div>
            </div>
        </div>
    )
}
