import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home';
import Destination from './pages/Destination';
import Profile from './pages/Profile';
import Footer from './components/Footer/Footer';
import ScrollToTop from './ScrollToTop';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Wishlist from './pages/Wishlist';
import Itinerary from './pages/Itinerary';
import Checkout from './pages/Checkout';
import Booking from './pages/Booking';
import TravConnect from './pages/TravConnect';
import Bookings from './pages/Bookings';
import Contact from './pages/Contact';
import About from './pages/About';
import Seller from './pages/Seller';
import Logout from './pages/Logout';
import NotFound from './components/NotFound/NotFound';
import Redirect from './components/Redirect/Redirect';
import Specials from './pages/Specials';

function App() {

    const [auth, setAuth] = React.useState(false);
    const [authStatus, setAuthStatus] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [title, setTitle] = React.useState('Travezco - The Best Travel Marketplace');

    React.useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <BrowserRouter>
            <LoadingBar
                color='#4B9ABD'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Navbar auth={auth} setAuth={setAuth} authStatus={authStatus} setAuthStatus={setAuthStatus} />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} setAuthStatus={setAuthStatus} />} />
                <Route path="/destination/:destination" element={<Destination setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} />} />
                <Route path="/specials/:category" element={<Specials setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} />} />
                <Route path="/itinerary/:tripParam" element={<Redirect path={0} />} />
                <Route path="/package/:tripParam" element={<Itinerary setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} setAuthStatus={setAuthStatus} />} />
                <Route path="/itinerary/:tripParam/checkout" element={<Redirect path={1} />} />
                <Route path="/package/:tripParam/checkout" element={<Checkout setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} setAuthStatus={setAuthStatus} />} />
                <Route path="/booking/success/:bookingId" element={<Booking success={true} setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} setAuthStatus={setAuthStatus} />} />
                <Route path="/booking/fail/:bookingId" element={<Booking success={false} setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} setAuthStatus={setAuthStatus} />} />
                <Route path="/travconnect" element={<TravConnect setProgress={setProgress} setTitle={setTitle} authStatus={authStatus} setAuth={setAuth} setAuthStatus={setAuthStatus} />} />
                <Route path="/profile" element={<Profile setProgress={setProgress} setTitle={setTitle} setAuthStatus={setAuthStatus} setAuth={setAuth} authStatus={authStatus} />} />
                <Route path="/wishlist" element={<Wishlist setProgress={setProgress} setTitle={setTitle} setAuthStatus={setAuthStatus} setAuth={setAuth} authStatus={authStatus} />} />
                <Route path="/bookings" element={<Bookings bookingState={0} setProgress={setProgress} setTitle={setTitle} setAuthStatus={setAuthStatus} setAuth={setAuth} authStatus={authStatus} />} />
                <Route path="/bookings/:bookingId" element={<Bookings bookingState={1} setProgress={setProgress} setTitle={setTitle} setAuthStatus={setAuthStatus} setAuth={setAuth} authStatus={authStatus} />} />
                <Route path="/contact" element={<Contact setProgress={setProgress} setTitle={setTitle} />} />
                <Route path="/terms" element={<Terms setProgress={setProgress} setTitle={setTitle} />} />
                <Route path="/privacy" element={<Privacy setProgress={setProgress} setTitle={setTitle} />} />
                <Route path="/about" element={<About setProgress={setProgress} setTitle={setTitle} />} />
                <Route path="/seller" element={<Seller setProgress={setProgress} setTitle={setTitle} />} />
                <Route path="/logout" element={<Logout authStatus={authStatus} setAuthStatus={setAuthStatus} />} />
                <Route path="*" element={<NotFound setProgress={setProgress} setTitle={setTitle} />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
