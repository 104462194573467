import React from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie';
import '../auth.css'

export default function LoginScreen1(props) {

    function checkInput() {
        const otpRegex = /^[0-9]{5}$/;
        const otp = document.getElementById('login-otp');
        const authError = document.getElementById('auth-error');
        if (otp.value === '') {
            otp.classList.add('error-input');
            authError.innerHTML = 'Please enter the OTP';
            return false;
        }
        else if (otpRegex.test(otp.value) === false) {
            otp.classList.add('error-input');
            authError.innerHTML = 'Please enter a valid OTP';
            return false;
        }
        else {
            otp.classList.remove('error-input');
            authError.innerHTML = '';
            return true;
        }
    }

    function verifyOtp() {
        if (checkInput()) {
            const otp = document.getElementById('login-otp');
            const authError = document.getElementById('auth-error');
            otp.classList.remove('error-input');
            authError.innerHTML = '';
            document.getElementById('submitButton').style.display = 'none';
            document.getElementById('loadingButton').style.display = 'block';
            const url = 'https://romeo.travezco.com/users/login/verify/';
            const data = {
                userId: props.userId,
                otp: otp.value
            }
            axios.post(url, data)
                .then(res => {
                    if (res.status === 200) {
                        const cookies = new Cookies();
                        cookies.set('refresh', res.data.refresh, { path: '/' });
                        cookies.set('access', res.data.access, { path: '/' });
                        props.authState(999);
                        props.authStatus(true);
                        axios.get('https://romeo.travezco.com/users/', { headers: { "Authorization": `Bearer ${res.data.access}` } })
                            .then(res => {
                                if (res.status === 200) {
                                    props.userIcon(res.data.profilePicture);
                                }
                            })
                    }
                })
                .catch(err => {
                    otp.classList.add('error-input');
                    authError.innerHTML = err.response.data.error;
                    document.getElementById('submitButton').style.display = 'block';
                    document.getElementById('loadingButton').style.display = 'none';
                })
        }
    }

    return (
        <div className="auth-right">
            <h4>Please enter the OTP sent to <br /> {props.userParam}</h4>
            <div className="auth-input">
                <input type="text" name="login-otp" id="login-otp" onKeyUp={checkInput} />
                <p className="auth-error" id="auth-error"></p>
                <button onClick={verifyOtp} id="submitButton">Verify</button>
                <button className="loadingButton" id="loadingButton" disabled>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                </button>
            </div>
        </div>
    )
}
