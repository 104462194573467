import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';

export default function Redirect(props) {

    const navigate = useNavigate();
    const { tripParam } = useParams();

    React.useEffect(() => {
        if (props.path === 0) {
            navigate(`/package/${tripParam}`);
        } else if (props.path === 1) {
            navigate(`/package/${tripParam}/checkout`);
        }
    });

    return (
        <div>Loading...</div>
    )
}
