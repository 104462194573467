import React from 'react'
import './bookings.css';
import BookingCard from './BookingCard';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Bookings(props) {

    const setProgress = props.setProgress;
    const setTitle = props.setTitle;
    const [bookings, setBookings] = React.useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        setProgress(20);
        setTitle('My Bookings - Travezco');

        const cookies = new Cookies();
        const access = cookies.get('access');
        const refresh = cookies.get('refresh');

        if (access && refresh) {
            var headers = {Authorization: `Bearer ${access}`}
            axios.get('https://romeo.travezco.com/bookings/fetch/', {headers: headers})
                .then(res => {
                    if (res.status === 200) {
                        setBookings(res.data);
                        setProgress(100);
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        axios.post('https://romeo.travezco.com/users/token/refresh/', {refresh: refresh})
                            .then(res => {
                                cookies.set('access', res.data.access, {path: '/'});
                                headers = {Authorization: `Bearer ${res.data.access}`}
                                axios.get('https://romeo.travezco.com/bookings/fetch/', {headers: headers})
                                    .then(res => {
                                        if (res.status === 200) {
                                            setBookings(res.data);
                                            setProgress(100);
                                        }
                                    }
                                )
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    cookies.remove('access', {path: '/'});
                                    cookies.remove('refresh', {path: '/'});
                                }
                                navigate('/');
                            })
                    }
                })
        } else {
            navigate('/');
        }

    }, [setProgress, setTitle, navigate])

    return (
        <div className="booking-cards">
            {bookings.length === 0 ? <h1 style={{textAlign: 'center', marginTop: '5rem'}}>No Bookings Found</h1> : null}
            {bookings.map((booking, key) => {
                return (
                    <BookingCard 
                        key={key}
                        origin={booking.origin}
                        destination={booking.destination}
                        bookingId={booking.bookingId}
                        bookingStatus={booking.status}
                        travelAgent={booking.travelAgent}
                        dateOfJourney={booking.dateOfJourney}
                    />
                )
            })}
        </div>
    )
}
