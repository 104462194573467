import React from 'react'
import './filter.css'
import filter from '../../assets/icons/filter.svg'
import sort from '../../assets/icons/sort.svg'
import star from '../../assets/icons/star-white.svg'

// TODO : Filters for Mobile Site

export default function Filter(props) {

    const trips = props.trips
    const setTrips = props.setTrips
    const [filterVisibility, setFilterVisibility] = React.useState(null)
    const [priceFilter, setPriceFilter] = React.useState(null)
    const [ratingFilter, setRatingFilter] = React.useState(null)
    const [durationFilter, setDurationFilter] = React.useState(null)
    const [sortFilter, setSortFilter] = React.useState(null)

    React.useEffect(() => {

        var tripSet = trips;

        function filterByPrice(val, trips) {
            if (val === 1) {
                const filteredTrips = trips.filter(trip => {
                    return trip.sellingPrice < 5000
                })
                tripSet = filteredTrips
            } else if (val === 2) {
                const filteredTrips = trips.filter(trip => {
                    return trip.sellingPrice >= 5000 && trip.sellingPrice <= 10000
                })
                tripSet = filteredTrips
            } else if (val === 3) {
                const filteredTrips = trips.filter(trip => {
                    return trip.sellingPrice >= 10000 && trip.sellingPrice <= 20000
                })
                tripSet = filteredTrips
            } else if (val === 4) {
                const filteredTrips = trips.filter(trip => {
                    return trip.sellingPrice > 20000
                })
                tripSet = filteredTrips
            }
        }
        
        if (priceFilter) {
            filterByPrice(priceFilter, tripSet)
        }

        function filterByRating(val, trips) {
            const filteredTrips = trips.filter(trip => {
                return trip.rating >= val
            })
            tripSet = filteredTrips
        }

        if (ratingFilter) {
            filterByRating(ratingFilter, tripSet)
        }

        function filterByDuration(val, trips) {
            if (val === 1) {
                const filteredTrips = trips.filter(trip => {
                    var duration = parseInt(trip.duration.split('/')[1].slice(0, -1))
                    return duration < 3
                })
                tripSet = filteredTrips
            } else if (val === 2) {
                const filteredTrips = trips.filter(trip => {
                    var duration = parseInt(trip.duration.split('/')[1].slice(0, -1))
                    return duration >= 3 && duration <= 5
                })
                tripSet = filteredTrips
            } else if (val === 3) {
                const filteredTrips = trips.filter(trip => {
                    var duration = parseInt(trip.duration.split('/')[1].slice(0, -1))
                    return duration > 5
                })
                tripSet = filteredTrips
            }
        }

        if (durationFilter) {
            filterByDuration(durationFilter, tripSet)
        }

        function sortTrips(val, trips) {
            if (val === 1) {
                const sortedTrips = trips.sort((a, b) => {
                    return a.sellingPrice - b.sellingPrice
                })
                tripSet = sortedTrips
            } else if (val === 2) {
                const sortedTrips = trips.sort((a, b) => {
                    return b.sellingPrice - a.sellingPrice
                })
                tripSet = sortedTrips
            } else if (val === 3) {
                const sortedTrips = trips.sort((a, b) => {
                    return b.rating - a.rating
                })
                tripSet = sortedTrips
            }
        }

        if (sortFilter) {
            sortTrips(sortFilter, tripSet)
        }

        setTrips([...tripSet])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceFilter, ratingFilter, durationFilter, sortFilter])

    React.useEffect(() => {
        const priceFilterDropdown = document.getElementById("price-filter-dropdown")
        const ratingFilterDropdown = document.getElementById("rating-filter-dropdown")
        const durationFilterDropdown = document.getElementById("duration-filter-dropdown")
        const sortByDropdown = document.getElementById("sort-by-dropdown")
        if (filterVisibility === "price") {
            priceFilterDropdown.style.height = "max-content"
            priceFilterDropdown.style.padding = "10px 20px"
            ratingFilterDropdown.style.height = "0"
            ratingFilterDropdown.style.padding = "0 20px"
            durationFilterDropdown.style.height = "0"
            durationFilterDropdown.style.padding = "0 20px"
            sortByDropdown.style.height = "0"
            sortByDropdown.style.padding = "0 20px"
        } else if (filterVisibility === "rating") {
            ratingFilterDropdown.style.height = "max-content"
            ratingFilterDropdown.style.padding = "10px 20px"
            priceFilterDropdown.style.height = "0"
            priceFilterDropdown.style.padding = "0 20px"
            durationFilterDropdown.style.height = "0"
            durationFilterDropdown.style.padding = "0 20px"
            sortByDropdown.style.height = "0"
            sortByDropdown.style.padding = "0 20px"
        } else if (filterVisibility === "duration") {
            durationFilterDropdown.style.height = "max-content"
            durationFilterDropdown.style.padding = "10px 20px"
            priceFilterDropdown.style.height = "0"
            priceFilterDropdown.style.padding = "0 20px"
            ratingFilterDropdown.style.height = "0"
            ratingFilterDropdown.style.padding = "0 20px"
            sortByDropdown.style.height = "0"
            sortByDropdown.style.padding = "0 20px"
        } else if (filterVisibility === "sort") {
            sortByDropdown.style.height = "max-content"
            sortByDropdown.style.padding = "10px 20px"
            priceFilterDropdown.style.height = "0"
            priceFilterDropdown.style.padding = "0 20px"
            ratingFilterDropdown.style.height = "0"
            ratingFilterDropdown.style.padding = "0 20px"
            durationFilterDropdown.style.height = "0"
            durationFilterDropdown.style.padding = "0 20px"
        } else {
            priceFilterDropdown.style.height = "0"
            priceFilterDropdown.style.padding = "0 20px"
            ratingFilterDropdown.style.height = "0"
            ratingFilterDropdown.style.padding = "0 20px"
            durationFilterDropdown.style.height = "0"
            durationFilterDropdown.style.padding = "0 20px"
            sortByDropdown.style.height = "0"
            sortByDropdown.style.padding = "0 20px"
        }
    }, [filterVisibility])

    React.useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.classList.contains("filter-option") && !e.target.classList.contains("filter-right")) {
                if (e.target.parentNode) {
                    if (!e.target.parentNode.classList.contains("filter-option") && !e.target.parentNode.classList.contains("filter-right")) {
                        setFilterVisibility(null)
                    } else {
                        return
                    }
                }
                setFilterVisibility(null)
            }
            if (window.outerWidth < 768) {
                const filterOptions = document.querySelector(".filter-options")
                if (!e.target.classList.contains("filter-icon") && !e.target.classList.contains("filter-options")) {
                    if (e.target.parentNode) {
                        if (!e.target.parentNode.classList.contains("filter-icon") && !e.target.parentNode.classList.contains("filter-options")) {
                            filterOptions.style.display = "none"
                        } else {
                            return
                        }
                    }
                    filterOptions.style.display = "none"
                }
            }
        })
    })

    function togglePrice() {
        const priceFilterDropdown = document.getElementById("price-filter-dropdown")
        if (priceFilterDropdown.style.height === "0px") {
            setFilterVisibility("price")
        } else {
            setFilterVisibility(null)
        }
    }

    function toggleRating() {
        const ratingFilterDropdown = document.getElementById("rating-filter-dropdown")
        if (ratingFilterDropdown.style.height === "0px") {
            setFilterVisibility("rating")
        } else {
            setFilterVisibility(null)
        }
    }

    function toggleDuration() {
        const durationFilterDropdown = document.getElementById("duration-filter-dropdown")
        if (durationFilterDropdown.style.height === "0px") {
            setFilterVisibility("duration")
        } else {
            setFilterVisibility(null)
        }
    }

    function toggleSort() {
        const sortByDropdown = document.getElementById("sort-by-dropdown")
        if (sortByDropdown.style.height === "0px") {
            setFilterVisibility("sort")
        } else {
            setFilterVisibility(null)
        }
    }

    function mobileFilter() {
        if (window.outerWidth > 768) {
            return
        }
        const filterOptions = document.querySelector(".filter-options")
        filterOptions.style.display = "block"
    }

    return (
        <div className="filter-bar">
            <div className="filter-left">
                <div className="filter-icon" onClick={mobileFilter}>
                    <img src={filter} alt="filter"/>
                </div>
                <div className="filter-options">
                    <div className="filter-option" onClick={togglePrice}>
                        <p>Price</p>
                        <i className="fa-solid fa-caret-down"></i>
                        {priceFilter ? <span></span> : null}
                        <div className="filter-dropdown" id="price-filter-dropdown">
                            <div className="filter-dropdown-item">
                                <input type="radio" name="price" id="price-less-than-5000" onClick={() => setPriceFilter(1)} />
                                <label htmlFor="price-less-than-5000">Less than ₹5000</label>
                            </div>
                            <div className="filter-dropdown-item">
                                <input type="radio" name="price" id="price-5000-to-10000" onClick={() => setPriceFilter(2)} />
                                <label htmlFor="price-5000-to-10000">₹5000 to ₹10000</label>
                            </div>
                            <div className="filter-dropdown-item">
                                <input type="radio" name="price" id="price-10000-to-20000" onClick={() => setPriceFilter(3)} />
                                <label htmlFor="price-10000-to-20000">₹10000 to ₹20000</label>
                            </div>
                            <div className="filter-dropdown-item">
                                <input type="radio" name="price" id="price-more-than-20000" onClick={() => setPriceFilter(4)} />
                                <label htmlFor="price-more-than-20000">More than ₹20000</label>
                            </div>
                        </div>
                    </div>
                    <div className="filter-option" onClick={toggleRating}>
                        <p>Rating</p>
                        {ratingFilter ? <span></span> : null}
                        <i className="fa-solid fa-caret-down"></i>
                        <div className="filter-dropdown" id="rating-filter-dropdown">
                            <div className="filter-dropdown-item">
                                <input type="radio" name="rating" id="rating-four-and-above" onClick={() => setRatingFilter(4)} />
                                <label htmlFor="rating-four-and-above">4 <img src={star} height={10} alt="rating" /> and above</label>
                            </div>
                            <div className="filter-dropdown-item">
                                <input type="radio" name="rating" id="rating-three-and-above" onClick={() => setRatingFilter(3)} />
                                <label htmlFor="rating-three-and-above">3 <img src={star} height={10} alt="rating" /> and above</label>
                            </div>
                        </div>
                    </div>
                    <div className="filter-option" onClick={toggleDuration}>
                        <p>Duration</p>
                        {durationFilter ? <span></span> : null}
                        <i className="fa-solid fa-caret-down"></i>
                        <div className="filter-dropdown" id="duration-filter-dropdown">
                            <div className="filter-dropdown-item">
                                <input type="radio" name="duration" id="duration-less-than-3" onClick={() => setDurationFilter(1)} />
                                <label htmlFor="duration-less-than-3">Less than 3 days</label>
                            </div>
                            <div className="filter-dropdown-item">
                                <input type="radio" name="duration" id="duration-3-to-5" onClick={() => setDurationFilter(2)} />
                                <label htmlFor="duration-3-to-5">3 to 5 Days</label>
                            </div>
                            <div className="filter-dropdown-item">
                                <input type="radio" name="duration" id="duration-more-than-5" onClick={() => setDurationFilter(3)} />
                                <label htmlFor="duration-more-than-5">More than 5 days</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="filter-right" onClick={toggleSort}>
                <img src={sort} alt="sort-by" />
                <p>Sort By</p>
                <div className="sort-by-dropdown" id="sort-by-dropdown">
                    <div className="sort-by-dropdown-item">
                        <input type="radio" name="sort-by" id="sort-by-price-ascending" onClick={() => setSortFilter(1)} />
                        <label htmlFor="sort-by-price-ascending">Price - Low to High</label>
                    </div>
                    <div className="sort-by-dropdown-item">
                        <input type="radio" name="sort-by" id="sort-by-price-descending" onClick={() => setSortFilter(2)} />
                        <label htmlFor="sort-by-price-descending">Price - High to Low</label>
                    </div>
                    <div className="sort-by-dropdown-item">
                        <input type="radio" name="sort-by" id="sort-by-rating" onClick={() => setSortFilter(3)} />
                        <label htmlFor="sort-by-rating">Rating</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
