import React from 'react'
import './profileedit.css'
import EditBio from './EditBio'
import EditPersonal from './EditPersonal'
import EditAddress from './EditAddress';
import EditContact from './EditContact';

export default function ProfileEdit(props) {

    const setEdit = props.setEdit;
    const setAuthStatus = props.setAuthStatus;

    window.addEventListener('click', (e) => {
        if (e.target.className === 'profile-edit-modal') {
            setEdit(0);
        }
    })

    return (
        <div className="profile-edit-modal">
            <div className="profile-edit-modal-content">
                {props.edit === 1 ? <EditBio profile={props.profile} setEdit={setEdit} setAuthStatus={setAuthStatus} /> : null}
                {props.edit === 2 ? <EditPersonal profile={props.profile} setEdit={setEdit} setAuthStatus={setAuthStatus} /> : null}
                {props.edit === 3 ? <EditContact profile={props.profile} setEdit={setEdit} setAuthStatus={setAuthStatus} /> : null}
                {props.edit === 4 ? <EditAddress profile={props.profile} setEdit={setEdit} setAuthStatus={setAuthStatus} /> : null}
            </div>
        </div>
    )
}
