import React from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import '../auth.css'

export default function SignupScreen3(props) {

    function checkInput() {
        const name = document.getElementById('signup-name');
        const authError = document.getElementById('auth-error');
        if (name.value === '') {
            name.classList.add('error-input');
            authError.innerHTML = 'Please enter your name';
            return false;
        } else {
            name.classList.remove('error-input');
            authError.innerHTML = '';
            return true;
        }
    }

    function signupOnboard() {
        if (checkInput()) {
            const name = document.getElementById('signup-name');
            const dob = document.getElementById('signup-dob');
            const gender = document.getElementById('signup-gender');
            const authError = document.getElementById('auth-error');
            name.classList.remove('error-input');
            authError.innerHTML = '';
            document.getElementById('submitButton').style.display = 'none';
            document.getElementById('loadingButton').style.display = 'block';

            const cookies = new Cookies();
            const access = cookies.get('access');
            const refresh = cookies.get('refresh');
            
            if (access && refresh) {
                const url = 'https://romeo.travezco.com/users/signup/onboarding/';
                const headers = {
                    Authorization: `Bearer ${access}`
                }
                const data = {
                    name: name.value,
                    dob: dob.value,
                    gender: gender.value,
                }
                axios.post(url, data, { headers: headers })
                    .then(res => {
                        if (res.status === 200) {
                            props.authState(999);
                        }})
                    .catch(err => {
                        if (err.response.status === 401) {
                            const url = 'https://romeo.travezco.com/users/token/refresh/';
                            const newData = {
                                refresh: refresh
                            }
                            axios.post(url, newData)
                            .then(res => {
                                if (res.status === 200) {
                                    cookies.set('access', res.data.access, { path: '/' });
                                    const url = 'https://romeo.travezco.com/users/signup/onboarding/';
                                    const header = {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                    axios.post(url, { headers: header, data: data })
                                        .then(res => {
                                            if (res.status === 200) {
                                                props.authState(999);
                                            }
                                        })
                                }
                            })
                            .catch(err => {
                                if (err.response.status === 401) {
                                    props.authState(999);
                                    props.authStatus(false);
                                    cookies.remove('access', { path: '/' });
                                    cookies.remove('refresh', { path: '/' });
                                }
                                else {
                                    authError.innerHTML = err.response.data.error;
                                    document.getElementById('submitButton').style.display = 'block';
                                    document.getElementById('loadingButton').style.display = 'none';
                                }
                            })    
                        }
                        else {
                            authError.innerHTML = err.response.data.error;
                            document.getElementById('submitButton').style.display = 'block';
                            document.getElementById('loadingButton').style.display = 'none';
                        }
                    })
                }
            else {
                props.authState(999);
            }            
        }
    }

    return (
        <div className="auth-right">
            <h4>Few details to get you started 🚀</h4>
            <div className="auth-input">
                <p>Name</p>
                <input type="text" name="signup-name" id="signup-name" onKeyUp={checkInput} />
                <p>Date of Birth</p>
                <input type="date" name="signup-dob" id="signup-dob" />
                <p>Gender</p>
                <select name="signup-gender" id="signup-gender">
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Others">Others</option>
                    <option value="Prefer not say">Prefer not say</option>
                </select>
                <p className="auth-error" id="auth-error"></p>
                <button onClick={signupOnboard} id="submitButton">Start Exploring</button>
                <button className="loadingButton" id="loadingButton" disabled>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                    <i className="fa-solid fa-circle"></i>
                </button>
            </div>
        </div>
    )
}
