import React from 'react'
import './homecarousel.css'
import SearchBar from './SearchBar'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function HomeCarousel() {

    const [offers, setOffers] = React.useState([])

    React.useEffect(() => {
        if (window.outerWidth > 768) {
            axios.get('https://romeo.travezco.com/misc/offers/?category=Desktop')
            .then(res => {
                setOffers(res.data)
            })
        } else {
            axios.get('https://romeo.travezco.com/misc/offers/?category=Mobile')
            .then(res => {
                setOffers(res.data)
            })
        }
    }, [])

    React.useEffect(() => {
        if (!offers.length) return;
        const cards = document.querySelectorAll('.offer-cards')
        let counter = 1;
        setInterval(() => {
            if (counter === cards.length) {
                counter = 0;
            }
            cards.forEach(card => {
                card.style.display = 'none'
            })
            cards[counter].style.display = 'block'
            counter++;
        }, 8000)
    }, [offers])

    return (
        <div className="home-carousel">
            <div className="home-carousel-image">
                {offers.map((offer, key) => {
                    if (key !== 0) {
                        return <Link to={offer.redirect_path || "#"}><img key={key} src={offer.image} className="offer-cards" style={{display: 'none'}} alt={`offer-${key+1}`} /></Link>
                    }
                    return <Link to={offer.redirect_path || "#"}><img key={key} src={offer.image} className="offer-cards" alt={`offer-${key+1}`} /></Link>
                })}
            </div>
            <SearchBar />
        </div>
    )
}
