import React from 'react'
import { Link } from 'react-router-dom'
import './travconnect.css'
import travconnectHome from '../../assets/images/travconnect-home.svg'
import LoadingTripCard from '../TripCards/LoadingTripCard'
import TripCard from '../TripCards/TripCard'
import Cookies from 'universal-cookie'
import axios from 'axios'

export default function TravConnect(props) {

    const [travCards, setTravCards] = React.useState([])

    React.useEffect(() => {
        const cookies = new Cookies();
        var access = cookies.get('access')
        const refresh = cookies.get('refresh')

        if (access && refresh) {
            axios.post('https://romeo.travezco.com/users/token/refresh/', { headers: { Authorization: `Bearer ${access}` } })
                .then(res => {
                    if (res.status === 200) {
                        cookies.set('access', res.data.access, { path: '/' })
                        access = res.data.access

                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        cookies.remove('access')
                        cookies.remove('refresh')
                    }
                })
        }

        var headers = {}

        if (access) {
            headers = {
                Authorization: `Bearer ${access}`
            }
        }

        axios.get('https://romeo.travezco.com/trips/travconnect/', { headers: headers })
            .then(res => {
                if (res.status === 200) {
                    // only first 5 cards
                    setTravCards(res.data.slice(0, 5))
                    const loadingCards = document.querySelectorAll('.loading-trip-card')
                    loadingCards.forEach(card => {
                        card.style.display = 'none'
                    })
                }
            })
            .catch(err => {
                alert(err.response.data.error);
            })
    }, [])

    return (
        <div className="travconnect">
            <h2><span>Trav</span>Connect</h2>
            <div className="travconnet-timeline">
                <div className="travconnect-timeline-line"></div>
                <div className="travconnect-timeline-item">
                    <i className="fa-solid fa-circle"></i>
                    <p>Like Minded Travellers</p>
                </div>
                <div className="travconnect-timeline-item">
                    <i className="fa-solid fa-circle"></i>
                    <p>Travel Interests</p>
                </div>
                <div className="travconnect-timeline-item">
                    <i className="fa-solid fa-circle"></i>
                    <p>Unforgettable Memories</p>
                </div>
                <div className="travconnect-timeline-item">
                    <i className="fa-solid fa-circle"></i>
                    <p>Travel Buddies</p>
                </div>
                <div className="travconnect-timeline-item">
                    <i className="fa-solid fa-circle"></i>
                    <p>Group Travel</p>
                </div>
            </div>
            <div className="travconnect-details">
                <div className="travconnect-details-text">
                    <p>Experience the power of connection with Travezco's TravConnect feature. Join like-minded individuals in group travel adventures, forging new friendships and creating cherished memories together. By sharing costs, you can also save money while embarking on unforgettable journeys. Discover the joy of traveling with a community that shares your passion and create lifelong connections.</p>
                    <Link to="/travconnect"><button>Explore More</button></Link>
                </div>
                <div className="travconnect-details-image">
                    <img src={travconnectHome} alt="TravConnect" />
                </div>
            </div>
            <div className="travconnect-cards">
                {travCards.map((trip, key) => {
                    return (
                        <TripCard
                            key={trip.id}
                            id={trip.id}
                            origin={trip.origin}
                            destination={trip.destination}
                            duration={trip.duration}
                            discount={trip.discount}
                            costPrice={trip.costPrice}
                            sellingPrice={trip.sellingPrice}
                            image={trip.primaryImage}
                            highlights={trip.highlights}
                            rating={trip.rating}
                            flight={trip.flight}
                            meal={trip.meal}
                            stay={trip.stay}
                            transfer={trip.transfer}
                            wishlist={trip.wishlist}
                            specialTag={trip.specialTag}
                            date={trip.date}
                            authStatus={props.authStatus}
                            setAuth={props.setAuth}
                            setAuthStatus={props.setAuthStatus}
                        />
                    )
                })}
                <LoadingTripCard />
                <LoadingTripCard />
                <LoadingTripCard />
                <LoadingTripCard />
                <LoadingTripCard />
            </div>
        </div>
    )
}
