import React from 'react'
import './about.css'

export default function AboutStatItem(props) {
    return (
        <div className="about-stat-item">
            <div className="about-stat-item-image">
                <img src={props.image} alt={props.text} />
            </div>
            <div className="about-stat-item-info">
                <p>{props.number}</p>
                <span>{props.text}</span>
            </div>
        </div>
    )
}
