import React from 'react'
import './about.css'
import AboutStatItem from './AboutStatItem'
import aboutTraveller from '../../assets/icons/about-traveller.svg'
import aboutTrips from '../../assets/icons/about-trips.svg'
import aboutRating from '../../assets/icons/about-rating.svg'

export default function AboutStat() {
    return (
        <div className="about-stats">
            <AboutStatItem number="1000+" text="Travellers" image={aboutTraveller} />
            <AboutStatItem number="100+" text="Trips" image={aboutTrips} />
            <AboutStatItem number="4.9" text="Google Rating" image={aboutRating} />
        </div>
    )
}
