import React from 'react'
import './checkout.css'
import PromocodeCard from './PromocodeCard'

export default function Promocode(props) {

    React.useEffect(() => {
        var promocode = document.getElementById("promocode");
        if (props.promocode !== 'NONE') {
            promocode.value = props.promocode;
        }
    }, [props.promocode])

    function applyCode() {
        var promocode = document.getElementById("promocode");
        if (!promocode.value) {
            return;
        }
        props.setPromocode(document.getElementById("promocode").value);
    }

    return (
        <div className="promocode">
            <div className="promocode-input">
                <input type="text" name="promocode" id="promocode" />
                <button onClick={applyCode}>Apply</button>
            </div>
            <div className="promocode-cards">
                {props.checkoutData.offers.map((offer, key) => {
                    return <PromocodeCard key={key} offer={offer} setPromocode={props.setPromocode} />
                })}
            </div>
        </div>
    )
}
