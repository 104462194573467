import React from 'react'
import { Link } from 'react-router-dom'
import call from '../../assets/icons/call.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'

export default function MobileNavNavigator(props) {

    window.addEventListener('click', (e) => {
        const mobileNav = document.querySelector('.mobile-nav');
        const hamburger = document.querySelector('.nav-hamburger');
        const destination = document.querySelector('.mobile-nav-dest-click');
        const goback = document.querySelector('.mobile-nav-destinations-go-back');
        const cover = document.querySelector('.mobile-nav-screen-cover');
        if (e.target === cover) {
            mobileNav.style.width = '0';
            cover.style.display = 'none';
            props.navstate(0);
        } else {
            if (e.target !== mobileNav && e.target.parentNode !== mobileNav && e.target !== hamburger && e.target.parentNode !== hamburger && e.target !== goback && e.target.parentNode !== goback && destination !== null) {
                mobileNav.style.width = '0';
                cover.style.display = 'none';
                props.navstate(0);
            }
        }
    })

    return (
        <>
            <div className="mobile-nav-links">
                <div className="mobile-nav-dest-click" onClick={() => props.navstate(1)}>
                    Destinations
                    <i className="fa-solid fa-angle-right">&nbsp;</i>
                </div>
                <Link to="/travconnect">TravConnect</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <div className="mobile-nav-auth">
                {props.authStatus ? <>
                    <Link to="/profile" className="mobile-nav-invert-button">My Profile</Link>
                    <Link to="/bookings" className="mobile-nav-invert-button">My Bookings</Link>
                    <Link to="/wishlist" className="mobile-nav-invert-button">My Wishlist</Link>
                    <Link to="/logout" className="mobile-nav-invert-button">Logout</Link>
                </> : <Link to="#" onClick={() => props.setAuth(1)}>Login</Link>}
            </div>
            <div className="mobile-nav-contact">
                <Link to="tel:918396957516">
                    <img src={call} alt="call" />
                    <span>+91 83969 57516</span>
                </Link>
                <Link to="https://wa.me/918396957516?text=Hello%2C%20I%20would%20like%20to%20go%20on%20a%20trip!" target="_blank">
                    <img src={whatsapp} alt="Whatsapp" />
                    <span>Chat</span>
                </Link>
            </div>
        </>
    )
}
