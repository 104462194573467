import React from 'react'
import image1 from '../../assets/images/travconnect-1.svg'
import image2 from '../../assets/images/travconnect-2.svg'
import './travconnect.css'

export default function Header() {
    return (
        <div className="travconnect-header">
            <div className="travconnect-image">
                <img src={image1} alt="TravConnect" />
            </div>
            <div className="travconnect-image">
                <img src={image2} alt="TravConnect" />
            </div>
        </div>
    )
}
