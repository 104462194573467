import React from 'react'
import './seller.css'

export default function SellerFaqCard(props) {

    function sellerFaqHandle(id) {
        let question = document.getElementById(`seller-faq-question-${id}`);
        let answer = document.getElementById(`seller-faq-answer-${id}`);
        if (answer.style.height === "auto") {
            question.classList.remove('fa-minus');
            question.classList.add('fa-plus');
            answer.style.height = "0px";
            answer.style.paddingBottom = '0px';
        } else {
            question.classList.remove('fa-plus');
            question.classList.add('fa-minus');
            answer.style.height = 'auto';
            answer.style.paddingBottom = '10px';
        }
    }

    return (
        <div className="seller-faq-card">
            <div className="seller-faq-card-question" onClick={() => sellerFaqHandle(props.id)}>
                <p>{props.question}</p>
                <i className="fa-solid fa-plus" id={`seller-faq-question-${props.id}`}></i>
            </div>
            <div className="seller-faq-card-answer" id={`seller-faq-answer-${props.id}`}>
                <p>{props.answer}</p>
            </div>
        </div>
    )
}
