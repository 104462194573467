import React from 'react'
import './profileedit.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export default function EditPersonal(props) {

    const setAuthStatus = props.setAuthStatus;
    const cookies = new Cookies();
    const access = cookies.get('access');
    const refresh = cookies.get('refresh');
    const navigate = useNavigate();

    function updatePersonalDetails() {
        const newName = document.getElementById('edit-name').value;
        const newGender = document.getElementById('edit-gender').value;
        const newMaritalStatus = document.getElementById('edit-maritalStatus').value;
        const newDob = document.getElementById('edit-dob').value;
        const newLocation = document.getElementById('edit-location').value;
        document.querySelector('.error-text').innerHTML = '';
        if (!newName || !newGender || !newMaritalStatus || !newDob || !newLocation) {
            document.querySelector('.error-text').innerHTML = 'Please fill all the fields';
            return;
        }
        axios.put(`https://romeo.travezco.com/users/update/personal/`, {
            name: newName,
            gender: newGender,
            maritalStatus: newMaritalStatus,
            dob: newDob,
            location: newLocation
        }, {
            headers: {
                "Authorization": `Bearer ${access}`
            }
        })
            .then(res => {
                if (res.status === 200) {
                    props.setEdit(0);
                    props.profile.name = res.data.name;
                    props.profile.gender = res.data.gender;
                    props.profile.maritalStatus = res.data.maritalStatus;
                    props.profile.dateOfBirth = res.data.dateOfBirth;
                    props.profile.location = res.data.location;
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    axios.post('https://romeo.travezco.com/users/token/refresh/', {
                        refresh: refresh
                    })
                        .then(res => {
                            if (res.status === 200) {
                                cookies.set('access', res.data.access, { path: '/' });
                                axios.put(`https://romeo.travezco.com/users/update/personal/`, {
                                    name: newName,
                                    gender: newGender,
                                    maritalStatus: newMaritalStatus,
                                    dob: newDob,
                                    location: newLocation
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${res.data.access}`
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            props.setEdit(0);
                                            props.profile.name = res.data.name;
                                            props.profile.gender = res.data.gender;
                                            props.profile.maritalStatus = res.data.maritalStatus;
                                            props.profile.dateOfBirth = res.data.dateOfBirth;
                                            props.profile.location = res.data.location;
                                        }
                                    }
                                    )
                            }
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                cookies.remove('access', { path: '/' });
                                cookies.remove('refresh', { path: '/' });
                                props.setEdit(0);
                                setAuthStatus(false);
                            }
                            navigate('/');
                        })
                }
            })
    }

    return (
        <>
            <h3>Edit Personal Details</h3>
            <p>Name</p>
            <input type="text" name="edit-name" id="edit-name" defaultValue={props.profile.name} />
            <p>Gender</p>
            <select name="edit-gender" id="edit-gender" defaultValue={props.profile.gender}>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Others">Others</option>
                <option value="Prefer not say">Prefer not say</option>
            </select>
            <p>Marital Status</p>
            <select name="edit-maritalStatus" id="edit-maritalStatus" defaultValue={props.profile.maritalStatus}>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
                <option value="Prefer not say">Prefer not say</option>
            </select>
            <p>Date of Birth</p>
            <input type="date" name="edit-dob" id="edit-dob" defaultValue={props.profile.dob} />
            <p>Location</p>
            <input type="text" name="edit-location" id="edit-location" defaultValue={props.profile.location} />
            <p className="error-text"></p>
            <button onClick={updatePersonalDetails}>Save</button>
        </>
    )
}
