import React from 'react'
import './seller.css'
import SellerFaqCard from './SellerFaqCard'

export default function SellerFaqs() {
    return (
        <div className="seller-faqs">
            <h3>Frequently Asked Questions (FAQs)</h3>
            <div className="seller-faq-cards">
                <SellerFaqCard key={1} id={1} question="Q1" answer="A1" />
                <SellerFaqCard key={2} id={2} question="Q2" answer="A2" />
            </div>
        </div>
    )
}
