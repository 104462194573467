import React from 'react'
import user from '../../assets/icons/profile-user.svg'
import design from '../../assets/images/profile-design.svg'
import './profilenavigator.css'
import { Link } from 'react-router-dom'

export default function ProfileNavigator(props) {
    return (
        <div className="profile-navigator">
            <div className="profile-navigator-header">
                <img src={user} alt="profile" />
            </div>
            <div className="profile-navigator-main">
                <div className="profile-navigator-option">
                    <Link to="/profile">
                        My Profile
                        {props.selected === 0 ? <div className="profile-navigation-selected"></div> : null}
                    </Link>
                </div>
                <div className="profile-navigator-option">
                    <Link to="/bookings">
                        My Bookings
                        {props.selected === 1 ? <div className="profile-navigation-selected"></div> : null}
                    </Link>
                </div>
                <div className="profile-navigator-option">
                    <Link to="/wishlist">
                        My Wishlist
                        {props.selected === 2 ? <div className="profile-navigation-selected"></div> : null}
                    </Link>
                </div>
                <div className="profile-navigator-option">
                    <Link to="/logout">Logout</Link>
                </div>
            </div>
            <div className="profile-navigator-footer">
                <img src={design} alt="profile-design" />
            </div>
        </div>
    )
}
