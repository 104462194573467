import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/full_logo.svg'
import './mobilenav.css'
import MobileNavNavigator from './MobileNavNavigator'
import MobileNavDestinations from './MobileNavDestinations'

export default function MobileNav(props) {

    const [mobileDestNav, setMobileDestNav] = React.useState(0);

    React.useEffect(() => {
        if (!mobileDestNav) {
            document.querySelector('.mobile-nav').style.width = '0%';
        }
    }, [mobileDestNav])

    return (
        <>
            <div className="mobile-nav-screen-cover"></div>
            <div className="mobile-nav">
                <div className="mobile-nav-header">
                    <Link to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                {mobileDestNav === 1 ? <MobileNavDestinations navstate={setMobileDestNav} destinations={props.destinations} /> : <MobileNavNavigator state={mobileDestNav} navstate={setMobileDestNav} authStatus={props.authStatus} setAuth={props.setAuth} />}
            </div>
        </>
    )
}